<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form ref="form" @submit.prevent="handleSubmit">
			<v-row justify="center" no-gutters>
				<v-col style="margin: 40px; max-width: 600px">
					<v-btn icon @click="archive = !archive">
						<v-icon>{{ archive ? "mdi-view-list" : "mdi-archive" }}</v-icon>
					</v-btn>
					<div
						v-for="item in items.filter(i => i.active !== archive)"
						:key="item.key"
						style="
							border: 1px solid Gray;
							border-radius: 5px;
							padding: 10px;
							margin-bottom: 20px;
							display: flex;
							flex-direction: column;
							gap: 20px;
						"
					>
						<div v-for="(value, propertyName) of item" :key="propertyName">
							<v-autocomplete
								v-if="propertyName === 'teamManagers'"
								outlined
								item-value="_id"
								item-text="name"
								label="Responsáveis da Equipa"
								v-model="item[propertyName]"
								:items="consultants"
								small-chips
								multiple
								hide-details
							/>
							<v-checkbox
								v-else-if="propertyName === 'active'"
								v-model="item[propertyName]"
								label="Ativo"
								hide-details
							/>
							<v-text-field
								v-else
								v-model="item[propertyName]"
								outlined
								:label="translations[propertyName] ?? propertyName"
								hide-details
								dense
								:disabled="propertyName === 'key'"
								v-show="propertyName !== '_id'"
							/>
						</div>
					</div>
					<div
						v-for="(item, index) in newItems"
						:key="index"
						style="
							border: 1px solid Gray;
							border-radius: 5px;
							padding: 10px;
							margin-bottom: 20px;
							display: flex;
							flex-direction: column;
							gap: 20px;
						"
					>
						<div v-for="(value, propertyName) of item" :key="propertyName">
							<v-autocomplete
								v-if="propertyName === 'teamManagers'"
								outlined
								item-value="_id"
								item-text="name"
								label="Responsáveis da Equipa"
								v-model="item[propertyName]"
								:items="consultants"
								small-chips
								multiple
								hide-details
							/>
							<v-checkbox
								v-else-if="propertyName === 'active'"
								v-model="item[propertyName]"
								label="Ativo"
								hide-details
							/>
							<v-text-field
								v-else
								v-model="item[propertyName]"
								outlined
								:label="translations[propertyName] ?? propertyName"
								hide-details
								dense
								:disabled="propertyName === 'key'"
								@change="value => setKey(item, propertyName, value)"
							/>
						</div>
						<v-btn btn color="error" @click="removeNewItem(index)">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</div>
					<center>
						<v-btn fab color="primary" @click="addItem" style="display: block; margin-bottom: 20px">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</center>
					<v-btn :disabled="loading" type="submit" color="primary"> Atualizar </v-btn>
					<br /><br />
				</v-col>
			</v-row>
		</v-form>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";

import { editConfig } from "../api/auth";

export default Vue.extend({
	name: "ListForm",
	props: ["translations", "consultants", "list", "onClose"],
	async created() {
		if (this.list) this.setList(this.list);
	},
	watch: {
		list: function (newVal) {
			if (newVal) {
				this.setList(this.list);
			} else {
				this.name = "";
				this.items = [];
				this.newItems = [];
			}
		},
	},
	data() {
		return {
			loading: false,

			archive: false,

			name: "",
			items: [],
			newItems: [],
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		setList(newVal) {
			this.name = newVal.name;
			this.items = newVal.items;
			this.newItems = [];
		},
		addItem() {
			let newItem = Object.keys(this.items[0] ?? { active: true, key: "", name: "" }).reduce(
				(p, key) => Object.assign(p, { active: true, [key]: "" }),
				{},
			);

			delete newItem._id;

			if ("teamManagers" in newItem) newItem.teamManagers = [];

			this.newItems.push(newItem);
		},
		removeNewItem(index) {
			this.newItems.splice(index, 1);
		},
		setKey(item, property, value) {
			if (property === "name") item.key = value.replace(/[^A-Z0-9]+/gi, "").toLowerCase();
		},
		async handleSubmit() {
			const { name, items, newItems } = this;

			this.loading = true;

			const response = await editConfig({ [name]: [...items, ...newItems] });

			if (response.status === 200) {
				if (this.onClose) this.onClose();
				this.openToast({ message: "Lista atualizada com sucesso", color: "green" });

				window.location.reload();
			}

			this.loading = false;
		},
	},
});
</script>
