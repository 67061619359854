<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form ref="form">
			<v-row justify="center" style="margin: 10px">
				<v-col style="min-width: 300px; max-width: 395px">
					<v-expansion-panels popout multiple :value="[0]">
						<v-expansion-panel style="border: 1px solid DarkGrey">
							<v-expansion-panel-header>Processo</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-select
									outlined
									v-model="dealType"
									item-text="name"
									item-value="key"
									label="Tipo de Negócio"
									:items="config.dealTypes"
									:rules="[required]"
									hint="Obrigatório"
									persistent-hint
								/>
								<v-autocomplete
									v-if="user.role !== 'consultant'"
									outlined
									v-model="state"
									:items="config.reservationStates"
									item-text="name"
									item-value="key"
									label="Estado"
								/>
								<v-text-field outlined v-model="number" label="Nº de Processo" />
								<div style="display: flex">
									<v-combobox
										outlined
										v-model="finder"
										item-text="name"
										label="Angariador"
										:items="consultants"
										:rules="[required]"
										hint="Obrigatório"
										persistent-hint
										@change="
											newVal => {
												setExternal('finder');
												updateConsultantInfo('finder', newVal);
											}
										"
									/>
									<v-btn
										v-if="features.editCommissions"
										icon
										@click="() => updateConsultantInfo('finder', finder)"
										style="top: 10px"
									>
										<v-icon>mdi-refresh</v-icon>
									</v-btn>
									<v-checkbox
										v-model="isExternalFinder"
										hint="Externo"
										persistent-hint
										style="top: -5px; left: 10px; position: relative; min-width: 45px"
										@change="() => resetFinderSeller(true)"
									/>
								</div>
								<div style="display: flex">
									<v-combobox
										outlined
										v-model="seller"
										item-text="name"
										label="Vendedor"
										:items="consultants"
										:rules="[required]"
										hint="Obrigatório"
										persistent-hint
										@change="
											newVal => {
												setExternal('seller');
												updateConsultantInfo('seller', newVal);
											}
										"
									/>
									<v-btn
										v-if="features.editCommissions"
										icon
										@click="() => updateConsultantInfo('seller', seller)"
										style="top: 10px"
									>
										<v-icon>mdi-refresh</v-icon>
									</v-btn>
									<v-checkbox
										v-model="isExternalSeller"
										hint="Externo"
										persistent-hint
										style="top: -5px; left: 10px; position: relative; min-width: 45px"
										@change="() => resetFinderSeller()"
									/>
								</div>
								<div v-for="group in personGroups" :key="group.key">
									<div style="margin-bottom: 10px">{{ group.name }}</div>
									<v-expansion-panels popout multiple style="margin-bottom: 10px">
										<v-expansion-panel
											v-for="(person, index) in $data[group.key]"
											:key="person.key"
											:style="`border: 1px solid ${
												group.key !== 'guarantors' && getIsInternal(group.key) ? '#ff5252' : 'DarkGrey'
											}`"
										>
											<v-expansion-panel-header>
												<div v-if="index !== 0" style="display: flex">
													{{ person.name || `${group.singleName} ${index + 1}` }}
													<v-btn
														btn
														x-small
														color="error"
														@click="() => deletePerson(group.key, person.key)"
														style="margin: 0px 10px"
													>
														<v-icon small>mdi-delete</v-icon>
													</v-btn>
												</div>
												<div v-else>
													{{ person.name || `${group.singleName} ${index + 1}` }}
												</div>
											</v-expansion-panel-header>
											<v-expansion-panel-content eager>
												<div style="display: flex; flex-direction: column; gap: 10px">
													<div style="display: flex">
														<v-text-field
															outlined
															v-model="person.name"
															label="Nome"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required] : []"
														/>
														<v-checkbox
															v-model="person.isCompany"
															hint="Empresa"
															persistent-hint
															style="top: -5px; left: 10px; position: relative; min-width: 50px"
														/>
													</div>
													<div
														v-if="company !== 'realtyart'"
														style="display: flex; flex-direction: column; gap: 10px"
													>
														<v-text-field
															outlined
															v-model="person.prospectingNumber"
															label="ID de Prospeção"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required] : []"
														/>
														<v-text-field
															outlined
															v-model="person.address"
															label="Morada"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required] : []"
														/>
														<v-text-field
															outlined
															v-model="person.phone"
															label="Nº de telefone"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required, validPhone] : [validPhone]"
														/>
														<v-text-field
															outlined
															v-model="person.email"
															label="Email"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required, validEmail] : [validEmail]"
														/>
														<v-menu
															v-if="!person.isCompany"
															:close-on-content-click="false"
															transition="scale-transition"
															offset-y
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	outlined
																	v-model="person.birthday"
																	label="Data de Nascimento"
																	v-bind="attrs"
																	v-on="on"
																	readonly
																	hide-details
																/>
															</template>
															<v-date-picker
																v-model="person.birthday"
																color="primary"
																:max="new Date().toISOString().substr(0, 10)"
															/>
														</v-menu>
														<v-text-field
															outlined
															v-model="person.nif"
															label="NIF"
															type="number"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required, validNif] : [validNif]"
														/>
														<v-text-field
															v-if="!person.isCompany"
															outlined
															v-model="person.cc"
															label="Documento de Identificação"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required] : []"
														/>
														<v-menu
															v-if="!person.isCompany"
															:close-on-content-click="false"
															transition="scale-transition"
															offset-y
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	outlined
																	v-model="person.ccExpirationDate"
																	label="Data de Validade do Documento"
																	v-bind="attrs"
																	v-on="on"
																	readonly
																	:hint="
																		group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''
																	"
																	persistent-hint
																	:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
																	:rules="getIsInternal(group.key) ? [required] : []"
																/>
															</template>
															<v-date-picker
																v-model="person.ccExpirationDate"
																color="primary"
																type="month"
																:min="new Date().toISOString().substr(0, 10)"
															/>
														</v-menu>
														<v-text-field
															outlined
															v-model="person.iban"
															label="IBAN"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required] : []"
														/>
														<v-text-field
															v-if="!person.isCompany"
															outlined
															v-model="person.naturality"
															label="Naturalidade (freguesia e concelho)"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required] : []"
														/>
														<v-select
															v-if="!person.isCompany"
															outlined
															v-model="person.maritalStatus"
															item-text="name"
															item-value="key"
															label="Estado Civil"
															:items="config.maritalStatusTypes"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required] : []"
														/>
														<v-select
															v-if="!person.isCompany && person.maritalStatus === 'married'"
															outlined
															v-model="person.marriageRegime"
															item-text="name"
															item-value="key"
															label="Regime de Casamento"
															:items="config.marriageRegimeTypes"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required] : []"
														/>
														<v-text-field
															v-if="!person.isCompany"
															outlined
															v-model="person.job"
															label="Profissão"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required] : []"
														/>
														<v-text-field
															v-if="!person.isCompany"
															outlined
															v-model="person.company"
															label="Entidade Empregadora"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required] : []"
														/>
														<v-checkbox v-if="person.isCompany" v-model="person.rcbe" label="RCBE" />
														<v-text-field
															v-if="person.isCompany"
															outlined
															v-model="person.commercialCertificate"
															label="Certidão Comercial"
															:hint="group.key !== 'guarantors' && getIsInternal(group.key) ? 'Obrigatório' : ''"
															persistent-hint
															:hide-details="group.key === 'guarantors' || !getIsInternal(group.key)"
															:rules="getIsInternal(group.key) ? [required] : []"
														/>
														<v-text-field
															v-if="group.key === 'owners'"
															outlined
															append-icon="mdi-percent"
															v-model="person.percentage"
															label="Percentagem"
															type="number"
															hide-details
															:rules="[required]"
														/>
													</div>
												</div>
											</v-expansion-panel-content>
										</v-expansion-panel>
									</v-expansion-panels>
									<div
										v-if="group.key !== 'guarantors' && getIsInternal(group.key)"
										:style="`text-align: left; margin-left: 25px; font-size: 0.75em; color: ${
											getIsInternal(group.key) ? '#ff5252' : 'LightGrey'
										}`"
									>
										Obrigatório
									</div>
									<center style="margin-bottom: 20px">
										<v-btn fab small color="primary" @click="() => addPerson(group.key)">
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</center>
								</div>
								<div
									v-if="
										!['mortgageLoan', 'creditTransfer'].includes(dealType) &&
										(isExternalFinder || isExternalSeller)
									"
								>
									<v-text-field outlined v-model="externalOffice" label="Agência de Partilha" />
									<v-text-field outlined v-model="externalContact" label="Contacto da Agência de Partilha" />
									<v-text-field
										outlined
										v-model="externalStaffContact"
										label="Contacto DP da Agência de Partilha"
									/>
									<v-text-field
										outlined
										v-model="externalNif"
										label="NIF da Agência de Partilha"
										:rules="[validNif]"
									/>
									<v-text-field outlined v-model="externalAddress" label="Morada da Agência de Partilha" />
								</div>
								<div style="border: 1px solid grey; border-radius: 3px; padding: 10px; margin-bottom: 30px">
									<p>Enviar email para</p>
									<v-autocomplete
										outlined
										v-model="processManagers"
										item-text="name"
										item-value="_id"
										label="Departamento Processual"
										:items="
											managers.filter(u => u.role === 'processManager' || u.otherRoles?.includes('processManager'))
										"
										chips
										multiple
									/>
									<v-autocomplete
										outlined
										v-model="creditManagers"
										item-text="name"
										item-value="_id"
										label="Departamento de Crédito"
										:items="
											managers.filter(u => u.role === 'creditManager' || u.otherRoles?.includes('creditManager'))
										"
										chips
										multiple
									/>
									<v-autocomplete
										outlined
										v-model="financeManagers"
										item-text="name"
										item-value="_id"
										label="Departamento Financeiro"
										:items="managers.filter(u => u.role === 'cfo' || u.otherRoles?.includes('cfo'))"
										chips
										multiple
									/>
									<v-autocomplete
										outlined
										v-model="teamLeaders"
										item-text="name"
										item-value="_id"
										label="Team Leaders"
										:items="managers.filter(u => u.role === 'teamLeader' || u.otherRoles?.includes('teamLeader'))"
										chips
										multiple
									/>
									<v-autocomplete
										outlined
										v-model="brokers"
										item-text="name"
										item-value="_id"
										label="Brokers"
										:items="managers.filter(u => u.role === 'broker' || u.otherRoles?.includes('broker'))"
										chips
										multiple
									/>
									<v-autocomplete
										outlined
										v-model="assistants"
										item-text="name"
										item-value="_id"
										label="Assistentes"
										:items="managers.filter(u => u.role === 'assistant') || u.otherRoles?.includes('assistant')"
										chips
										multiple
									/>
								</div>
								<v-autocomplete
									v-if="features.editCommissions"
									outlined
									v-model="createdBy"
									item-text="name"
									item-value="_id"
									label="Criado por"
									:items="users"
								/>
								<v-textarea outlined v-model="observations" label="Observações" height="145px" hide-details />
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
				<v-col style="min-width: 300px; max-width: 395px">
					<v-expansion-panels popout multiple :value="[0, 1]" :key="dealType">
						<v-expansion-panel
							v-if="!['mortgageLoan', 'creditTransfer'].includes(dealType)"
							style="border: 1px solid DarkGrey"
						>
							<v-expansion-panel-header>Negócio</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-text-field
									outlined
									append-icon="mdi-currency-eur"
									v-model="houseValue"
									:label="dealType === 'renting' ? 'Valor da Renda Mensal' : 'Valor de Venda'"
									type="number"
									:rules="[required]"
									hint="Obrigatório"
									persistent-hint
									@input="() => (this.value = this.houseValue * (this.officePercentage / 100))"
								/>
								<v-text-field
									v-if="dealType === 'renting'"
									outlined
									v-model="rentingDuration"
									label="Duração do Arrendamento"
									type="number"
									hint="meses"
									persistent-hint
								/>
								<v-row>
									<v-col>
										<v-text-field
											v-if="dealType === 'renting'"
											outlined
											v-model="numberOfRents"
											label="Nº de rendas"
											type="number"
										/>
									</v-col>
									<v-col>
										<v-text-field
											v-if="dealType === 'renting'"
											outlined
											v-model="numberOfDeposits"
											label="Nº de Cauções"
											type="number"
										/>
									</v-col>
								</v-row>
								<v-text-field
									v-if="dealType !== 'renting'"
									outlined
									append-icon="mdi-currency-eur"
									v-model="signalValue"
									label="Valor do Sinal"
									type="number"
								/>
								<v-text-field
									outlined
									append-icon="mdi-percent"
									v-model="officePercentage"
									label="Percentagem do Negócio"
									type="number"
									@input="() => (this.value = this.houseValue * (this.officePercentage / 100))"
								/>
								<v-text-field
									outlined
									append-icon="mdi-currency-eur"
									v-model="value"
									label="Comissão Total"
									type="number"
									:rules="[required]"
									hint="Obrigatório"
									persistent-hint
								/>
								<v-text-field
									v-if="isExternalFinder || isExternalSeller"
									outlined
									append-icon="mdi-percent"
									v-model="percentageSplit"
									label="Percentagem Interna"
									type="number"
								/>
								<div align="left" style="margin-left: 10px; margin-bottom: 10px">
									<div v-if="value">
										Comissão da Agência:
										{{
											formatNumber(
												isExternalFinder || isExternalSeller ? value * (percentageSplit / 100 || 0.5) : value,
												true,
											)
										}}
									</div>
									<div
										v-if="
											false &&
											finder &&
											seller &&
											finder._id === seller._id &&
											(['teamLeader', 'consultant'].includes(user.role) ? user._id === finder._id : true)
										"
									>
										<br />
										<div>
											Comissão do Consultor:
											{{ formatNumber(value * (finder.commission / 100), true) }}
											({{ finder.commission }}%)
										</div>
									</div>
									<div v-else-if="false">
										<div
											v-if="
												finder &&
												!isExternalFinder &&
												(['teamLeader', 'consultant'].includes(user.role) ? user._id === finder._id : true)
											"
										>
											<br />
											<div>
												Comissão do Angariador:
												{{
													formatNumber(
														(isExternalFinder ? value * (percentageSplit / 100 || 0.5) : value / 2) *
															(finder.commission / 100),
														true,
													)
												}}
												({{ finder.commission }}%)
											</div>
										</div>
										<div
											v-if="
												seller &&
												!isExternalSeller &&
												(['teamLeader', 'consultant'].includes(user.role) ? user._id === seller._id : true)
											"
										>
											<br />
											<div>
												Comissão do Vendedor:
												{{
													formatNumber(
														(isExternalSeller ? value * (percentageSplit / 100 || 0.5) : value / 2) *
															(seller.commission / 100),
														true,
													)
												}}
												({{ seller.commission }}%)
											</div>
										</div>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel v-if="dealType !== 'renting'" style="border: 1px solid DarkGrey">
							<v-expansion-panel-header>Crédito</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-if="user.role != 'consultant'"
											outlined
											v-model="scheduledDate"
											label="Data de Agendamento"
											v-bind="attrs"
											v-on="on"
											readonly
											clearable
										/>
									</template>
									<v-date-picker v-model="scheduledDate" color="primary" />
								</v-menu>
								<v-autocomplete
									outlined
									v-model="creditManager"
									item-text="name"
									item-value="_id"
									label="Gestora de Crédito"
									:items="managers"
								/>
								<v-select
									outlined
									v-model="internalCredit"
									item-text="name"
									item-value="key"
									label="Intermediação"
									:items="config.internalCreditTypes"
								/>
								<v-text-field
									v-if="internalCredit && !['byClient', 'paidInFull'].includes(internalCredit)"
									outlined
									append-icon="mdi-currency-eur"
									v-model="creditValue"
									label="Valor do Financiamento"
									type="number"
								/>
								<v-autocomplete
									v-if="internalCredit && internalCredit !== 'paidInFull'"
									outlined
									v-model="creditBank"
									item-text="name"
									label="Banco"
									:items="internalCredit === 'yes' ? config.banks.filter(b => b.type === 'deal') : config.banks"
									return-object
								/>
								<v-text-field
									v-if="internalCredit === 'yes'"
									outlined
									append-icon="mdi-currency-eur"
									v-model="appraisal"
									label="Valor de Avaliação"
									type="number"
								/>
								<v-menu
									v-if="internalCredit === 'yes'"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="appraisalDate"
											label="Data de Avaliação"
											v-bind="attrs"
											v-on="on"
											readonly
											clearable
										/>
									</template>
									<v-date-picker v-model="appraisalDate" color="primary" />
								</v-menu>
								<v-text-field
									v-if="internalCredit === 'yes'"
									outlined
									append-icon="mdi-currency-eur"
									v-model="bankCommission"
									label="Comissão Bancária"
									type="number"
								/>
								<v-menu
									v-if="internalCredit === 'yes'"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="bankCommissionDate"
											label="Data de Extrato"
											v-bind="attrs"
											v-on="on"
											readonly
											clearable
										/>
									</template>
									<v-date-picker v-model="bankCommissionDate" color="primary" />
								</v-menu>
								<v-checkbox v-model="insurance" label="Seguro" style="padding: 0px; margin: 0px" />
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
				<v-col
					v-if="!['mortgageLoan', 'creditTransfer'].includes(dealType)"
					style="min-width: 300px; max-width: 395px"
				>
					<v-expansion-panels popout multiple :value="[0, 1]">
						<v-expansion-panel style="border: 1px solid DarkGrey">
							<v-expansion-panel-header>Imóvel</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-autocomplete
									v-if="!ref"
									outlined
									v-model="fillable"
									:items="fillables"
									:item-text="
										f => `${f.type} -
									${formatDate(f.date || f.findingDate, 'DD-MMM')} - ${f.ref || f.buyers[0]?.name}`
									"
									label="Preencher com angariação/viabilidade"
									:loading="loadingInfo"
									hint="Ref, Nome, NIF, Telefone, Email"
									persistent-hint
									hide-no-data
									clearable
									chips
									return-object
									@update:search-input="search => setSearch(search, 'search')"
									@change="populateInfo"
								/>
								<v-text-field outlined v-model="ref" label="Referência" />
								<v-text-field outlined v-model="address" label="Morada" />
								<v-row>
									<v-col>
										<v-text-field outlined v-model="postalCode" label="Código Postal" :rules="[validPostalCode]" />
									</v-col>
									<v-col>
										<v-text-field outlined v-model="city" label="Cidade" />
									</v-col>
								</v-row>
								<v-autocomplete
									outlined
									v-model="houseType"
									item-text="name"
									item-value="key"
									label="Tipo de Imóvel"
									:items="config.houseTypes"
								/>
								<v-row>
									<v-col>
										<v-text-field outlined append-icon="m²" v-model="area" label="Área Útil" type="number" />
									</v-col>
									<v-col>
										<v-text-field
											outlined
											prepend-inner-icon="T"
											v-model="rooms"
											label="Tipologia"
											type="number"
										/>
									</v-col>
								</v-row>
								<v-select
									outlined
									v-model="condominium"
									item-text="name"
									item-value="key"
									label="Condomínio"
									:items="config.condominiumTypes"
								/>
								<div v-if="company !== 'realtyart'">
									<v-checkbox
										v-model="predialRegistrationCertificate"
										label="Certidão Registo Predial"
										style="padding: 0px; margin: 0px"
									/>
									<v-checkbox
										v-model="predialBooklet"
										label="Caderneta Predial"
										style="padding: 0px; margin: 0px"
									/>
									<v-checkbox
										v-model="usageLicense"
										label="Licença de Utilização"
										style="padding: 0px; margin: 0px"
									/>
									<v-checkbox
										v-model="luExemptionCertificate"
										label="Certidão de Isenção LU (1951)"
										style="padding: 0px; margin: 0px"
									/>
									<v-checkbox
										v-model="infrastructureCertificate"
										label="Certidão de Infraestruturas (1ª Transmissão)"
										style="padding: 0px; margin: 0px"
									/>
									<v-checkbox
										v-model="housingTechnicalSheet"
										label="Ficha Técnica de Habitação (FTH)"
										style="padding: 0px; margin: 0px"
									/>
									<v-checkbox
										v-model="energeticCertificate"
										label="Certificado Energético"
										style="padding: 0px; margin: 0px"
									/>
									<v-checkbox v-model="propertyPlan" label="Planta do Imóvel" style="padding: 0px; margin: 0px" />
									<v-checkbox v-model="kyc" label="KYC" style="padding: 0px; margin: 0px" />
									<v-checkbox
										v-model="preferenceRights"
										label="Direitos de Preferência"
										style="padding: 0px; margin: 0px"
									/>
								</div>
								<div v-if="company === 'realtyart'">
									<p>Branqueamento de Capitais</p>
									<v-btn-toggle v-model="moneyLaundering">
										<v-btn value="ok">OK</v-btn>
										<v-btn value="missing">Em Falta</v-btn>
										<v-btn value="notApplicable">Não Aplicável</v-btn>
									</v-btn-toggle>
									<br /><br />
									<p>Análise de Risco</p>
									<v-btn-toggle v-model="riskAnalysis">
										<v-btn value="ok">OK</v-btn>
										<v-btn value="missing">Em Falta</v-btn>
										<v-btn value="notApplicable">Não Aplicável</v-btn>
									</v-btn-toggle>
									<br /><br />
								</div>
								<v-select
									v-if="dealType !== 'renting'"
									outlined
									v-model="purpose"
									item-text="name"
									item-value="key"
									label="Destino do Imóvel"
									:items="config.purposeTypes"
									hide-details
								/>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
					<Files
						ref="files"
						:files="reservation ? reservation.files && reservation.files.filter(f => !f.type) : []"
						group="reservations"
						:id="reservation ? reservation._id : ''"
						:hideSaveButton="true"
						:patchFunction="patchReservation"
						:onSubmit="onUpdate"
						style="min-width: 300px; max-width: 395px; flex-grow: 1; border: 1px solid DarkGrey; margin-top: 15px"
					/>
				</v-col>
				<v-col style="min-width: 300px; max-width: 395px">
					<v-expansion-panels popout multiple :value="[0, 1]">
						<v-expansion-panel style="border: 1px solid DarkGrey">
							<v-expansion-panel-header>Fecho de Negócio</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="reservationDate"
											label="Data de Entrada"
											v-bind="attrs"
											v-on="on"
											:rules="[required]"
											readonly
										/>
									</template>
									<v-date-picker v-model="reservationDate" color="primary" />
								</v-menu>
								<div v-if="!['mortgageLoan', 'creditTransfer'].includes(dealType)">
									<p>
										Forma de Pagamento
										<v-btn icon small @click="billingSimpleMode = !billingSimpleMode">
											<v-icon small>mdi-more</v-icon>
										</v-btn>
									</p>
									<v-btn-toggle
										v-if="billingSimpleMode"
										v-model="billingType"
										@change="setBillingDates"
										style="margin-bottom: 20px"
										mandatory
									>
										<v-btn :value="dealType === 'renting' ? 'ASS' : 'CPCV'">
											{{ dealType === "renting" ? "Assinatura" : "CPCV" }}
										</v-btn>
										<v-btn v-if="dealType !== 'renting'" value="5050">50/50</v-btn>
										<v-btn v-if="dealType !== 'renting'" value="ESC">Escritura</v-btn>
									</v-btn-toggle>
									<div v-else style="margin-bottom: 20px">
										<span v-for="(date, index) in billingDates" :key="date.key">
											<div v-if="index !== 0" style="display: flex">
												<v-divider style="margin: 20px" />
												<v-btn btn small color="error" @click="() => deleteBillingDate(date.key)">
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</div>
											<v-row>
												<v-col>
													<v-select
														outlined
														v-model="date.type"
														item-text="name"
														item-value="key"
														label="Tipo"
														:items="
															config.billingTypes.filter(t =>
																dealType === 'renting' ? t.type === 'renting' : t.type !== 'renting',
															)
														"
														hide-details
														style="margin-bottom: 10px"
													/>
												</v-col>
												<v-col>
													<v-text-field
														outlined
														append-icon="mdi-percent"
														v-model="date.percentage"
														label="Percentagem"
														type="number"
														hide-details
														:rules="[required]"
													/>
												</v-col>
											</v-row>
											<v-menu v-if="user.role != 'consultant'" transition="scale-transition" offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														outlined
														v-model="date.predictedExecutionDate"
														label="Data de Previsão de Faturação"
														v-bind="attrs"
														v-on="on"
														style="margin-bottom: 10px"
														readonly
														clearable
														:rules="[required]"
														hint="Obrigatório"
														persistent-hint
													/>
												</template>
												<v-date-picker v-model="date.predictedExecutionDate" color="primary" />
											</v-menu>
											<v-menu transition="scale-transition" offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														outlined
														v-model="date.executionDate"
														label="Data de Faturação"
														v-bind="attrs"
														v-on="on"
														hide-details
														style="margin-bottom: 10px"
														readonly
														clearable
													/>
												</template>
												<v-date-picker v-model="date.executionDate" color="primary" />
											</v-menu>
											<center>
												<v-btn
													v-if="index === billingDates.length - 1"
													fab
													small
													color="primary"
													@click="() => addBillingDate(index)"
												>
													<v-icon>mdi-plus</v-icon>
												</v-btn>
											</center>
										</span>
									</div>
									<v-menu
										v-if="dealType !== 'renting'"
										:close-on-content-click="false"
										transition="scale-transition"
										offset-y
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												outlined
												v-model="cpcvDate"
												label="Data de CPCV"
												v-bind="attrs"
												v-on="on"
												readonly
												clearable
											/>
										</template>
										<v-date-picker v-model="cpcvDate" color="primary" />
									</v-menu>
									<v-text-field
										v-if="dealType !== 'renting'"
										outlined
										v-model="deedDeadlineDuration"
										label="Prazo para Escritura"
										type="number"
										hint="dias"
										persistent-hint
									/>
								</div>
								<v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="deedDate"
											:label="`Data de ${dealType === 'renting' ? 'Assinatura' : 'Escritura'}`"
											v-bind="attrs"
											v-on="on"
											readonly
											clearable
										/>
									</template>
									<v-date-picker v-model="deedDate" color="primary" />
								</v-menu>
								<v-row v-if="!['mortgageLoan', 'creditTransfer'].includes(dealType) && user.role != 'consultant'">
									<v-col>
										<v-checkbox v-model="impic" label="IMPIC" />
									</v-col>
									<v-col>
										<v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													v-show="impic"
													outlined
													v-model="impicDate"
													label="Data de IMPIC"
													v-bind="attrs"
													v-on="on"
													readonly
													clearable
												/>
											</template>
											<v-date-picker v-model="impicDate" color="primary" />
										</v-menu>
									</v-col>
								</v-row>
								<v-menu
									v-if="user.role != 'consultant'"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="giveUpDate"
											label="Data de Desistência"
											v-bind="attrs"
											v-on="on"
											readonly
											clearable
										/>
									</template>
									<v-date-picker v-model="giveUpDate" color="primary" />
								</v-menu>
								<v-combobox
									v-if="giveUpDate"
									outlined
									v-model="giveUpReason"
									item-text="name"
									item-value="key"
									label="Razão da Desistência"
									:items="config.giveUpReasons"
									:rules="[required]"
									hide-details
								/>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
			</v-row>
			<v-btn v-if="!reservation" :disabled="loading" @click="handleAddReservation">Adicionar</v-btn>
			<v-btn v-if="reservation" :disabled="loading" @click="handleEditReservation">Atualizar</v-btn>
			<br /><br />
		</v-form>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import dayjs from "dayjs";

import Files from "./Files";

import { getUsers } from "../api/users";
import { addReservation, editReservation, patchReservation } from "../api/reservations";
import { getFindings } from "../api/findings";
import { getViabilities } from "../api/viabilities";

import { formatDate, formatNumber } from "../utils/utils";

export default Vue.extend({
	name: "ReservationForm",
	props: ["reservation", "onUpdate", "onClose"],
	components: { Files },
	created() {
		this.handleGetUsers();

		if (this.reservation) {
			this.setReservation(this.reservation);
		} else {
			this.resetReservation();
		}
	},
	watch: {
		reservation: function (newVal) {
			if (newVal) {
				this.setReservation(newVal);
			} else {
				this.resetReservation();
			}
		},
		internalCredit(newVal) {
			if (
				newVal === "yes" &&
				this.creditBank &&
				this.config.banks.filter(b => b.type === "noDeal").find(b => b.key === this.creditBank.key)
			) {
				this.creditBank = "";
			}
		},
		dealType(newVal) {
			this.personGroups = [
				{
					key: "owners",
					name: newVal === "renting" ? "Senhorios" : "Proprietários",
					singleName: newVal === "renting" ? "Senhorio" : "Proprietário",
				},
				{
					key: "buyers",
					name: newVal === "renting" ? "Arrendatários" : "Compradores",
					singleName: newVal === "renting" ? "Arrendatário" : "Comprador",
				},
			];

			if (newVal === "renting") {
				this.personGroups.push({ key: "guarantors", name: "Fiadores", singleName: "Fiador" });
			} else if (["mortgageLoan", "creditTransfer"].includes(newVal)) {
				this.internalCredit = "yes";
			}

			if (!this.reservation) {
				this.billingType = newVal === "renting" ? "ASS" : "CPCV";
				this.billingDates = [
					{
						key: 0,
						type: newVal === "renting" ? "ASS" : "CPCV",
						predictedExecutionDate: "",
						executionDate: "",
						percentage: 100,
					},
				];
			}
		},
	},
	computed: mapState(["company", "config", "user", "selectedOffices", "features"]),
	data() {
		const emailRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const postalCodeRegex = /^[0-9]{4}-[0-9]{3}$/;

		return {
			loading: false,
			loadingInfo: false,

			required: value => !!value || "Obrigatório",
			validEmail: value => !value || (value && emailRegex.test(value)) || "Email inválido",
			validPhone: value =>
				!value || (value && (value.includes("+") ? true : value.length === 9)) || "Telefone inválido",
			validNif: value => !value || (value && value.toString().length === 9) || "NIF inválido",
			validPostalCode: value => !value || postalCodeRegex.test(value) || "Código postal inválido",

			users: [],
			consultants: [],
			managers: [],
			personGroups: [],
			billingType: "CPCV",
			billingSimpleMode: false,
			timer: null,
			search: null,
			fillables: [],
			fillable: null,

			ref: "",
			dealType: "",
			state: "active",
			number: "",
			reservationDate: formatDate(dayjs(), "YYYY-MM-DD"),
			address: "",
			postalCode: "",
			city: "",
			houseType: "",
			area: "",
			rooms: "",
			condominium: "",
			predialRegistrationCertificate: false,
			predialBooklet: false,
			usageLicense: false,
			luExemptionCertificate: false,
			infrastructureCertificate: false,
			housingTechnicalSheet: false,
			energeticCertificate: false,
			propertyPlan: false,
			kyc: false,
			preferenceRights: false,
			purpose: "",
			appraisal: "",
			appraisalDate: "",
			owners: [{ key: 0, percentage: 100 }],
			buyers: [{ key: 0 }],
			guarantors: [{ key: 0 }],
			finder: "",
			isExternalFinder: true,
			finderPercentage: null,
			finderTeam: null,
			finderCommercialDirector: null,
			finderCommercialDirectorCommission: null,
			finderBrokerCommission: null,
			finderRecruiter: null,
			finderRecruiterPercentage: null,
			finderRecruiterDirector: null,
			finderRecruiterDirectorPercentage: null,
			sentBillsToFinder: null,
			finderPaymentDate: null,
			seller: "",
			isExternalSeller: true,
			sellerPercentage: null,
			sellerTeam: null,
			sellerCommercialDirector: null,
			sellerCommercialDirectorPercentage: null,
			sellerBrokerPercentage: null,
			sellerRecruiter: null,
			sellerRecruiterPercentage: null,
			sellerRecruiterDirector: null,
			sellerRecruiterDirectorPercentage: null,
			externalOffice: "",
			externalContact: "",
			externalStaffContact: "",
			externalNif: "",
			externalAddress: "",
			processManagers: [],
			creditManagers: [],
			financeManagers: [],
			brokers: [],
			teamLeaders: [],
			assistants: [],
			houseValue: "",
			signalValue: "",
			rentingDuration: "",
			numberOfRents: "",
			numberOfDeposits: "",
			value: "",
			officePercentage: "",
			percentageSplit: "",
			impic: false,
			moneyLaundering: "",
			riskAnalysis: "",
			createdBy: "",
			observations: "",
			billingDates: [
				{
					key: 0,
					type: "CPCV",
					predictedExecutionDate: "",
					executionDate: "",
					percentage: 100,
				},
			],
			cpcvDate: "",
			deedDeadlineDuration: "",
			deedDate: "",
			impicDate: "",
			giveUpDate: "",
			giveUpReason: "",
			scheduledDate: "",
			creditManager: "",
			internalCredit: "",
			creditValue: "",
			creditBank: "",
			bankCommission: "",
			bankCommissionDate: "",
			insurance: false,
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		formatNumber,
		formatDate,
		patchReservation,
		setReservation(newVal) {
			this.ref = newVal.ref;
			this.dealType = newVal.dealType;
			this.state = newVal.state;
			this.number = newVal.number;
			this.reservationDate = formatDate(newVal.reservationDate, "YYYY-MM-DD");
			this.address = newVal.address;
			this.postalCode = newVal.postalCode;
			this.city = newVal.city;
			this.houseType = newVal.houseType;
			this.area = newVal.area;
			this.rooms = newVal.rooms;
			this.condominium = newVal.condominium;
			this.predialRegistrationCertificate = newVal.predialRegistrationCertificate;
			this.predialBooklet = newVal.predialBooklet;
			this.usageLicense = newVal.usageLicense;
			this.luExemptionCertificate = newVal.luExemptionCertificate;
			this.infrastructureCertificate = newVal.infrastructureCertificate;
			this.housingTechnicalSheet = newVal.housingTechnicalSheet;
			this.energeticCertificate = newVal.energeticCertificate;
			this.propertyPlan = newVal.propertyPlan;
			this.kyc = newVal.kyc;
			this.preferenceRights = newVal.preferenceRights;
			this.purpose = newVal.purpose;
			this.appraisal = newVal.appraisal;
			this.appraisalDate = newVal.appraisalDate && formatDate(newVal.appraisalDate, "YYYY-MM-DD");
			this.owners = newVal.owners.map(o => ({
				_id: o._id,
				key: o._id,
				...o,
				birthday: o.birthday && formatDate(o.birthday, "YYYY-MM-DD"),
			}));
			this.buyers = newVal.buyers.map(b => ({
				_id: b._id,
				key: b._id,
				...b,
				birthday: b.birthday && formatDate(b.birthday, "YYYY-MM-DD"),
			}));
			this.guarantors = newVal.guarantors
				? newVal.guarantors.map(g => ({
						_id: g._id,
						key: g._id,
						...g,
						birthday: g.birthday && formatDate(g.birthday, "YYYY-MM-DD"),
				  }))
				: [{ key: 0 }];
			this.finder = newVal.finder;
			this.isExternalFinder = newVal.isExternalFinder;
			this.finderPercentage = newVal.finderPercentage;
			this.finderTeam = newVal.finderTeam;
			this.finderCommercialDirector = newVal.finderCommercialDirector;
			this.finderCommercialDirectorCommission = newVal.finderCommercialDirectorCommission;
			this.finderBrokerCommission = newVal.finderBrokerCommission;
			this.finderRecruiter = newVal.finderRecruiter;
			this.finderRecruiterPercentage = newVal.finderRecruiterPercentage;
			this.finderRecruiterDirector = newVal.finderRecruiterDirector;
			this.finderRecruiterDirectorPercentage = newVal.finderRecruiterDirectorPercentage;
			this.sentBillsToFinder = newVal.sentBillsToFinder && formatDate(newVal.sentBillsToFinder, "YYYY-MM-DD");
			this.finderPaymentDate = newVal.finderPaymentDate && formatDate(newVal.finderPaymentDate, "YYYY-MM-DD");
			this.seller = newVal.seller;
			this.isExternalSeller = newVal.isExternalSeller;
			this.sellerPercentage = newVal.sellerPercentage;
			this.sellerTeam = newVal.sellerTeam;
			this.sellerCommercialDirector = newVal.sellerCommercialDirector;
			this.sellerCommercialDirectorPercentage = newVal.sellerCommercialDirectorPercentage;
			this.sellerBrokerPercentage = newVal.sellerBrokerPercentage;
			this.sellerRecruiter = newVal.sellerRecruiter;
			this.sellerRecruiterPercentage = newVal.sellerRecruiterPercentage;
			this.sellerRecruiterDirector = newVal.sellerRecruiterDirector;
			this.sellerRecruiterDirectorPercentage = newVal.sellerRecruiterDirectorPercentage;
			this.externalOffice = newVal.externalOffice;
			this.externalContact = newVal.externalContact;
			this.externalStaffContact = newVal.externalStaffContact;
			this.externalNif = newVal.externalNif;
			this.externalAddress = newVal.externalAddress;
			this.processManagers = newVal.processManagers;
			this.creditManagers = newVal.creditManagers;
			this.financeManagers = newVal.financeManagers;
			this.brokers = newVal.brokers;
			this.teamLeaders = newVal.teamLeaders;
			this.assistants = newVal.assistants;
			this.houseValue = newVal.houseValue;
			this.signalValue = newVal.signalValue;
			this.rentingDuration = newVal.rentingDuration;
			this.numberOfRents = newVal.numberOfRents;
			this.numberOfDeposits = newVal.numberOfDeposits;
			this.value = newVal.value;
			this.officePercentage = newVal.officePercentage ? newVal.officePercentage * 100 : null;
			this.percentageSplit = newVal.percentageSplit ? newVal.percentageSplit * 100 : null;
			this.impic = newVal.impic;
			this.moneyLaundering = newVal.moneyLaundering;
			this.riskAnalysis = newVal.riskAnalysis;
			this.createdBy = newVal.user;
			this.observations = newVal.observations;
			this.billingDates = newVal.billingDates.map(d => ({
				_id: d._id,
				key: d._id,
				type: d.type,
				predictedExecutionDate: d.predictedExecutionDate && formatDate(d.predictedExecutionDate, "YYYY-MM-DD"),
				executionDate: d.executionDate && formatDate(d.executionDate, "YYYY-MM-DD"),
				percentage: d.percentage,
			}));
			this.cpcvDate = newVal.cpcvDate && formatDate(newVal.cpcvDate, "YYYY-MM-DD");
			this.deedDeadlineDuration = newVal.deedDeadlineDuration;
			this.deedDate = newVal.deedDate && formatDate(newVal.deedDate, "YYYY-MM-DD");
			this.impicDate = newVal.impicDate && formatDate(newVal.impicDate, "YYYY-MM-DD");
			this.giveUpDate = newVal.giveUpDate && formatDate(newVal.giveUpDate, "YYYY-MM-DD");
			this.giveUpReason =
				this.config.giveUpReasons.find(r => r.key === newVal.giveUpReason) || newVal.giveUpReason;
			this.scheduledDate = newVal.scheduledDate && formatDate(newVal.scheduledDate, "YYYY-MM-DD");
			this.creditManager = newVal.creditManager;
			this.internalCredit = newVal.internalCredit;
			this.creditValue = newVal.creditValue;
			this.creditBank = newVal.creditBank && this.config.banks.find(b => b.key === newVal.creditBank);
			this.bankCommission = newVal.bankCommission;
			this.bankCommissionDate = newVal.bankCommissionDate && formatDate(newVal.bankCommissionDate, "YYYY-MM-DD");
			this.insurance = newVal.insurance;

			this.setConsultantFields();
		},
		resetReservation() {
			this.ref = "";
			this.dealType = "";
			this.state = "active";
			this.number = "";
			this.reservationDate = formatDate(dayjs(), "YYYY-MM-DD");
			this.address = "";
			this.postalCode = "";
			this.city = "";
			this.houseType = "";
			this.area = "";
			this.rooms = "";
			this.condominium = "";
			this.predialRegistrationCertificate = false;
			this.predialBooklet = false;
			this.usageLicense = false;
			this.luExemptionCertificate = false;
			this.infrastructureCertificate = false;
			this.housingTechnicalSheet = false;
			this.energeticCertificate = false;
			this.propertyPlan = false;
			this.kyc = false;
			this.preferenceRights = false;
			this.purpose = "";
			this.appraisal = "";
			this.appraisalDate = "";
			this.owners = [{ key: 0, percentage: 100 }];
			this.buyers = [{ key: 0 }];
			this.guarantors = [{ key: 0 }];
			this.finder = "";
			this.isExternalFinder = true;
			this.finderPercentage = null;
			this.finderTeam = null;
			this.finderCommercialDirector = null;
			this.finderCommercialDirectorCommission = null;
			this.finderBrokerCommission = null;
			this.finderRecruiter = null;
			this.finderRecruiterPercentage = null;
			this.finderRecruiterDirector = null;
			this.finderRecruiterDirectorPercentage = null;
			this.seller = "";
			this.isExternalSeller = true;
			this.sellerPercentage = null;
			this.sellerTeam = null;
			this.sellerCommercialDirector = null;
			this.sellerCommercialDirectorPercentage = null;
			this.sellerBrokerPercentage = null;
			this.sellerRecruiter = null;
			this.sellerRecruiterPercentage = null;
			this.sellerRecruiterDirector = null;
			this.sellerRecruiterDirectorPercentage = null;
			this.externalOffice = "";
			this.externalContact = "";
			this.externalStaffContact = "";
			this.externalNif = "";
			this.externalAddress = "";
			this.processManagers = [];
			this.creditManagers = [];
			this.financeManagers = [];
			this.brokers = [];
			this.teamLeaders = [];
			this.assistants = [];
			this.houseValue = "";
			this.signalValue = "";
			this.rentingDuration = "";
			this.numberOfRents = "";
			this.numberOfDeposits = "";
			this.value = "";
			this.officePercentage = "";
			this.percentageSplit = "";
			this.impic = false;
			this.moneyLaundering = "";
			this.riskAnalysis = "";
			this.createdBy = this.user;
			this.observations = "";
			this.billingDates = [
				{ key: 0, type: "CPCV", predictedExecutionDate: "", executionDate: "", percentage: 100 },
			];
			this.cpcvDate = "";
			this.deedDeadlineDuration = "";
			this.deedDate = "";
			this.impicDate = "";
			this.giveUpDate = "";
			this.giveUpReason = "";
			this.scheduledDate = "";
			this.creditManager = this.user.role === "creditManager" ? this.user._id : "";
			this.internalCredit = "";
			this.creditValue = "";
			this.creditBank = "";
			this.bankCommission = "";
			this.bankCommissionDate = "";
			this.insurance = false;

			this.setConsultantFields();
		},
		setConsultantFields() {
			if (["teamLeader", "consultant"].includes(this.user.role)) {
				this.finder = this.seller = this.user;

				this.updateConsultantInfo("finder", this.user);
				this.updateConsultantInfo("seller", this.user);

				this.billingSimpleMode = true;
				this.state = "toValidate";
			}
		},
		setExternal(type) {
			this[`isExternal${type.charAt(0).toUpperCase() + type.slice(1)}`] = !this[type]?._id;
		},
		updateConsultantInfo(type, newVal) {
			this[`isExternal${type.charAt(0).toUpperCase() + type.slice(1)}`] = !newVal?._id;
			this[`${type}Percentage`] = newVal?.commission || null;
			this[`${type}Team`] = newVal?.team || null;
			this[`${type}CommercialDirector`] = newVal?.manager || null;
			this[`${type}CommercialDirectorCommission`] = newVal?.commercialDirectorCommission || null;
			this[`${type}BrokerCommission`] = newVal?.brokerCommission || null;
			this[`${type}Recruiter`] = newVal?.recruiter || null;
			this[`${type}RecruiterPercentage`] = newVal?.recruiterCommission || null;
			this[`${type}RecruiterDirector`] = newVal?.recruiterDirector || null;
			this[`${type}RecruiterDirectorPercentage`] = newVal?.recruiterDirectorCommission || null;
		},
		resetFinderSeller(finder) {
			if (finder) this.finder = "";
			else this.seller = "";
		},
		setBillingDates(type) {
			if (type === "5050") {
				this.billingDates = [
					{ key: 0, type: "CPCV", predictedExecutionDate: this.cpcvDate, percentage: 50 },
					{ key: 1, type: "ESC", predictedExecutionDate: this.deedDate, percentage: 50 },
				];
			} else {
				this.billingDates = [
					{
						key: 0,
						type,
						predictedExecutionDate: this[`${type === "CPCV" ? "cpcv" : "deed"}Date`],
						percentage: 100,
					},
				];
			}
		},
		getIsInternal(group) {
			return (
				false && this.company !== "realtyart" && !this[`isExternal${group === "owners" ? "Finder" : "Seller"}`]
			);
		},
		isFormValid() {
			if (!this.$refs.form.validate()) return false;

			return true;
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { all: true, infoLevel: 2 });

			if (response.status === 200) {
				this.users = response.data.users;
				this.consultants = response.data.users.filter(u =>
					["consultant", "teamLeader", "commercialDirector", "broker", "creditManager"].includes(u.role),
				);
				this.managers = response.data.users.filter(
					u =>
						u.active &&
						u.offices.some(o => this.selectedOffices.includes(o)) &&
						["coo", "cfo", "broker", "hr", "processManager", "creditManager", "teamLeader", "assistant"].includes(
							u.role,
						),
				);
			}
		},
		async addBillingDate(index) {
			this.billingDates.push({
				key: index + 1,
				type: this.dealType === "renting" ? "ASS" : "CPCV",
				predictedExecutionDate: "",
				executionDate: "",
				percentage: 100,
			});

			for (const date of this.billingDates) {
				date.percentage = 100 / this.billingDates.length;
			}
		},
		async deleteBillingDate(key) {
			this.billingDates = this.billingDates.filter(d => d.key !== key);

			for (const date of this.billingDates) {
				date.percentage = 100 / this.billingDates.length;
			}
		},
		async addPerson(group) {
			this[group].push({ key: this[group].length + 1 });

			if (group === "owners") {
				for (const person of this[group]) {
					person.percentage = 100 / this[group].length;
				}
			}
		},
		async deletePerson(group, key) {
			this[group] = this[group].filter(d => d.key !== key);

			if (group === "owners") {
				for (const person of this[group]) {
					person.percentage = 100 / this[group].length;
				}
			}
		},
		setSearch(value, key) {
			this[key] = value;

			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this.handleGetFillables();
			}, 800);
		},
		async handleGetFillables() {
			this.loadingInfo = true;

			const { search } = this;

			if (search) {
				const findingsResponse = await getFindings(0, 10, "date", true, { search });
				const viabilitiesResponse = await getViabilities(0, 10, "date", true, { search });

				if (findingsResponse.status === 200 && viabilitiesResponse.status === 200) {
					const findings = findingsResponse.data.findings.map(f => ({ ...f, type: "Angariação" }));
					const viabilities = viabilitiesResponse.data.viabilities.map(f => ({ ...f, type: "Viabilidade" }));

					this.fillables = findings.concat(viabilities);
				}
			}

			this.loadingInfo = false;
		},
		populateInfo() {
			if (!this.fillable) return;

			this.number = this.fillable.number;
			this.finder = this.consultants.find(
				c => c._id === (this.fillable.finder?._id || this.fillable.consultant?._id),
			);
			this.seller = this.consultants.find(c => c._id === this.fillable.consultant?._id);
			this.houseValue = this.fillable.houseValue;
			this.value = this.fillable.value;
			this.address = this.fillable.address;
			this.postalCode = this.fillable.postalCode;
			this.city = this.fillable.city;
			this.houseType = this.fillable.houseType;
			this.area = this.fillable.area;
			this.rooms = this.fillable.rooms;
			this.owners[0].name = this.fillable.owner;
			this.ref = this.fillable.ref;

			// Viability
			if (this.fillable.buyers) this.buyers = this.fillable.buyers;
			this.creditManager = this.fillable.creditManager;
			this.internalCredit = "yes";

			if (this.fillable.requests) {
				const hasChosen = this.fillable.requests.find(r => r.chosen);
				this.creditValue = hasChosen ? hasChosen.chosenCredit : null;
				this.creditBank = hasChosen ? hasChosen.bank : null;
			}
		},
		async handleAddReservation() {
			this.loading = true;

			const {
				ref,
				dealType,
				state,
				number,
				reservationDate,
				address,
				postalCode,
				city,
				houseType,
				area,
				rooms,
				condominium,
				predialRegistrationCertificate,
				predialBooklet,
				usageLicense,
				luExemptionCertificate,
				infrastructureCertificate,
				housingTechnicalSheet,
				energeticCertificate,
				propertyPlan,
				kyc,
				preferenceRights,
				purpose,
				appraisal,
				appraisalDate,
				owners,
				buyers,
				guarantors,
				finder,
				isExternalFinder,
				finderPercentage,
				finderTeam,
				finderCommercialDirector,
				finderCommercialDirectorCommission,
				finderBrokerCommission,
				finderRecruiter,
				finderRecruiterPercentage,
				finderRecruiterDirector,
				finderRecruiterDirectorPercentage,
				seller,
				isExternalSeller,
				sellerPercentage,
				sellerTeam,
				sellerCommercialDirector,
				sellerCommercialDirectorPercentage,
				sellerBrokerPercentage,
				sellerRecruiter,
				sellerRecruiterPercentage,
				sellerRecruiterDirector,
				sellerRecruiterDirectorPercentage,
				externalOffice,
				externalContact,
				externalStaffContact,
				externalNif,
				externalAddress,
				processManagers,
				creditManagers,
				financeManagers,
				brokers,
				teamLeaders,
				assistants,
				value,
				officePercentage,
				percentageSplit,
				impic,
				moneyLaundering,
				riskAnalysis,
				createdBy,
				observations,
				billingDates,
				cpcvDate,
				deedDeadlineDuration,
				deedDate,
				impicDate,
				giveUpDate,
				giveUpReason,
				scheduledDate,
				creditManager,
				internalCredit,
				houseValue,
				signalValue,
				rentingDuration,
				numberOfRents,
				numberOfDeposits,
				creditValue,
				creditBank,
				bankCommission,
				bankCommissionDate,
				insurance,
			} = this;

			if (!this.isFormValid()) {
				this.loading = false;

				return this.openToast({ message: "Faltam campos obrigatórios", color: "red" });
			}

			const totalBillingDatesPercentage = this.billingDates.reduce((acc, d) => acc + Number(d.percentage), 0);
			const totalOwnersPercentage = this.owners.reduce((acc, o) => acc + Number(o.percentage), 0);

			if (totalBillingDatesPercentage !== 100 || totalOwnersPercentage !== 100) {
				this.loading = false;

				return this.openToast({ message: "A percentagem total têm de ser 100%", color: "red" });
			}

			const response = await addReservation({
				ref,
				dealType,
				state,
				number,
				reservationDate,
				address,
				postalCode,
				city,
				houseType,
				area,
				rooms,
				condominium,
				predialRegistrationCertificate,
				predialBooklet,
				usageLicense,
				luExemptionCertificate,
				infrastructureCertificate,
				housingTechnicalSheet,
				energeticCertificate,
				propertyPlan,
				kyc,
				preferenceRights,
				purpose,
				appraisal,
				appraisalDate,
				owners,
				buyers,
				guarantors,
				finder: finder._id && isExternalFinder ? finder.name : finder._id || finder.name || finder,
				isExternalFinder,
				finderPercentage,
				finderTeam,
				finderCommercialDirector,
				finderCommercialDirectorCommission,
				finderBrokerCommission,
				finderRecruiter,
				finderRecruiterPercentage,
				finderRecruiterDirector,
				finderRecruiterDirectorPercentage,
				seller: seller._id && isExternalSeller ? seller.name : seller._id || seller.name || seller,
				isExternalSeller,
				sellerPercentage,
				sellerTeam,
				sellerCommercialDirector,
				sellerCommercialDirectorPercentage,
				sellerBrokerPercentage,
				sellerRecruiter,
				sellerRecruiterPercentage,
				sellerRecruiterDirector,
				sellerRecruiterDirectorPercentage,
				externalOffice,
				externalContact,
				externalStaffContact,
				externalNif,
				externalAddress,
				processManagers,
				creditManagers,
				financeManagers,
				brokers,
				teamLeaders,
				assistants,
				value,
				officePercentage,
				percentageSplit,
				impic,
				moneyLaundering: moneyLaundering || null,
				riskAnalysis: riskAnalysis || null,
				user: createdBy,
				observations,
				billingDates,
				cpcvDate,
				deedDeadlineDuration,
				deedDate,
				impicDate,
				giveUpDate,
				giveUpReason: giveUpReason && giveUpReason.key ? giveUpReason.key : giveUpReason,
				scheduledDate,
				creditManager: creditManager || null,
				internalCredit: internalCredit || null,
				houseValue,
				signalValue,
				rentingDuration,
				numberOfRents,
				numberOfDeposits,
				creditValue,
				creditBank: creditBank && creditBank.key,
				bankCommission,
				bankCommissionDate,
				insurance,
			});

			if (response.status === 201) {
				if (this.$refs.files) await this.$refs.files.handleFileUpload(response.data._id);

				this.onClose();
				this.resetReservation();
			} else if (response.status === 400) {
				this.openToast({ message: "Escolha uma agência", color: "red" });
			} else if (response.status === 409) {
				this.openToast({ message: "Essa referência já existe", color: "red" });
			}

			this.loading = false;
		},
		async handleEditReservation() {
			this.loading = true;

			const {
				ref,
				dealType,
				state,
				number,
				reservationDate,
				address,
				postalCode,
				city,
				houseType,
				area,
				rooms,
				condominium,
				predialRegistrationCertificate,
				predialBooklet,
				usageLicense,
				luExemptionCertificate,
				infrastructureCertificate,
				housingTechnicalSheet,
				energeticCertificate,
				propertyPlan,
				kyc,
				preferenceRights,
				purpose,
				appraisal,
				appraisalDate,
				owners,
				buyers,
				guarantors,
				finder,
				isExternalFinder,
				finderPercentage,
				finderTeam,
				finderCommercialDirector,
				finderCommercialDirectorCommission,
				finderBrokerCommission,
				finderRecruiter,
				finderRecruiterPercentage,
				finderRecruiterDirector,
				finderRecruiterDirectorPercentage,
				seller,
				isExternalSeller,
				sellerPercentage,
				sellerTeam,
				sellerCommercialDirector,
				sellerCommercialDirectorPercentage,
				sellerBrokerPercentage,
				sellerRecruiter,
				sellerRecruiterPercentage,
				sellerRecruiterDirector,
				sellerRecruiterDirectorPercentage,
				externalOffice,
				externalContact,
				externalStaffContact,
				externalNif,
				externalAddress,
				processManagers,
				creditManagers,
				financeManagers,
				brokers,
				teamLeaders,
				assistants,
				value,
				officePercentage,
				percentageSplit,
				impic,
				moneyLaundering,
				riskAnalysis,
				createdBy,
				observations,
				billingDates,
				cpcvDate,
				deedDeadlineDuration,
				deedDate,
				impicDate,
				giveUpDate,
				giveUpReason,
				scheduledDate,
				creditManager,
				internalCredit,
				houseValue,
				signalValue,
				rentingDuration,
				numberOfRents,
				numberOfDeposits,
				creditValue,
				creditBank,
				bankCommission,
				bankCommissionDate,
				insurance,
			} = this;

			if (!this.isFormValid()) {
				this.loading = false;

				return this.openToast({ message: "Faltam campos obrigatórios", color: "red" });
			}

			const totalBillingDatesPercentage = this.billingDates.reduce((acc, d) => acc + Number(d.percentage), 0);
			const totalOwnersPercentage = this.owners.reduce((acc, o) => acc + Number(o.percentage), 0);

			if (totalBillingDatesPercentage !== 100 || totalOwnersPercentage !== 100) {
				this.loading = false;

				return this.openToast({ message: "A percentagem total têm de ser 100%", color: "red" });
			}

			const response = await editReservation({
				_id: this.reservation._id,
				ref,
				dealType,
				state,
				number,
				reservationDate,
				address,
				postalCode,
				city,
				houseType,
				area,
				rooms,
				condominium,
				predialRegistrationCertificate,
				predialBooklet,
				usageLicense,
				luExemptionCertificate,
				infrastructureCertificate,
				housingTechnicalSheet,
				energeticCertificate,
				propertyPlan,
				kyc,
				preferenceRights,
				purpose,
				appraisal,
				appraisalDate,
				owners,
				buyers,
				guarantors,
				finder: finder._id && isExternalFinder ? finder.name : finder._id || finder.name || finder,
				isExternalFinder,
				finderPercentage,
				finderTeam,
				finderCommercialDirector,
				finderCommercialDirectorCommission,
				finderBrokerCommission,
				finderRecruiter,
				finderRecruiterPercentage,
				finderRecruiterDirector,
				finderRecruiterDirectorPercentage,
				seller: seller._id && isExternalSeller ? seller.name : seller._id || seller.name || seller,
				isExternalSeller,
				sellerPercentage,
				sellerTeam,
				sellerCommercialDirector,
				sellerCommercialDirectorPercentage,
				sellerBrokerPercentage,
				sellerRecruiter,
				sellerRecruiterPercentage,
				sellerRecruiterDirector,
				sellerRecruiterDirectorPercentage,
				externalOffice,
				externalContact,
				externalStaffContact,
				externalNif,
				externalAddress,
				processManagers,
				creditManagers,
				financeManagers,
				brokers,
				teamLeaders,
				assistants,
				value,
				officePercentage,
				percentageSplit,
				impic,
				moneyLaundering: moneyLaundering || null,
				riskAnalysis: riskAnalysis || null,
				user: createdBy,
				observations,
				billingDates,
				cpcvDate,
				deedDeadlineDuration,
				deedDate,
				impicDate,
				giveUpDate,
				giveUpReason: giveUpReason && giveUpReason.key ? giveUpReason.key : giveUpReason,
				scheduledDate,
				creditManager: creditManager || null,
				internalCredit: internalCredit || null,
				houseValue,
				signalValue,
				rentingDuration,
				numberOfRents,
				numberOfDeposits,
				creditValue,
				creditBank: creditBank && creditBank.key,
				bankCommission,
				bankCommissionDate,
				insurance,
			});

			if (response.status === 200) {
				if (this.$refs.files) await this.$refs.files.handleFileUpload();

				this.onClose();
			} else if (response.status === 409) {
				this.openToast({ message: "Essa referência já existe", color: "red" });
			}

			this.loading = false;
		},
	},
});
</script>
