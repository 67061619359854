<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form ref="form" @submit.prevent="handleSubmit">
			<v-row justify="center" no-gutters>
				<v-col style="margin: 40px; max-width: 700px">
					<v-text-field
						outlined
						v-model="name"
						label="Nome"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-text-field outlined v-model="completeName" label="Nome Completo" />
					<v-select
						outlined
						v-model="gender"
						:items="config.genders"
						item-text="name"
						item-value="key"
						label="Género"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-menu
						v-model="showBirthdayPicker"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="birthday"
								label="Data de Nascimento"
								v-bind="attrs"
								v-on="on"
								:rules="[required]"
								hint="Obrigatório"
								persistent-hint
								readonly
							/>
						</template>
						<v-date-picker
							ref="birthdayPicker"
							v-model="birthday"
							color="primary"
							:max="new Date().toISOString().substr(0, 10)"
							@input="showBirthdayPicker = false"
						/>
					</v-menu>
					<v-text-field outlined v-model="personalEmail" label="Email Pessoal" :rules="[validEmail]" />
					<v-row class="twoColRow">
						<v-col>
							<v-text-field
								outlined
								v-model="phone"
								label="Nº de telefone"
								:rules="[required, validPhone]"
								hint="Obrigatório"
								persistent-hint
							/>
						</v-col>
						<v-col>
							<v-text-field outlined v-model="phone2" label="Nº de telefone 2" :rules="[validPhone]" />
						</v-col>
					</v-row>
					<v-select
						outlined
						v-model="qualifications"
						:items="config.qualifications"
						item-text="name"
						item-value="key"
						label="Habilitações literárias"
					/>
					<v-autocomplete
						outlined
						v-model="country"
						:items="countries"
						item-text="name"
						item-value="value"
						label="País"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
						hide-selected
					/>
					<div v-if="country !== 'PT'">
						<v-row class="twoColRow">
							<v-col>
								<v-checkbox outlined v-model="residenceTitle" label="Título de residência" />
							</v-col>
							<v-col v-if="residenceTitle">
								<v-menu
									v-model="showExpirationDatePicker"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="residenceExpirationDate"
											label="Data de Validade"
											v-bind="attrs"
											v-on="on"
											:rules="[required]"
											hint="Obrigatório"
											persistent-hint
											readonly
											clearable
										/>
									</template>
									<v-date-picker
										v-model="residenceExpirationDate"
										color="primary"
										:min="new Date().toISOString().substr(0, 10)"
										@input="showExpirationDatePicker = false"
									/>
								</v-menu>
							</v-col>
						</v-row>
						<v-text-field
							v-if="residenceTitle"
							outlined
							v-model="residenceTitleNumber"
							label="Nº do título de residência"
						/>
					</div>
					<v-row class="twoColRow">
						<v-col>
							<v-text-field
								outlined
								v-model="cc"
								:label="country === 'PT' ? 'Nº de Cartão de Cidadão' : 'Passaporte/BI/CC'"
								:rules="country === 'PT' ? [validCc] : []"
								hide-details
							/>
						</v-col>
						<v-col>
							<v-menu
								v-model="showExpirationDatePicker"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										outlined
										v-model="expirationDate"
										label="Data de Validade do CC"
										v-bind="attrs"
										v-on="on"
										readonly
										hide-details
										clearable
									/>
								</template>
								<v-date-picker
									v-model="expirationDate"
									color="primary"
									:min="new Date().toISOString().substr(0, 10)"
									@input="showExpirationDatePicker = false"
								/>
							</v-menu>
						</v-col>
					</v-row>
					<v-row class="twoColRow">
						<v-col>
							<v-text-field
								outlined
								v-model="nif"
								label="NIF"
								type="number"
								:rules="[required, validNif]"
								hint="Obrigatório"
								persistent-hint
							/>
						</v-col>
						<v-col>
							<v-text-field
								v-if="!['consultant', 'teamLeader'].includes(role)"
								outlined
								v-model="niss"
								label="NISS"
								type="number"
								:rules="[validNiss]"
							/>
						</v-col>
					</v-row>
					<v-text-field outlined v-model="iban" label="IBAN" />
					<v-text-field outlined v-model="address" label="Morada" />
					<v-row class="twoColRow">
						<v-col>
							<v-text-field outlined v-model="postalCode" label="Código Postal" :rules="[validPostalCode]" />
						</v-col>
						<v-col>
							<v-text-field outlined v-model="city" label="Cidade" />
						</v-col>
					</v-row>
					<br />
					<v-text-field
						outlined
						v-model="socialMedia.facebook"
						label="Facebook"
						placeholder="..."
						prepend-icon="mdi-facebook"
						prefix="facebook.com/"
						hide-details
						style="padding-bottom: 10px"
					/>
					<v-text-field
						outlined
						v-model="socialMedia.instagram"
						label="Instagram"
						placeholder="..."
						prepend-icon="mdi-instagram"
						prefix="instagram.com/"
						hide-details
						style="padding-bottom: 10px"
					/>
					<v-text-field
						outlined
						v-model="socialMedia.twitter"
						label="Twitter"
						placeholder="..."
						prepend-icon="mdi-twitter"
						prefix="twitter.com/"
						hide-details
						style="padding-bottom: 10px"
					/>
					<v-text-field
						outlined
						v-model="socialMedia.linkedin"
						label="LinkedIn"
						placeholder="..."
						prepend-icon="mdi-linkedin"
						prefix="linkedin.com/in/"
					/>
					<v-checkbox
						v-if="staff"
						outlined
						v-model="resetPassword"
						label="Reset Password"
						style="padding: 0px; margin: 0px"
					/>
				</v-col>
				<v-divider vertical />
				<v-col style="margin: 40px; max-width: 700px">
					<div v-if="image && !editImage" @click="editImage = true" style="margin-bottom: 20px">
						<v-badge color="primary" icon="mdi-pencil" overlap>
							<img :src="getFileUrl(this.image)" height="145px" />
						</v-badge>
					</div>
					<div v-else style="margin-bottom: 30px">
						<VueFileAgent
							accept="image/*"
							helpText="Escolha uma imagem ou faça drag & drop"
							:errorText="{ type: 'Tipo de ficheiro inválido. Só são aceites imagens' }"
							v-model="file"
							class="imageFile"
						>
						</VueFileAgent>
					</div>
					<v-text-field
						outlined
						v-model="email"
						label="Email"
						:rules="[required, validEmail]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-row class="twoColRow">
						<v-col>
							<v-select
								outlined
								v-model="role"
								:items="config.roles"
								item-text="name"
								item-value="key"
								label="Cargo"
								:rules="[required]"
								hint="Obrigatório"
								persistent-hint
								:disabled="isLead"
							/>
							<v-select
								v-if="!isLead"
								outlined
								v-model="otherRoles"
								:items="config.roles"
								item-text="name"
								item-value="key"
								label="Outros Cargos"
								multiple
								chips
								hide-details
							/>
						</v-col>
						<v-col>
							<v-select
								outlined
								v-model="office"
								:items="offices"
								item-text="name"
								item-value="key"
								multiple
								small-chips
								label="Agência"
								:rules="[requiresOffice]"
								hint="Obrigatório"
								persistent-hint
							/>
						</v-col>
						<v-col v-if="['recruiter', 'expansionDirector'].includes(role)">
							<v-select
								outlined
								v-model="contactCenter"
								:items="offices"
								item-text="name"
								item-value="key"
								multiple
								small-chips
								label="Contact Center"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-autocomplete
								outlined
								v-model="manager"
								:items="managers"
								item-text="name"
								item-value="_id"
								label="Responsável"
								clearable
							/>
						</v-col>
						<v-col>
							<v-combobox
								outlined
								v-model="team"
								:items="config.teams.filter(t => t.active)"
								item-text="name"
								item-value="key"
								label="Equipa"
								clearable
							/>
						</v-col>
					</v-row>
					<v-autocomplete
						outlined
						item-value="_id"
						item-text="name"
						label="Referência"
						v-model="referencedBy"
						:items="consultants"
						clearable
					/>
					<v-autocomplete
						v-if="company === 'realtyart'"
						outlined
						item-value="_id"
						item-text="name"
						label="Diretor de Recrutamento"
						v-model="recruiterDirector"
						:items="managers"
						clearable
					/>
					<v-row>
						<v-col>
							<v-autocomplete
								outlined
								item-value="_id"
								item-text="name"
								label="Recrutador"
								v-model="recruiter"
								:items="managers"
								clearable
							/>
						</v-col>
						<v-col>
							<v-select
								outlined
								v-model="source"
								:items="config.sources"
								item-text="name"
								item-value="key"
								label="Fonte"
								clearable
							/>
						</v-col>
					</v-row>
					<v-autocomplete
						outlined
						item-value="_id"
						item-text="name"
						label="Integrador"
						v-model="integration"
						:items="managers"
						clearable
					/>
					<v-row class="twoColRow">
						<v-col>
							<v-select
								outlined
								v-model="contractType"
								:items="
									config.contractTypes.filter(t =>
										['consultant', 'teamLeader'].includes(role) ? t.type === 'consultant' : !t.type,
									)
								"
								item-text="name"
								item-value="key"
								label="Tipo de Contrato"
								:rules="[required]"
								hint="Obrigatório"
								persistent-hint
								style="max-width: 200px"
							/>
						</v-col>
						<v-col style="padding: 0px; margin: 0px">
							<v-checkbox outlined v-model="fullTime" label="Tempo Inteiro" style="padding: 0px; margin: 0px" />
							<v-checkbox outlined v-model="experient" label="Experiente" style="padding: 0px; margin: 0px" />
						</v-col>
					</v-row>
					<v-row class="twoColRow">
						<v-col>
							<v-menu
								v-model="showStartDatePicker"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										outlined
										v-model="startDate"
										label="Início de Contrato"
										v-bind="attrs"
										v-on="on"
										:rules="[required]"
										hint="Obrigatório"
										persistent-hint
										readonly
										clearable
									/>
								</template>
								<v-date-picker v-model="startDate" color="primary" @input="showStartDatePicker = false" />
							</v-menu>
						</v-col>
						<v-col v-if="contractType === 'hasTerm' || contractType === 'cf' || staff">
							<v-menu
								v-model="showEndDatePicker"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										outlined
										v-model="endDate"
										label="Fim de Contrato"
										v-bind="attrs"
										v-on="on"
										:rules="[hasTermContract]"
										readonly
										clearable
									/>
								</template>
								<v-date-picker v-model="endDate" color="primary" @input="showEndDatePicker = false" />
							</v-menu>
						</v-col>
					</v-row>
					<v-text-field v-if="endDate" outlined v-model="contractEndReason" label="Motivo de Saída" />
					<div v-if="['consultant', 'teamLeader', 'commercialDirector'].includes(role)">
						<v-row class="twoColRow">
							<v-col>
								<v-combobox
									outlined
									v-model="commission"
									:items="[40, 47.8, 50, 57.3, 60]"
									label="Comissão"
									append-icon="mdi-percent"
									type="number"
									:rules="[required]"
									hint="Obrigatório"
									persistent-hint
									:disabled="staff && !isLead ? !features.editCommissions : false"
								/>
							</v-col>
							<v-col>
								<v-combobox
									outlined
									v-model="bankCommission"
									:items="[20, 50, 60]"
									label="Comissão Bancária"
									append-icon="mdi-percent"
									type="number"
									:rules="[required]"
									hint="Obrigatório"
									persistent-hint
									:disabled="staff && !isLead ? !features.editCommissions : false"
								/>
							</v-col>
						</v-row>
						<v-row v-if="features.editResponsableCommissions" class="twoColRow">
							<v-col>
								<v-text-field
									outlined
									v-model="commercialDirectorCommission"
									label="Comissão do Responsável"
									append-icon="mdi-percent"
									type="number"
								/>
							</v-col>
							<v-col>
								<v-text-field
									outlined
									v-model="commercialDirectorBankCommission"
									label="Comissão Bancária do Responsável"
									append-icon="mdi-percent"
									type="number"
								/>
							</v-col>
						</v-row>
						<v-row v-if="features.editResponsableCommissions" class="twoColRow">
							<v-col>
								<v-text-field
									v-if="features.editResponsableCommissions"
									outlined
									v-model="brokerCommission"
									label="Comissão do Broker"
									append-icon="mdi-percent"
									type="number"
								/>
							</v-col>
							<v-col>
								<v-text-field
									v-if="features.editResponsableCommissions"
									outlined
									v-model="cooCommission"
									label="Comissão do COO"
									append-icon="mdi-percent"
									type="number"
								/>
							</v-col>
						</v-row>
						<v-text-field
							v-if="features.editResponsableCommissions"
							outlined
							v-model="creditManagerCommission"
							label="Comissão da Gestora de Crédito"
							append-icon="mdi-percent"
							type="number"
						/>
						<v-row v-if="features.editResponsableCommissions" class="twoColRow">
							<v-col>
								<v-text-field
									outlined
									v-model="recruiterCommission"
									label="Comissão do Recrutador"
									append-icon="mdi-percent"
									type="number"
								/>
							</v-col>
							<v-col v-if="company === 'realtyart'">
								<v-text-field
									outlined
									v-model="recruiterDirectorCommission"
									label="Comissão do Diretor de Recrutamento"
									append-icon="mdi-percent"
									type="number"
								/>
							</v-col>
						</v-row>
						<v-select
							outlined
							v-model="invoice"
							:items="config.invoiceTypes"
							item-text="name"
							item-value="key"
							label="Fatura/Recibo Verde"
						/>
						<v-row class="twoColRow" style="height: 45px">
							<v-col style="padding: 0px 12px">
								<v-checkbox outlined v-model="hasComputer" label="Tem Computador" style="margin: 0px" />
							</v-col>
							<v-col style="padding: 0px 12px">
								<v-checkbox outlined v-model="hasPhone" label="Tem Telemóvel" style="margin: 0px" />
							</v-col>
						</v-row>
						<v-row class="twoColRow" style="height: 35px">
							<v-col style="padding: 0px 12px">
								<v-checkbox outlined v-model="hasVehicle" label="Tem Viatura" style="margin: 0px" />
							</v-col>
						</v-row>
						<v-row class="twoColRow">
							<v-col>
								<v-autocomplete
									outlined
									v-model="languages"
									:items="languagesList"
									item-text="name"
									item-value="value"
									label="Línguas"
									multiple
									chips
								/>
							</v-col>
							<v-col>
								<v-select
									outlined
									v-model="shirtSize"
									:items="config.shirtSizes"
									item-text="name"
									item-value="key"
									label="Tamanho T-shirt"
								/>
							</v-col>
						</v-row>
						<v-row class="twoColRow">
							<v-col>
								<v-menu
									v-model="showIntegrationMeetingPicker"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="integrationMeetingDate"
											label="Data da Reunião de Integração"
											v-bind="attrs"
											v-on="on"
											readonly
											clearable
										/>
									</template>
									<v-date-picker
										v-model="integrationMeetingDate"
										color="primary"
										@input="showIntegrationMeetingPicker = false"
									/>
								</v-menu>
							</v-col>
							<v-col>
								<v-menu
									v-model="showIntegrationMeetingTimePicker"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="integrationMeetingTime"
											label="Hora da Reunião de Integração"
											v-bind="attrs"
											v-on="on"
											clearable
										/>
									</template>
									<v-time-picker
										v-model="integrationMeetingTime"
										color="primary"
										format="24hr"
										@input="showIntegrationMeetingTimePicker = false"
									/>
								</v-menu>
							</v-col>
						</v-row>
						<v-row class="twoColRow">
							<v-col>
								<v-menu
									v-model="showVitamina21DatePicker"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="vitamina21Date"
											label="Data do BEGIN"
											v-bind="attrs"
											v-on="on"
											readonly
											clearable
										/>
									</template>
									<v-date-picker
										v-model="vitamina21Date"
										color="primary"
										@input="showVitamina21DatePicker = false"
										:max="vitamina21EndDate"
									/>
								</v-menu>
							</v-col>
							<v-col>
								<v-menu
									v-model="showVitamina21EndDatePicker"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="vitamina21EndDate"
											label="Data Final do BEGIN"
											v-bind="attrs"
											v-on="on"
											readonly
											clearable
										/>
									</template>
									<v-date-picker
										v-model="vitamina21EndDate"
										color="primary"
										@input="showVitamina21EndDatePicker = false"
										:min="vitamina21Date"
									/>
								</v-menu>
							</v-col>
						</v-row>
						<v-menu
							v-model="showCreate21Picker"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									outlined
									v-model="create21Date"
									label="Data do Create21"
									v-bind="attrs"
									v-on="on"
									readonly
									clearable
								/>
							</template>
							<v-date-picker v-model="create21Date" color="primary" @input="showCreate21Picker = false" />
						</v-menu>
						<v-select
							outlined
							v-model="selectedOffers"
							:items="config.offers"
							item-text="name"
							item-value="key"
							chips
							label="Ofertas"
							multiple
						/>
					</div>
					<v-textarea outlined v-model="observations" label="Observações" height="145px" />
					<v-checkbox
						v-if="!staff && !isLead"
						outlined
						v-model="sendRecruitedEmails"
						label="Enviar emails de recrutado"
					/>
					<VueFileAgent
						v-if="isLead"
						multiple
						theme="list"
						deletable
						@beforedelete="deleteFileToUpload($event)"
						helpText="Escolha ficheiros ou faça drag & drop"
						:errorText="{
							type: 'Tipo de ficheiro inválido. Só são aceites PDFs',
						}"
						v-model="filesToUpload"
					/>
				</v-col>
			</v-row>
			<v-btn :disabled="loading" type="submit" color="primary">
				{{ staff && !isLead ? "Atualizar" : "Adicionar" }}
			</v-btn>
			<br /><br />
		</v-form>
		<v-btn v-if="isLead" :disabled="loading" type="submit" color="primary" @click="handleAddUserDraft">
			{{ "Guardar Rascunho" }}
		</v-btn>
		<br v-if="isLead" /><br v-if="isLead" />
	</v-sheet>
</template>

<script>
import Vue from "vue";
import { countries } from "countries-list";
import { all as languagesList } from "locale-codes";
import { mapState, mapMutations } from "vuex";
import dayjs from "dayjs";

import { getUsers, addUser, editUser } from "../api/users";
import { addUserDraft } from "../api/leads";
import { addFiles } from "../api/files";

import { formatDate, validateCC, getFileUrl, getFileName } from "../utils/utils";

export default Vue.extend({
	name: "StaffForm",
	props: ["staff", "isLead", "onClose"],
	async created() {
		await this.handleGetUsers();

		if (this.staff) {
			this.populateStaff(this.staff);
		}

		this.languagesList = languagesList.map(language => ({
			name: `${language.local || language.name} ${language.location ? `(${language.location})` : ""}`,
			value: language.tag,
		}));
		this.countries = Object.entries(countries).map(([key, value]) => ({ name: value.name, value: key }));
	},
	watch: {
		staff: function (newVal) {
			if (newVal) {
				this.populateStaff(newVal);
			} else {
				this.email = "";
				this.role = "";
				this.otherRoles = [];
				this.office = [];
				this.contactCenter = [];
				this.team = null;
				this.manager = null;
				this.startDate = "";
				this.endDate = "";
				this.contractEndReason = "";
				this.contractType = "";
				this.fullTime = true;
				this.experient = false;
				this.recruiter = null;
				this.recruiterDirector = null;
				this.source = null;
				this.integration = null;
				this.referencedBy = null;
				this.commission = 50;
				this.bankCommission = 20;
				this.commercialDirectorCommission = null;
				this.commercialDirectorBankCommission = null;
				this.brokerCommission = null;
				this.cooCommission = null;
				this.creditManagerCommission = null;
				this.recruiterCommission = null;
				this.recruiterDirectorCommission = null;
				this.invoice = true;
				this.hasComputer = false;
				this.hasPhone = false;
				this.hasVehicle = false;
				this.languages = [];
				this.shirtSize = null;
				this.vitamina21Date = null;
				this.vitamina21EndDate = null;
				this.integrationMeetingDate = null;
				this.integrationMeetingTime = null;
				this.create21Date = null;
				this.selectedOffers = [];
				this.observations = "";
				this.socialMedia = { facebook: "", instagram: "", twitter: "", linkedin: "" };
				this.resetPassword = false;
				this.sendRecruitedEmails = false;

				this.image = "";
				this.name = "";
				this.completeName = "";
				this.gender = "";
				this.birthday = "";
				this.phone = "";
				this.phone2 = "";
				this.personalEmail = "";
				this.qualifications = "";
				this.country = "PT";
				this.residenceTitle = false;
				this.residenceExpirationDate = "";
				this.residenceTitleNumber = null;
				this.cc = "";
				this.expirationDate = "";
				this.nif = null;
				this.niss = null;
				this.iban = "";
				this.address = "";
				this.postalCode = "";
				this.city = "";

				this.file = null;
				this.filesToUpload = [];
				this.editImage = false;
			}

			this.$refs.form.resetValidation();
		},
		showBirthdayPicker(val) {
			val && setTimeout(() => (this.$refs.birthdayPicker.activePicker = "YEAR"));
		},
	},
	computed: {
		...mapState(["user", "company", "features", "token", "config", "offices"]),
		apiUrl() {
			return process.env.VUE_APP_API_URL;
		},
	},
	data() {
		const emailRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const postalCodeRegex = /^[0-9]{4}-[0-9]{3}$/;

		return {
			loading: false,

			required: value => !!value || "Obrigatório",
			validEmail: value => !value || (value && emailRegex.test(value)) || "Email inválido",
			validPhone: value =>
				!value || (value && (value.includes("+") ? true : value.length === 9)) || "Telefone inválido",
			validCc: value => !value || (value && validateCC(value)) || "Cartão de Cidadão inválido",
			validNif: value => (value && value.toString().length === 9) || "NIF inválido",
			validNiss: value => !value || (value && value.toString().length === 11) || "NISS inválido",
			validPostalCode: value => !value || (value && postalCodeRegex.test(value)) || "Código postal inválido",
			requiresOffice: value => (value && value.length) || "Obrigatório",
			hasTermContract: value => (this.contractType === "hasTerm" && !value ? "Obrigatório" : true),

			languagesList: [],
			countries: [],
			consultants: [],
			managers: [],
			showBirthdayPicker: false,
			showExpirationDatePicker: false,
			showStartDatePicker: false,
			showEndDatePicker: false,
			showVitamina21DatePicker: false,
			showVitamina21EndDatePicker: false,
			showIntegrationMeetingPicker: false,
			showIntegrationMeetingTimePicker: false,
			showCreate21Picker: false,
			file: null,
			filesToUpload: [],
			editImage: false,
			editFiles: false,
			search: "",

			email: "",
			role: "",
			otherRoles: [],
			office: [],
			contactCenter: [],
			team: null,
			manager: null,
			startDate: "",
			endDate: "",
			contractEndReason: "",
			contractType: "",
			fullTime: true,
			experient: false,
			recruiter: null,
			recruiterDirector: null,
			source: null,
			integration: null,
			referencedBy: null,
			commission: 50,
			bankCommission: 20,
			commercialDirectorCommission: null,
			commercialDirectorBankCommission: null,
			brokerCommission: null,
			cooCommission: null,
			creditManagerCommission: null,
			recruiterCommission: null,
			recruiterDirectorCommission: null,
			invoice: true,
			hasComputer: false,
			hasPhone: false,
			hasVehicle: false,
			languages: [],
			shirtSize: null,
			vitamina21Date: null,
			vitamina21EndDate: null,
			integrationMeetingDate: null,
			integrationMeetingTime: null,
			create21Date: null,
			selectedOffers: [],

			observations: "",
			socialMedia: {
				facebook: "",
				instagram: "",
				twitter: "",
				linkedin: "",
			},
			resetPassword: false,
			sendRecruitedEmails: false,

			image: "",
			name: "",
			completeName: "",
			gender: "",
			birthday: "",
			phone: "",
			phone2: "",
			personalEmail: "",
			qualifications: "",
			country: "PT",
			residenceTitle: false,
			residenceExpirationDate: "",
			residenceTitleNumber: null,
			cc: "",
			expirationDate: "",
			nif: null,
			niss: null,
			iban: "",
			address: "",
			postalCode: "",
			city: "",
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		getFileUrl,
		getFileName,
		populateStaff(newVal) {
			this.email = newVal.email;
			this.role = newVal.role;
			this.otherRoles = newVal.otherRoles;
			this.office = newVal.offices;
			this.contactCenter = newVal.contactCenter;
			this.team = newVal.team ? this.config.teams.find(t => t.key === newVal.team) : null;
			this.manager = newVal.manager;
			this.startDate = newVal.startDate ? formatDate(newVal.startDate, "YYYY-MM-DD") : "";
			this.endDate = newVal.endDate ? formatDate(newVal.endDate, "YYYY-MM-DD") : "";
			this.contractEndReason = newVal.contractEndReason;
			this.contractType = newVal.contractType;
			this.fullTime = newVal.fullTime;
			this.experient = newVal.experient;
			this.recruiter = newVal.recruiter;
			this.recruiterDirector = newVal.recruiterDirector;
			this.source = newVal.source;
			this.integration = newVal.integration;
			this.referencedBy = this.isLead ? this.staff.reference : newVal.referencedBy;
			this.commission = newVal.commission;
			this.bankCommission = newVal.bankCommission;
			this.commercialDirectorCommission = newVal.commercialDirectorCommission;
			this.commercialDirectorBankCommission = newVal.commercialDirectorBankCommission;
			this.brokerCommission = newVal.brokerCommission;
			this.cooCommission = newVal.cooCommission;
			this.creditManagerCommission = newVal.creditManagerCommission;
			this.recruiterCommission = newVal.recruiterCommission;
			this.recruiterDirectorCommission = newVal.recruiterDirectorCommission;
			this.invoice = newVal.invoice;
			this.hasComputer = newVal.hasComputer;
			this.hasPhone = newVal.hasPhone;
			this.hasVehicle = newVal.hasVehicle;
			this.languages = newVal.languages;
			this.shirtSize = newVal.shirtSize;
			this.vitamina21Date = newVal.vitamina21Date ? formatDate(newVal.vitamina21Date, "YYYY-MM-DD") : "";
			this.vitamina21EndDate = newVal.vitamina21EndDate ? formatDate(newVal.vitamina21EndDate, "YYYY-MM-DD") : "";
			this.integrationMeetingDate = newVal.integrationMeetingDate
				? formatDate(newVal.integrationMeetingDate, "YYYY-MM-DD")
				: "";
			this.integrationMeetingTime = newVal.integrationMeetingDate
				? formatDate(newVal.integrationMeetingDate, "HH:mm")
				: null;
			this.create21Date = newVal.create21Date ? formatDate(newVal.create21Date, "YYYY-MM-DD") : "";
			this.selectedOffers = newVal.offers ? newVal.offers : [];
			this.observations = newVal.observations;
			if (newVal.socialMedia && Object.keys(newVal.socialMedia).length) this.socialMedia = newVal.socialMedia;
			this.resetPassword = false;
			this.sendRecruitedEmails = false;

			this.image = newVal.image;
			this.name = newVal.name;
			this.completeName = newVal.completeName;
			this.gender = newVal.gender;
			this.birthday = newVal.birthday ? formatDate(newVal.birthday, "YYYY-MM-DD") : "";
			this.phone = newVal.phone;
			this.phone2 = newVal.phone2;
			this.personalEmail = newVal.personalEmail;
			this.qualifications = newVal.qualifications;
			this.country = newVal.country;
			this.residenceTitle = newVal.residenceTitle;
			this.residenceExpirationDate = newVal.residenceExpirationDate
				? formatDate(newVal.residenceExpirationDate, "YYYY-MM-DD")
				: "";
			this.residenceTitleNumber = newVal.residenceTitleNumber;
			this.cc = newVal.cc;
			this.expirationDate = newVal.expirationDate ? formatDate(newVal.expirationDate, "YYYY-MM-DD") : "";
			this.nif = newVal.nif;
			this.niss = newVal.niss;
			this.iban = newVal.iban;
			this.address = newVal.address;
			this.postalCode = newVal.postalCode;
			this.city = newVal.city;

			this.files = newVal.files;
			this.file = null;
			this.filesToUpload = [];
			this.editImage = false;
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { all: true });

			if (response.status === 200) {
				this.consultants = response.data.users;
				this.managers = response.data.users.filter(
					u =>
						u.active &&
						[
							"ceo",
							"cfo",
							"coo",
							"broker",
							"commercialDirector",
							"teamLeader",
							"recruiter",
							"expansionDirector",
							"integration",
						].includes(u.role),
				);
			}
		},
		isFormValid() {
			if (!this.$refs.form.validate()) {
				this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

				return false;
			}

			if (this.isLead && this.company !== "realtyart") {
				const cps = this.filesToUpload.find(
					f => f.file.name.toLowerCase().includes("cps") && f.file.name.toLowerCase().includes(".pdf"),
				);

				const cf = this.filesToUpload.find(
					f => f.file.name.toLowerCase().includes("cf") && f.file.name.toLowerCase().includes(".pdf"),
				);

				if (!cps && !cf) {
					this.openToast({ message: "Falta o CPS ou o CF (Tem de ser um PDF)", color: "red" });

					return false;
				}
			}

			return true;
		},
		handleSubmit() {
			return this.staff && !this.isLead ? this.handleEditStaff() : this.handleAddStaff();
		},
		async uploadFiles(id, group) {
			if (this.file) {
				const formData = new FormData();

				formData.append("files", this.file.file);
				formData.append("fileTypes", [this.file.ext]);
				formData.append("fileNames", [`/${this.file.file.name}`]);

				await addFiles(group, id, group, formData, "profileImage");
			}

			if (this.filesToUpload.length) {
				const formData = new FormData();
				const fileTypes = [];
				const fileNames = [];
				for (const file of this.filesToUpload) {
					formData.append("files", file.file);
					fileTypes.push(file.ext);
					fileNames.push(`/${file.file.name}`);
				}

				formData.append("fileTypes", fileTypes);
				formData.append("fileNames", fileNames);

				await addFiles(group, id, group, formData);
			}

			this.file = null;
			this.editImage = false;
		},
		async handleAddUserDraft() {
			this.handleAddStaff(true);
		},
		async handleAddStaff(userDraft) {
			const {
				email,
				role,
				otherRoles,
				office,
				contactCenter,
				team,
				manager,
				startDate,
				endDate,
				contractEndReason,
				contractType,
				fullTime,
				experient,
				recruiter,
				recruiterDirector,
				source,
				integration,
				referencedBy,
				commission,
				bankCommission,
				commercialDirectorCommission,
				commercialDirectorBankCommission,
				brokerCommission,
				cooCommission,
				creditManagerCommission,
				recruiterCommission,
				recruiterDirectorCommission,
				invoice,
				hasComputer,
				hasPhone,
				hasVehicle,
				languages,
				shirtSize,
				vitamina21Date,
				vitamina21EndDate,
				integrationMeetingDate,
				integrationMeetingTime,
				create21Date,
				selectedOffers,
				observations,
				socialMedia,
				name,
				completeName,
				gender,
				birthday,
				phone,
				phone2,
				personalEmail,
				qualifications,
				country,
				residenceTitle,
				residenceExpirationDate,
				residenceTitleNumber,
				cc,
				expirationDate,
				nif,
				niss,
				iban,
				address,
				postalCode,
				city,
				files,
				isLead,
				staff,
				sendRecruitedEmails,
			} = this;

			if (!userDraft && !this.isFormValid()) return;

			this.loading = true;

			const user = {
				email,
				role,
				otherRoles,
				offices: office,
				contactCenter,
				team: team ? team.key || team.replace(/[^A-Z0-9]+/gi, "").toLowerCase() : null,
				teamName: team ? (team.key ? null : team) : null,
				manager,
				startDate,
				endDate,
				contractEndReason,
				contractType,
				fullTime,
				experient,
				recruiter,
				recruiterDirector,
				source,
				integration,
				referencedBy,
				commission,
				bankCommission,
				commercialDirectorCommission,
				commercialDirectorBankCommission,
				brokerCommission,
				cooCommission,
				creditManagerCommission,
				recruiterCommission,
				recruiterDirectorCommission,
				invoice,
				hasComputer,
				hasPhone,
				hasVehicle,
				languages,
				shirtSize,
				vitamina21Date,
				vitamina21EndDate,
				integrationMeetingDate: dayjs(`${integrationMeetingDate} ${integrationMeetingTime}`),
				create21Date,
				offers: selectedOffers,
				observations,
				socialMedia,
				name: name.replace("\t", "").trim(),
				completeName: completeName ? completeName.replace("\t", "").trim() : null,
				gender,
				birthday,
				phone,
				phone2,
				personalEmail,
				qualifications,
				country,
				residenceTitle,
				residenceExpirationDate,
				residenceTitleNumber,
				cc,
				expirationDate,
				nif,
				niss,
				iban,
				address,
				postalCode,
				city,
				files,
				lead: isLead ? staff._id : null,
				sendRecruitedEmails,
			};

			const response = await (userDraft ? addUserDraft(staff._id, user) : addUser(user));

			if (response.status === 201) {
				await this.uploadFiles(response.data._id, "users");

				this.onClose(startDate);

				this.openToast({ message: "Staff adicionado com sucesso", color: "green" });
			} else if (response.status === 409) {
				this.openToast({ message: "Este nome ou email já está registado", color: "red" });
			} else if (response.status === 200) {
				await this.uploadFiles(response.data._id, "leads");

				this.openToast({ message: "Rascunho guardado com sucesso", color: "green" });
			} else {
				this.openToast({ message: "Ocorreu um erro", color: "red" });
			}

			this.loading = false;
		},
		async handleEditStaff() {
			const {
				email,
				role,
				otherRoles,
				office,
				contactCenter,
				team,
				manager,
				startDate,
				endDate,
				contractEndReason,
				contractType,
				fullTime,
				experient,
				recruiter,
				recruiterDirector,
				source,
				integration,
				referencedBy,
				commission,
				bankCommission,
				commercialDirectorCommission,
				commercialDirectorBankCommission,
				brokerCommission,
				cooCommission,
				creditManagerCommission,
				recruiterCommission,
				recruiterDirectorCommission,
				invoice,
				hasComputer,
				hasPhone,
				hasVehicle,
				languages,
				shirtSize,
				vitamina21Date,
				vitamina21EndDate,
				integrationMeetingDate,
				integrationMeetingTime,
				create21Date,
				selectedOffers,
				observations,
				socialMedia,
				resetPassword,
				name,
				completeName,
				gender,
				birthday,
				phone,
				phone2,
				personalEmail,
				qualifications,
				country,
				residenceTitle,
				residenceExpirationDate,
				residenceTitleNumber,
				cc,
				expirationDate,
				nif,
				niss,
				iban,
				address,
				postalCode,
				city,
			} = this;

			if (!this.isFormValid()) return;

			this.loading = true;

			const response = await editUser({
				_id: this.staff._id,
				email,
				role,
				otherRoles,
				offices: office,
				contactCenter,
				team: team ? team.key || team.replace(/[^A-Z0-9]+/gi, "").toLowerCase() : null,
				teamName: team ? (team.key ? null : team) : null,
				manager,
				startDate,
				endDate,
				contractEndReason,
				contractType,
				fullTime,
				experient,
				recruiter,
				recruiterDirector,
				source,
				integration,
				referencedBy,
				commission,
				bankCommission,
				commercialDirectorCommission,
				commercialDirectorBankCommission,
				brokerCommission,
				cooCommission,
				creditManagerCommission,
				recruiterCommission,
				recruiterDirectorCommission,
				invoice,
				hasComputer,
				hasPhone,
				hasVehicle,
				languages,
				shirtSize,
				vitamina21Date,
				vitamina21EndDate,
				integrationMeetingDate: dayjs(`${integrationMeetingDate} ${integrationMeetingTime}`),
				create21Date,
				offers: selectedOffers,
				observations,
				socialMedia,
				resetPassword,
				name: name.replace("\t", "").trim(),
				completeName: completeName ? completeName.replace("\t", "").trim() : null,
				gender,
				birthday,
				phone,
				phone2,
				personalEmail,
				qualifications,
				country,
				residenceTitle,
				residenceExpirationDate,
				residenceTitleNumber,
				cc,
				expirationDate,
				nif,
				niss,
				iban,
				address,
				postalCode,
				city,
			});

			if (response.status === 200) {
				await this.uploadFiles(response.data._id, "users");

				this.onClose();

				this.openToast({ message: "Staff atualizado com sucesso", color: "green" });
			} else if (response.status === 409) {
				this.openToast({ message: "Este nome ou email já está registado", color: "red" });
			}

			this.loading = false;
		},
	},
});
</script>
