<template>
	<v-card
		class="widgetCard"
		width="100%"
		:style="`display: flex; flex-wrap: wrap; justify-content: center ${view === 'table' ? '; padding: 0px' : ''}`"
	>
		<v-data-table
			v-if="view === 'table'"
			:headers="headers"
			:items="officeStats"
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
			:loading="loading"
			item-key="_id"
			must-sort
			no-data-text="Não há resultados"
			no-results-text="Não há resultados"
			hide-default-footer
			width="100%"
		>
			<template v-if="header" v-slot:top>
				<v-toolbar rounded dense flat>
					<v-toolbar-title>{{ header }}</v-toolbar-title>
				</v-toolbar>
			</template>
			<template v-slot:item.rank="{ item }">
				{{ officeStats.indexOf(item) + 1 }}
			</template>
			<template v-for="period in [monthAndYear, year]" :slot="`item.${period}.${stat}`" slot-scope="{ item }">
				<span v-if="item[period]" :key="period">
					{{ stat === "findings" ? item[period][stat] : formatNumber(item[period][stat]) }}
				</span>
			</template>
			<template v-slot:item.pipeline="{ item }">
				<td>{{ formatNumber(item.pipeline) }}</td>
			</template>
			<template v-for="period in [monthAndYear, year]" :slot="`item.${period}.predicted`" slot-scope="{ item }">
				<span v-if="item[period]" :key="period">
					{{ formatNumber(item[period].predicted) }}
				</span>
			</template>
			<template v-for="period in [monthAndYear, year]" :slot="`item.${period}.billingPaid`" slot-scope="{ item }">
				<span v-if="item[period]" :key="period">
					{{ formatNumber(item[period].billingPaid) }}
				</span>
			</template>
			<template v-for="period in [monthAndYear, year]" :slot="`item.${period}.${stat}Goal`" slot-scope="{ item }">
				{{
					item[period] && item[period][`${stat}Goal`]
						? stat == "findings"
							? item[period][`${stat}Goal`]
							: formatNumber(item[period][`${stat}Goal`])
						: "Sem Objetivo"
				}}
			</template>
			<template v-for="period in [monthAndYear, year]" :slot="`item.${period}.${stat}Goal`" slot-scope="{ item }">
				{{
					item[period] && item[period][`${stat}Goal`]
						? stat == "findings"
							? item[period][`${stat}Goal`]
							: formatNumber(item[period][`${stat}Goal`])
						: "Sem Objetivo"
				}}
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}GoalPercent`"
				slot-scope="{ item }"
			>
				<span v-if="item[period] && item[period][`${stat}GoalPercent`]" :key="period">
					{{ formatNumber(item[period][`${stat}GoalPercent`]) }}%
					<v-progress-linear rounded :value="item[period][`${stat}GoalPercent`]" />
				</span>
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}ComparedToGoal`"
				slot-scope="{ item }"
			>
				<PercentageChip
					:key="period"
					:stats="item[period]"
					:stat="`${stat}ComparedToGoal`"
					:type="type"
					:absoluteFirst="true"
					:onClick="e => changeStatsType(e, true)"
				/>
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}ComparedToGoalPercent`"
				slot-scope="{ item }"
			>
				<PercentageChip
					:key="period"
					:stats="item[period]"
					:stat="`${stat}ComparedToGoal`"
					:type="type"
					:onClick="e => changeStatsType(e, true)"
				/>
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}Expected`"
				slot-scope="{ item }"
			>
				<span v-if="item[period] && item[period][`${stat}Expected`]" :key="period">
					{{
						stat == "findings"
							? formatNumber(item[period][`${stat}Expected`], false, false, 0)
							: formatNumber(item[period][`${stat}Expected`])
					}}
				</span>
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}ComparedToExpected`"
				slot-scope="{ item }"
			>
				<PercentageChip
					:key="period"
					:stats="item[period]"
					:stat="`${stat}ComparedToExpected`"
					:type="type"
					:absoluteFirst="true"
					:onClick="e => changeStatsType(e, true)"
				/>
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}ComparedToExpectedPercent`"
				slot-scope="{ item }"
			>
				<PercentageChip
					:key="period"
					:stats="item[period]"
					:stat="`${stat}ComparedToExpected`"
					:type="type"
					:onClick="e => changeStatsType(e, true)"
				/>
			</template>
			<template :slot="`item.${monthAndYear}.${stat}YearlyGrowth${translateStatsType()}`" slot-scope="{ item }">
				<PercentageChip
					:stats="item[monthAndYear]"
					:stat="`${stat}YearlyGrowth`"
					:type="type"
					:onClick="changeStatsType"
				/>
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}PeriodicGrowth${translateStatsType()}`"
				slot-scope="{ item }"
			>
				<PercentageChip
					:key="period"
					:stats="item[period]"
					:stat="`${stat}PeriodicGrowth`"
					:type="type"
					:onClick="changeStatsType"
				/>
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}ComparedToMedian${translateStatsType()}`"
				slot-scope="{ item }"
			>
				<PercentageChip
					:key="period"
					:stats="item[period]"
					:stat="`${stat}ComparedToMedian`"
					:type="type"
					:onClick="changeStatsType"
				/>
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}ComparedToAverage${translateStatsType()}`"
				slot-scope="{ item }"
			>
				<PercentageChip
					:key="period"
					:stats="item[period]"
					:stat="`${stat}ComparedToAverage`"
					:type="type"
					:onClick="changeStatsType"
				/>
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}PercentOfTotal`"
				slot-scope="{ item }"
			>
				{{
					item[period] && item[period][`${stat}PercentOfTotal`]
						? item[period][`${stat}PercentOfTotal`].toFixed(1)
						: 0
				}}%
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}TransactionHighest`"
				slot-scope="{ item }"
			>
				{{ item[period] && formatNumber(item[period][`${stat}TransactionHighest`]) }}
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}TransactionLowest`"
				slot-scope="{ item }"
			>
				{{ item[period] && formatNumber(item[period][`${stat}TransactionLowest`]) }}
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}TransactionCount`"
				slot-scope="{ item }"
			>
				{{ item[period] && item[period][`${stat}TransactionCount`] }}
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}TransactionAverage`"
				slot-scope="{ item }"
			>
				{{ item[period] && formatNumber(item[period][`${stat}TransactionAverage`]) }}
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}TransactionMedian`"
				slot-scope="{ item }"
			>
				{{ item[period] && formatNumber(item[period][`${stat}TransactionMedian`]) }}
			</template>
			<template
				v-for="period in [monthAndYear, year]"
				:slot="`item.${period}.${stat}TransactionLastDate`"
				slot-scope="{ item }"
			>
				{{ item[period] && formatDate(item[period][`${stat}TransactionLastDate`], "DD-MMM-YY") }}
			</template>
			<template v-if="total" slot="body.append">
				<td v-for="field in fields" :key="field" style="text-align: right; padding-right: 15px">
					<span v-if="field === 'monthly'">{{ formatNumber(total[monthAndYear][stat]) }}</span>
					<span v-else-if="field === 'yearly'">{{ formatNumber(total[year]?.[stat]) }}</span>
					<span v-else-if="['monthlyPredicted', 'yearlyPredicted'].includes(field)">
						{{ formatNumber(total[field === "monthlyPredicted" ? monthAndYear : year]?.predicted) }}
					</span>
					<span v-else-if="['monthlyBillingPaid', 'yearlyBillingPaid'].includes(field)">
						{{ formatNumber(total[field === "monthlyBillingPaid" ? monthAndYear : year]?.billingPaid) }}
					</span>
					<span v-else-if="['monthlyGoal', 'yearlyGoal'].includes(field)">
						{{ formatNumber(total[field === "monthlyGoal" ? monthAndYear : year]?.[`${stat}Goal`]) }}
					</span>
					<span v-else-if="['monthlyGoalPercent', 'yearlyGoalPercent'].includes(field)">
						<span v-if="total[field === 'monthlyGoalPercent' ? monthAndYear : year][`${stat}GoalPercent`]">
							{{
								formatNumber(total[field === "monthlyGoalPercent" ? monthAndYear : year][`${stat}GoalPercent`])
							}}%
							<v-progress-linear
								rounded
								:value="total[field === 'monthlyGoalPercent' ? monthAndYear : year][`${stat}GoalPercent`]"
							/>
						</span>
					</span>
					<span v-else-if="['monthlyComparedToGoal', 'yearlyComparedToGoal'].includes(field)">
						<PercentageChip
							:stats="total[field === 'monthlyComparedToGoal' ? monthAndYear : year]"
							:stat="`${stat}ComparedToGoal`"
							:type="type"
							:absoluteFirst="true"
							:onClick="e => changeStatsType(e, true)"
						/>
					</span>
					<span v-else-if="['monthlyComparedToGoalPercent', 'yearlyComparedToGoalPercent'].includes(field)">
						<PercentageChip
							:stats="total[field === 'monthlyComparedToGoalPercent' ? monthAndYear : year]"
							:stat="`${stat}ComparedToGoal`"
							:type="type"
							:onClick="e => changeStatsType(e, true)"
						/>
					</span>
					<span v-else-if="['monthlyExpected', 'yearlyExpected'].includes(field)">
						{{ formatNumber(total[field === "monthlyExpected" ? monthAndYear : year]?.[`${stat}Expected`]) }}
					</span>
					<span v-else-if="['monthlyComparedToExpected', 'yearlyComparedToExpected'].includes(field)">
						<PercentageChip
							:stats="total[field === 'monthlyComparedToExpected' ? monthAndYear : year]"
							:stat="`${stat}ComparedToExpected`"
							:type="type"
							:absoluteFirst="true"
							:onClick="e => changeStatsType(e, true)"
						/>
					</span>
					<span
						v-else-if="['monthlyComparedToExpectedPercent', 'yearlyComparedToExpectedPercent'].includes(field)"
					>
						<PercentageChip
							:stats="total[field === 'monthlyComparedToExpectedPercent' ? monthAndYear : year]"
							:stat="`${stat}ComparedToExpected`"
							:type="type"
							:onClick="e => changeStatsType(e, true)"
						/>
					</span>
					<span v-else-if="field === 'yearlyGrowth'">
						<PercentageChip
							:stats="total[monthAndYear]"
							:stat="`${stat}YearlyGrowth`"
							:type="type"
							:onClick="changeStatsType"
						/>
					</span>
					<span v-else-if="['monthlyPeriodicGrowth', 'yearlyPeriodicGrowth'].includes(field)">
						<PercentageChip
							:stats="total[field === 'monthlyPeriodicGrowth' ? monthAndYear : year]"
							:stat="`${stat}PeriodicGrowth`"
							:type="type"
							:onClick="changeStatsType"
						/>
					</span>
					<span v-else-if="['monthlyComparedToMedian', 'yearlyComparedToMedian'].includes(field)">
						{{
							formatNumber(
								total[field === "monthlyComparedToMedian" ? monthAndYear : year][`${stat}ComparedToMedianLast`],
							)
						}}
					</span>
					<span v-else-if="['monthlyComparedToAverage', 'yearlyComparedToAverage'].includes(field)">
						{{
							formatNumber(
								total[field === "monthlyComparedToAverage" ? monthAndYear : year][`${stat}ComparedToAverageLast`],
							)
						}}
					</span>
					<span v-else-if="['monthlyCount', 'yearlyCount'].includes(field)">
						{{ total[field === "monthlyCount" ? monthAndYear : year][`${stat}TransactionCount`] }}
					</span>
					<span v-else-if="['monthlyAverage', 'yearlyAverage'].includes(field)">
						{{
							formatNumber(total[field === "monthlyAverage" ? monthAndYear : year][`${stat}TransactionAverage`])
						}}
					</span>
					<span v-else-if="['monthlyMedian', 'yearlyMedian'].includes(field)">
						{{ formatNumber(total[field === "monthlyMedian" ? monthAndYear : year][`${stat}TransactionMedian`]) }}
					</span>
					<span v-else>{{ formatNumber(total[field]) }}</span>
				</td>
			</template>
		</v-data-table>
		<div v-else style="display: flex; flex-wrap: wrap; gap: 10px; justify-content: center">
			<v-card
				v-for="(office, index) in total ? officeStats.concat([total]) : officeStats"
				:key="office._id"
				align="left"
				:style="`background-color: ${$vuetify.theme.dark ? '#333' : '#f2f2f2'}; padding: 10px; min-width: 300px`"
			>
				<v-badge inline dot :color="colors[index]" style="float: left; top: 10px; padding-right: 5px" />
				<h2>{{ office.name ? office.name : "Grupo" }}</h2>
				<v-divider style="margin: 5px 0px" />
				<div v-for="(field, index) in fields" :key="index">
					<v-divider v-if="field === 'divider'" style="margin: 5px 0px" />
					<h3 v-if="field === 'monthlyLabel'" @click="() => changeSort(monthAndYear)" style="cursor: pointer">
						{{ formatDate(today, "MMMM YYYY") }}
					</h3>
					<div v-if="field === 'monthly'">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									{{
										formatNumber(
											office[monthAndYear] && office[monthAndYear].billing ? office[monthAndYear].billing : 0,
											true,
											false,
											2,
											true,
										)
									}}
								</span>
							</template>
							<span>Faturação</span>
						</v-tooltip>
						<span style="display: flex; gap: 5px; float: right">
							<PercentageChip
								:stats="office[monthAndYear]"
								stat="billingYearlyGrowth"
								tooltip="Comparado ao mesmo mês do ano anterior"
								:type="type"
								:chip="true"
								:onClick="changeStatsType"
							/>
							<PercentageChip
								:stats="office[monthAndYear]"
								stat="billingPeriodicGrowth"
								tooltip="Comparado ao mês anterior"
								:type="type"
								:chip="true"
								:onClick="changeStatsType"
							/>
						</span>
					</div>
					<div v-if="field === 'monthlyBillingPaid'">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on" style="color: lightgreen">
									{{
										formatNumber(
											office[monthAndYear] && office[monthAndYear].billingPaid
												? office[monthAndYear].billingPaid
												: 0,
											true,
											false,
											2,
											true,
										)
									}}
								</span>
							</template>
							<span>Faturação Paga</span>
						</v-tooltip>
					</div>
					<div v-if="field === 'monthlyPredicted'" style="margin-top: 5px">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on" style="color: DarkGray; font-size: 0.8em">
									{{
										formatNumber(
											office[monthAndYear] && office[monthAndYear].predicted ? office[monthAndYear].predicted : 0,
											true,
											false,
											2,
											true,
										)
									}}
								</span>
							</template>
							<span>Previsto</span>
						</v-tooltip>
						<span style="display: flex; gap: 5px; float: right">
							<PercentageChip
								:stats="office[monthAndYear]"
								stat="billingYearlyGrowthPrediction"
								tooltip="Comparado ao mesmo mês do ano anterior"
								:type="type"
								:chip="true"
								:onClick="changeStatsType"
							/>
							<PercentageChip
								:stats="office[monthAndYear]"
								stat="billingPeriodicGrowthPrediction"
								tooltip="Comparado ao mês anterior"
								:type="type"
								:chip="true"
								:onClick="changeStatsType"
							/>
						</span>
					</div>
					<div v-if="field === 'monthlyGoal'">
						<div
							v-if="office[monthAndYear] && office[monthAndYear].billingGoalPercent"
							style="margin: 10px 0px 20px 0px"
						>
							<v-progress-linear rounded :value="office[monthAndYear].billingGoalPercent" />
							<div style="float: right; font-size: 0.8em; cursor: pointer" @click="changeStatsType">
								<span
									:style="
										type === 1
											? `color: #${office[monthAndYear].billingComparedToExpected >= 0 ? '4caf50' : 'ff5252'}`
											: ''
									"
								>
									{{ formatNumber(office[monthAndYear][translateGoalStatsType("billing")], type) }}
									{{ type ? "" : "%" }}
								</span>
								<span v-if="type === 1">
									<v-icon v-if="office[monthAndYear].billingComparedToExpected > 0" small color="success">
										mdi-arrow-up
									</v-icon>
									<v-icon v-else-if="office[monthAndYear].billingComparedToExpected === 0" small color="success">
										mdi-check
									</v-icon>
									<v-icon v-else-if="office[monthAndYear].billingComparedToExpected < 0" small color="error">
										mdi-arrow-down
									</v-icon>
								</span>
							</div>
						</div>
						<v-divider v-else style="margin: 12px 0px" />
					</div>
					<div v-if="field === 'monthlyReservations'" style="margin-top: 5px">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									{{
										formatNumber(
											office[monthAndYear] && office[monthAndYear].reservations
												? office[monthAndYear].reservations
												: 0,
											true,
											false,
											2,
											true,
										)
									}}
								</span>
							</template>
							<span>Pipeline</span>
						</v-tooltip>
						<span style="display: flex; gap: 5px; float: right">
							<PercentageChip
								:stats="office[monthAndYear]"
								stat="reservationsYearlyGrowth"
								tooltip="Comparado ao mesmo mês do ano anterior"
								:type="type"
								:chip="true"
								:onClick="changeStatsType"
							/>
							<PercentageChip
								:stats="office[monthAndYear]"
								stat="reservationsPeriodicGrowth"
								tooltip="Comparado ao mês anterior"
								:type="type"
								:chip="true"
								:onClick="changeStatsType"
							/>
						</span>
					</div>
					<div v-if="field === 'monthlyFindings'" style="margin-top: 5px">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									{{ office[monthAndYear] && office[monthAndYear].findings ? office[monthAndYear].findings : 0 }}
								</span>
							</template>
							<span>Angariações</span>
						</v-tooltip>
						<span style="display: flex; gap: 5px; float: right">
							<PercentageChip
								:stats="office[monthAndYear]"
								stat="findingsYearlyGrowth"
								tooltip="Comparado ao mesmo mês do ano anterior"
								:type="type"
								:chip="true"
								:onClick="changeStatsType"
							/>
							<PercentageChip
								:stats="office[monthAndYear]"
								stat="findingsPeriodicGrowth"
								tooltip="Comparado ao mês anterior"
								:type="type"
								:chip="true"
								:onClick="changeStatsType"
							/>
						</span>
					</div>
					<h3 v-if="field === 'yearlyLabel'" @click="() => changeSort(year)" style="cursor: pointer">
						{{ year }}
					</h3>
					<div v-if="field === 'yearly'">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									{{ formatNumber(office[year] ? office[year].billing : 0, true, false, 2, true) }}
								</span>
							</template>
							<span>Faturação</span>
						</v-tooltip>
						<span style="float: right">
							<PercentageChip
								:stats="office[year]"
								stat="billingPeriodicGrowth"
								tooltip="Comparado ao ano anterior"
								:type="type"
								:chip="true"
								:onClick="changeStatsType"
							/>
						</span>
					</div>
					<div v-if="field === 'yearlyBillingPaid'">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on" style="color: lightgreen">
									{{
										formatNumber(
											office[year] && office[year].billingPaid ? office[year].billingPaid : 0,
											true,
											false,
											2,
											true,
										)
									}}
								</span>
							</template>
							<span>Faturação Paga</span>
						</v-tooltip>
					</div>
					<div v-if="field === 'yearlyPredicted'" style="margin-top: 5px">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on" style="color: DarkGray; font-size: 0.8em">
									{{
										formatNumber(
											office[year] && office[year].predicted ? office[year].predicted : 0,
											true,
											false,
											2,
											true,
										)
									}}
								</span>
							</template>
							<span>Previsto</span>
						</v-tooltip>
						<span style="float: right">
							<PercentageChip
								:stats="office[year]"
								stat="billingPeriodicGrowthPrediction"
								tooltip="Comparado ao ano anterior"
								:type="type"
								:chip="true"
								:onClick="changeStatsType"
							/>
						</span>
					</div>
					<div v-if="field === 'yearlyGoal'">
						<div v-if="office[year] && office[year].billingGoalPercent" style="margin: 10px 0px 20px 0px">
							<v-progress-linear rounded :value="office[year].billingGoalPercent" />
							<div style="float: right; font-size: 0.8em; cursor: pointer" @click="changeStatsType">
								<span
									:style="
										type === 1
											? `color: #${office[year].billingComparedToExpected >= 0 ? '4caf50' : 'ff5252'}`
											: ''
									"
								>
									{{ formatNumber(office[year][translateGoalStatsType("billing")], type) }}
									{{ type ? "" : "%" }}
								</span>
								<span v-if="type === 1">
									<v-icon v-if="office[year].billingComparedToExpected > 0" small color="success">
										mdi-arrow-up
									</v-icon>
									<v-icon v-else-if="office[year].billingComparedToExpected === 0" small color="success">
										mdi-check
									</v-icon>
									<v-icon v-else-if="office[year].billingComparedToExpected < 0" small color="error">
										mdi-arrow-down
									</v-icon>
								</span>
							</div>
						</div>
						<v-divider v-else style="margin: 11px 0px" />
					</div>
					<div v-if="field === 'yearlyReservations'" style="margin-top: 5px">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									{{
										formatNumber(
											office[year] && office[year].reservations ? office[year].reservations : 0,
											true,
											false,
											2,
											true,
										)
									}}
								</span>
							</template>
							<span>Pipeline</span>
						</v-tooltip>
						<span style="float: right">
							<PercentageChip
								:stats="office[year]"
								stat="reservationsPeriodicGrowth"
								tooltip="Comparado ao ano anterior"
								:type="type"
								:chip="true"
								:onClick="changeStatsType"
							/>
						</span>
					</div>
					<div v-if="field === 'yearlyFindings'" style="margin-top: 5px">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									{{ office[year] && office[year].findings ? office[year].findings : 0 }}
								</span>
							</template>
							<span>Angariações</span>
						</v-tooltip>
						<span style="float: right">
							<PercentageChip
								:stats="office[year]"
								stat="findingsPeriodicGrowth"
								tooltip="Comparado ao ano anterior"
								:type="type"
								:chip="true"
								:onClick="changeStatsType"
							/>
						</span>
					</div>
					<h3 v-if="field === 'pipelineLabel'">Em Pipeline</h3>
					<div v-if="field === 'pipeline'">
						{{ formatNumber(office.pipeline, true) }}
					</div>
					<div v-if="field === 'pipelineGoal'">
						<div v-if="office[year] && office[year].pipelineGoalPercent" style="margin: 10px 0px 20px 0px">
							<v-progress-linear rounded :value="office[year].pipelineGoalPercent" />
							<div style="float: right; font-size: 0.8em; cursor: pointer" @click="changeStatsType">
								<span
									:style="
										type === 1
											? `color: #${office[year].pipelineComparedToExpected >= 0 ? '4caf50' : 'ff5252'}`
											: ''
									"
								>
									{{ formatNumber(office[year][translateGoalStatsType("pipeline")], type) }}
									{{ type ? "" : "%" }}
								</span>
								<span v-if="type === 1">
									<v-icon v-if="office[year].pipelineComparedToExpected > 0" small color="success">
										mdi-arrow-up
									</v-icon>
									<v-icon v-else-if="office[year].pipelineComparedToExpected === 0" small color="success">
										mdi-check
									</v-icon>
									<v-icon v-else-if="office[year].pipelineComparedToExpected < 0" small color="error">
										mdi-arrow-down
									</v-icon>
								</span>
							</div>
						</div>
						<v-divider v-else style="margin: 11px 0px" />
					</div>
					<div v-if="field === 'monthlyMargin'" style="display: flex; justify-content: space-between">
						<v-chip small style="border-radius: 3px; margin: 3px 10px 3px 0px">Margem</v-chip>
						{{
							formatNumber(office[monthAndYear]?.margin ? office[monthAndYear].margin : 0, true, false, 2, true)
						}}
						({{
							formatNumber(
								office[monthAndYear]?.margin ? office[monthAndYear].marginPercent : 0,
								false,
								false,
								0,
								true,
							)
						}}%)
					</div>
					<div v-if="field === 'monthlyIncome'">
						<div style="display: flex; justify-content: space-between">
							<v-chip small style="border-radius: 3px; margin: 3px 10px 3px 0px">Receita</v-chip>
							{{ formatNumber(office[monthAndYear]?.billing, true, false, 2, true) }}
						</div>
						<div style="display: flex; justify-content: space-between">
							<v-chip
								small
								style="border-radius: 3px; margin: 3px 10px 3px 10px"
								@click="includeBank = !includeBank"
							>
								Imobiliário
							</v-chip>
							{{
								formatNumber(
									office[monthAndYear]?.billing - office[monthAndYear]?.billingBanks,
									true,
									false,
									2,
									true,
								)
							}}
						</div>
						<div style="display: flex; justify-content: space-between">
							<v-chip small style="border-radius: 3px; margin: 3px 10px 3px 10px">Diversos</v-chip>
							{{ formatNumber(office[monthAndYear]?.billingBanks, true, false, 2, true) }}
						</div>
					</div>
					<div v-if="field === 'monthlyExpenses'" style="display: flex; justify-content: space-between">
						<v-chip small style="border-radius: 3px; margin: 3px 10px 3px 0px">Despesas</v-chip>
						{{
							formatNumber(office[monthAndYear]?.salesCost + office[monthAndYear]?.fixedCost, true, false, 2, true)
						}}
					</div>
					<div v-if="field === 'monthlyExpensesCategories'">
						<div style="display: flex; justify-content: space-between">
							<v-chip small style="border-radius: 3px; margin: 3px 10px 3px 10px">Custo de Venda</v-chip>
							{{
								formatNumber(
									office[monthAndYear]?.salesCost ? office[monthAndYear].salesCost : 0,
									true,
									false,
									2,
									true,
								)
							}}
							({{
								formatNumber(
									office[monthAndYear]?.salesCost ? office[monthAndYear].salesCostPercent : 0,
									false,
									false,
									0,
									true,
								)
							}}%)
						</div>
						<div style="display: flex; justify-content: space-between">
							<v-chip small style="border-radius: 3px; margin: 3px 10px 3px 10px">Custo Fixo</v-chip>
							{{
								formatNumber(
									office[monthAndYear]?.fixedCost ? office[monthAndYear].fixedCost : 0,
									true,
									false,
									2,
									true,
								)
							}}
							({{
								formatNumber(
									office[monthAndYear]?.fixedCost ? office[monthAndYear].fixedCostPercent : 0,
									false,
									false,
									0,
									true,
								)
							}}%)
						</div>
					</div>
					<div v-if="field === 'yearlyMargin'" style="display: flex; justify-content: space-between">
						<v-chip small style="border-radius: 3px; margin: 3px 10px 3px 0px">Margem</v-chip>
						{{ formatNumber(office[year]?.margin ? office[year].margin : 0, true, false, 2, true) }}
						({{ formatNumber(office[year]?.margin ? office[year].marginPercent : 0, false, false, 0, true) }}%)
					</div>
					<div v-if="field === 'yearlyIncome'">
						<div style="display: flex; justify-content: space-between">
							<v-chip small style="border-radius: 3px; margin: 3px 10px 3px 0px">Receita</v-chip>
							{{ formatNumber(office[year]?.billing, true, false, 2, true) }}
						</div>
						<div style="display: flex; justify-content: space-between">
							<v-chip
								small
								style="border-radius: 3px; margin: 3px 10px 3px 10px"
								@click="includeBank = !includeBank"
							>
								Imobiliário
							</v-chip>
							{{ formatNumber(office[year]?.billing - office[year]?.billingBanks, true, false, 2, true) }}
						</div>
						<div style="display: flex; justify-content: space-between">
							<v-chip small style="border-radius: 3px; margin: 3px 10px 3px 10px">Diversos</v-chip>
							{{ formatNumber(office[year]?.billingBanks, true, false, 2, true) }}
						</div>
					</div>
					<div v-if="field === 'yearlyExpenses'" style="display: flex; justify-content: space-between">
						<v-chip small style="border-radius: 3px; margin: 3px 10px 3px 0px">Despesas</v-chip>
						{{ formatNumber(office[year]?.salesCost + office[year]?.fixedCost, true, false, 2, true) }}
					</div>
					<div v-if="field === 'yearlyExpensesCategories'">
						<div style="display: flex; justify-content: space-between">
							<v-chip small style="border-radius: 3px; margin: 3px 10px 3px 10px">Custo de Venda</v-chip>
							{{ formatNumber(office[year]?.salesCost ? office[year].salesCost : 0, true, false, 2, true) }}
							({{
								formatNumber(office[year]?.salesCost ? office[year].salesCostPercent : 0, false, false, 0, true)
							}}%)
						</div>
						<div style="display: flex; justify-content: space-between">
							<v-chip small style="border-radius: 3px; margin: 3px 10px 3px 10px">Custo Fixo</v-chip>
							{{ formatNumber(office[year]?.fixedCost ? office[year].fixedCost : 0, true, false, 2, true) }}
							({{
								formatNumber(office[year]?.fixedCost ? office[year].fixedCostPercent : 0, false, false, 0, true)
							}}%)
						</div>
					</div>
				</div>
			</v-card>
		</div>
		<RadarChart
			v-if="includeChart && selectedOffices.length === 1"
			width="400px"
			height="400px"
			title="Distribuição por mês"
			:stats="officeStats"
			:stat="stat"
			:year="formatDate(dateRange[1], 'YYYY')"
			:yearLimit="1"
			:yFormatter="stat === 'findings' ? null : formatNumber"
			style="margin: 0px 50px"
		/>
		<BarChart
			v-else-if="includeChart"
			width="100%"
			height="125px"
			:seriesOverwrite="series"
			:showLegend="true"
			:dataLabelFormatter="(value, dataPoint) => this.officeStats[dataPoint.seriesIndex].name"
			:legendFormatter="
				office =>
					`${office} - ${
						this.officeStats.find(o => o.name === office)?.[this.chartSort]?.[`${this.stat}PercentOfTotal`]
							? formatNumber(
									this.officeStats.find(o => o.name === office)[this.chartSort][`${this.stat}PercentOfTotal`],
									false,
									false,
									0,
									true,
							  )
							: 0
					}%`
			"
			:xFormatter="
				(value, dataPoint) => {
					const office = this.officeStats[dataPoint.seriesIndex];

					return `${office.name} - ${
						this.officeStats.find(o => o._id === office._id)?.[this.chartSort]
							? formatNumber(
									this.officeStats.find(o => o._id === office._id)[this.chartSort][`${this.stat}PercentOfTotal`],
									false,
									false,
									0,
									true,
							  )
							: 0
					}%`;
				}
			"
			:yFormatter="formatNumber"
			:centerLabels="true"
			:hideGrid="true"
			:stacked="true"
			:horizontal="true"
			style="width: 100%"
		/>
	</v-card>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import dayjs from "dayjs";

import PercentageChip from "./PercentageChip";
import RadarChart from "./RadarChart";
import BarChart from "./BarChart";

import { getGroupedStats } from "../../api/stats";

import { formatDate, formatNumber } from "../../utils/utils";

export default Vue.extend({
	name: "OfficeStats",
	props: ["view", "stat", "header", "fields", "includeChart"],
	components: { PercentageChip, RadarChart, BarChart },
	created() {
		this.setupFields(this.dateRange);

		this.handleGetOfficeStats();
	},
	watch: {
		selectedOffices: function () {
			this.handleGetOfficeStats();
		},
		dateRange: function (dateRange) {
			this.setupFields(dateRange);

			this.handleGetOfficeStats();
		},
		fields: function () {
			this.setupFields(this.dateRange);
		},
		type() {
			this.setupFields(this.dateRange);
		},
		includeBank: function () {
			this.handleGetOfficeStats();
		},
	},
	computed: mapState(["dateRange", "selectedOffices"]),
	data() {
		const today = dayjs();
		const year = today.get("year");
		const monthAndYear = `${today.get("month") + 1}-${year}`;
		const sortBy = year.toString();

		return {
			loading: true,

			headers: [],
			sortBy,
			sortDesc: true,

			today,
			year,
			monthAndYear,
			type: 0,
			includeBank: true,

			officeStats: [],
			total: null,

			series: [],
			chartSort: sortBy,

			colors: ["#2E93fA", "#FF9800", "#E91E63", "#00BCD4", "#9C27B0", "#3F51B5", "#546E7A", "#FFC107"],
		};
	},
	methods: {
		formatDate,
		formatNumber,
		changeStatsType(e, skipLast) {
			this.type = this.type === 2 || (this.type === 1 && skipLast) ? 0 : this.type + 1;
		},
		changeSort(chartSort) {
			this.chartSort = chartSort;

			if (this.stat === "pipeline") {
				this.officeStats.sort((a, b) => (b[this.stat] || 0) - (a[this.stat] || 0));
			} else {
				this.officeStats.sort(
					(a, b) => (b[chartSort] ? b[chartSort][this.stat] : 0) - (a[chartSort] ? a[chartSort][this.stat] : 0),
				);
			}

			this.formatStats();
		},
		translateGoalStatsType(prefix) {
			return this.type === 2
				? `${prefix}Goal`
				: this.type === 1
				? `${prefix}ComparedToExpected`
				: `${prefix}GoalPercent`;
		},
		translateStatsType() {
			return this.type === 2 ? "Last" : this.type === 1 ? "" : "Percent";
		},
		setupFields(dateRange) {
			this.today = dayjs(dateRange[1]);
			this.year = this.today.get("year");
			this.month = this.today.get("month") + 1;
			this.monthAndYear = `${this.month}-${this.year}`;
			this.sortBy = this.stat === "pipeline" ? this.stat : `${this.year.toString()}.${this.stat}`;

			const monthAndYearText = formatDate(this.today.set("month", this.month - 1), "MMMM YYYY");

			const headers = [
				{ text: "#", value: "rank", field: "rank", width: 60 },
				{ text: "Nome", value: "name", field: "name", width: 100 },
				{
					text: monthAndYearText,
					value: `${this.monthAndYear}.${this.stat}`,
					field: "monthly",
					align: "right",
					width: 130,
				},
				{ text: this.year, value: `${this.year}.${this.stat}`, field: "yearly", align: "right", width: 130 },
				{ text: "Em Pipeline", value: "pipeline", field: "pipeline", width: 130 },
				{
					text: `Previsto (${monthAndYearText})`,
					value: `${this.monthAndYear}.predicted`,
					field: "monthlyPredicted",
					align: "right",
					width: 130,
				},
				{
					text: `Pago (${monthAndYearText})`,
					value: `${this.monthAndYear}.billingPaid`,
					field: "monthlyBillingPaid",
					align: "right",
					width: 130,
				},
				{
					text: `Previsto (${this.year})`,
					value: `${this.year}.predicted`,
					field: "yearlyPredicted",
					align: "right",
					width: 130,
				},
				{
					text: `Pago (${this.year})`,
					value: `${this.year}.billingPaid`,
					field: "yearlyBillingPaid",
					align: "right",
					width: 130,
				},
				{
					text: `Objetivo (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}Goal`,
					field: "monthlyGoal",
					align: "right",
					width: 130,
				},
				{
					text: `Esperado (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}Expected`,
					field: "monthlyExpected",
					align: "right",
					width: 130,
				},
				{
					text: `Diferença do Esperado (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}ComparedToExpected`,
					field: "monthlyComparedToExpected",
					align: "right",
					width: 130,
				},
				{
					text: `Diferença do Esperado % (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}ComparedToExpectedPercent`,
					field: "monthlyComparedToExpectedPercent",
					align: "right",
					width: 130,
				},
				{
					text: `% do Objetivo (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}GoalPercent`,
					field: "monthlyGoalPercent",
					align: "right",
					width: 130,
				},
				{
					text: `Diferença do Objetivo (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}ComparedToGoal`,
					field: "monthlyComparedToGoal",
					align: "right",
					width: 130,
				},
				{
					text: `Diferença do Objetivo % (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}ComparedToGoalPercent`,
					field: "monthlyComparedToGoalPercent",
					align: "right",
					width: 130,
				},
				{
					text: `Objetivo (${this.year})`,
					value: `${this.year}.${this.stat}Goal`,
					field: "yearlyGoal",
					align: "right",
					width: 130,
				},
				{
					text: `Esperado (${this.year})`,
					value: `${this.year}.${this.stat}Expected`,
					field: "yearlyExpected",
					align: "right",
					width: 130,
				},
				{
					text: `Diferença do Esperado (${this.year})`,
					value: `${this.year}.${this.stat}ComparedToExpected`,
					field: "yearlyComparedToExpected",
					align: "right",
					width: 130,
				},
				{
					text: `Diferença do Esperado % (${this.year})`,
					value: `${this.year}.${this.stat}ComparedToExpectedPercent`,
					field: "yearlyComparedToExpectedPercent",
					align: "right",
					width: 130,
				},
				{
					text: `% do Objetivo (${this.year})`,
					value: `${this.year}.${this.stat}GoalPercent`,
					field: "yearlyGoalPercent",
					align: "right",
					width: 130,
				},
				{
					text: `Diferença do Objetivo (${this.year})`,
					value: `${this.year}.${this.stat}ComparedToGoal`,
					field: "yearlyComparedToGoal",
					align: "right",
					width: 130,
				},
				{
					text: `Diferença do Objetivo % (${this.year})`,
					value: `${this.year}.${this.stat}ComparedToGoalPercent`,
					field: "yearlyComparedToGoalPercent",
					align: "right",
					width: 130,
				},
				{
					text: "Comparado ao mesmo mês do ano anterior",
					value: `${this.monthAndYear}.${this.stat}YearlyGrowth${this.translateStatsType()}`,
					field: "yearlyGrowth",
					align: "right",
					width: 130,
				},
				{
					text: "Comparado ao mês anterior",
					value: `${this.monthAndYear}.${this.stat}PeriodicGrowth${this.translateStatsType()}`,
					field: "monthlyPeriodicGrowth",
					align: "right",
					width: 130,
				},
				{
					text: `Comparado à mediana (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}ComparedToMedian${this.translateStatsType()}`,
					field: "monthlyComparedToMedian",
					align: "right",
					width: 130,
				},
				{
					text: `Comparado à média (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}ComparedToAverage${this.translateStatsType()}`,
					field: "monthlyComparedToAverage",
					align: "right",
					width: 130,
				},
				{
					text: `Percentagem do total (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}PercentOfTotal`,
					field: "monthlyPercentOfTotal",
					align: "right",
					width: 130,
				},
				{
					text: "Comparado ao ano anterior",
					value: `${this.year}.${this.stat}PeriodicGrowth${this.translateStatsType()}`,
					field: "yearlyPeriodicGrowth",
					align: "right",
					width: 130,
				},
				{
					text: `Comparado à mediana (${this.year})`,
					value: `${this.year}.${this.stat}ComparedToMedian${this.translateStatsType()}`,
					field: "yearlyComparedToMedian",
					align: "right",
					width: 130,
				},
				{
					text: `Comparado à média (${this.year})`,
					value: `${this.year}.${this.stat}ComparedToAverage${this.translateStatsType()}`,
					field: "yearlyComparedToAverage",
					align: "right",
					width: 130,
				},
				{
					text: `Percentagem do total (${this.year})`,
					value: `${this.year}.${this.stat}PercentOfTotal`,
					field: "yearlyPercentOfTotal",
					align: "right",
					width: 130,
				},
				{
					text: `Fatura mais alta (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}TransactionHighest`,
					field: "monthlyHighest",
					align: "right",
					width: 130,
				},
				{
					text: `Fatura mais baixa (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}TransactionLowest`,
					field: "monthlyLowest",
					align: "right",
					width: 130,
				},
				{
					text: `Nº de Faturas (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}TransactionCount`,
					field: "monthlyCount",
					align: "right",
					width: 80,
				},
				{
					text: `Fatura média (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}TransactionAverage`,
					field: "monthlyAverage",
					align: "right",
					width: 130,
				},
				{
					text: `Fatura mediana (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}TransactionMedian`,
					field: "monthlyMedian",
					align: "right",
					width: 130,
				},
				{
					text: `Última Fatura (${monthAndYearText})`,
					value: `${this.monthAndYear}.${this.stat}TransactionLastDate`,
					field: "monthlyLastDate",
					align: "center",
					width: 100,
				},
				{
					text: `Fatura mais alta (${this.year})`,
					value: `${this.year}.${this.stat}TransactionHighest`,
					field: "yearlyHighest",
					align: "right",
					width: 130,
				},
				{
					text: `Fatura mais baixa (${this.year})`,
					value: `${this.year}.${this.stat}TransactionLowest`,
					field: "yearlyLowest",
					align: "right",
					width: 130,
				},
				{
					text: `Nº de Faturas (${this.year})`,
					value: `${this.year}.${this.stat}TransactionCount`,
					field: "yearlyCount",
					align: "right",
					width: 80,
				},
				{
					text: `Fatura média (${this.year})`,
					value: `${this.year}.${this.stat}TransactionAverage`,
					field: "yearlyAverage",
					align: "right",
					width: 130,
				},
				{
					text: `Fatura mediana (${this.year})`,
					value: `${this.year}.${this.stat}TransactionMedian`,
					field: "yearlyMedian",
					align: "right",
					width: 130,
				},
				{
					text: `Última Fatura (${this.year})`,
					value: `${this.year}.${this.stat}TransactionLastDate`,
					field: "yearlyLastDate",
					align: "center",
					width: 100,
				},
			];

			this.headers = [];
			if (this.fields.length) {
				for (const field of this.fields) {
					const headerFound = headers.find(h => h.field === field);

					if (headerFound) this.headers.push(headerFound);
				}
			} else {
				this.headers = headers;
			}

			this.formatStats();
		},
		async handleGetOfficeStats() {
			this.loading = true;

			const response = await getGroupedStats("office", this.stat, null, !this.includeBank && "!Bank");

			if (response.status === 200) {
				this.officeStats = [];
				this.total = null;

				const showTotal = Object.keys(response.data).length > 2;
				for (const id in response.data) {
					if (id === "total") {
						response.data[id]._id = id;

						if (showTotal) this.total = response.data[id];
					} else {
						this.officeStats.push(response.data[id]);
					}
				}

				const stat = this.stat ? this.stat : "billing";

				if (this.stat === "pipeline") {
					this.officeStats.sort((a, b) => (b[stat] || 0) - (a[stat] || 0));
				} else {
					this.officeStats.sort(
						(a, b) => (b[this.year] ? b[this.year][stat] : 0) - (a[this.year] ? a[this.year][stat] : 0),
					);
				}

				this.formatStats();
			}

			this.loading = false;
		},
		formatStats() {
			this.series = [];

			for (const office of this.officeStats) {
				this.series.push({
					name: office.name,
					data: [
						this.stat === "pipeline"
							? office[this.stat]
							: office[this.chartSort]
							? office[this.chartSort][this.stat]
							: 0,
					],
				});
			}
		},
	},
});
</script>
