<style scoped>
.chip {
	width: 165px;
	justify-content: center;
	cursor: pointer;
}
</style>

<template>
	<v-container
		fluid
		:style="`display: flex; flex-direction: column; gap: 10px; max-width: ${maxWidth}px${
			profileOverwrite ? '; padding:0px' : ''
		}`"
	>
		<slot
			v-if="featuresMap.listTotals && !profileOverwrite"
			name="totals"
			:monthly="monthly"
			:pipeline="pipeline"
			:totals="totals"
		/>
		<v-row>
			<v-col v-if="showFilters" sm="12" md="4" lg="3" style="margin-right: -10px">
				<v-card width="100%" style="padding: 10px">
					<Profiles
						:type="type"
						:profile="profile"
						:columns="columns"
						:expandedColumns="expandedColumns"
						:filters="filters"
						:headers="headers.filter(h => !h.types || h.types.includes(type))"
						:formatColumns="formatColumns"
						:updateParent="updateParent"
					/>
					<v-divider v-if="quickFilters" />
					<v-list v-if="quickFilters" nav dense style="padding: 20px 0px">
						<v-list-item-group v-model="quickFilter" color="primary" style="text-align: left">
							<v-list-item v-for="filter in quickFilters" :key="filter.value" :value="filter.value">
								<v-list-item-icon>
									<v-icon>{{ filter.icon }}</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{ filter.name }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
					<v-divider />
					<br />
					<div v-for="filter in filtersConfig" :key="filter.value">
						<v-autocomplete
							v-if="
								filter.type === 'autocomplete' &&
								('condition' in filter ? evaluateCondition(filter.condition) : true)
							"
							outlined
							:item-text="filter.itemText || 'name'"
							:item-value="filter.itemValue || '_id'"
							:label="filter.label"
							v-model="filterOptions[filter.value]"
							:items="getAutocompleteItems(filter.items || filter.value)"
							chips
							small-chips
							multiple
							hide-details
							@change="value => handleSetFilters(filter.value, value)"
						/>
						<span
							v-else-if="
								filter.type === 'buttonToggle' &&
								('condition' in filter ? evaluateCondition(filter.condition) : true)
							"
						>
							<p v-if="filter.label" style="font-size: 0.75em">{{ filter.label }}</p>
							<v-btn-toggle
								dense
								mandatory
								v-model="filterOptions[filter.value]"
								@change="value => handleSetFilters(filter.value, value)"
								:style="filter.vertical ? 'flex-direction: column; border: 1px solid grey' : ''"
							>
								<v-btn
									v-for="option in filter.options"
									:key="option.value"
									style="font-size: 0.6em"
									:value="option.value"
								>
									{{ option.label }}
								</v-btn>
							</v-btn-toggle>
						</span>
						<v-menu
							v-else-if="filter.type === 'date'"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									outlined
									:value="formatDateRange(filterOptions[filter.value])"
									:label="filter.label"
									v-bind="attrs"
									v-on="on"
									hide-details
									readonly
									clearable
									@change="() => clearDateRange(filter.value)"
								/>
							</template>
							<v-date-picker
								range
								v-model="filterOptions[filter.value]"
								@change="value => handleDateFilter(filter.value, value)"
								color="primary"
							/>
						</v-menu>
						<v-text-field
							v-else-if="filter.type === 'textField'"
							outlined
							v-model="filterOptions[filter.value]"
							:label="filter.label"
							:type="filter.textType ? filter.textType : 'text'"
							@keyup="e => setSearch(e.target.value, filter.value)"
							hide-details
						/>
						<v-divider v-else-if="filter.type === 'divider'" />
						<span v-if="'condition' in filter ? evaluateCondition(filter.condition) : true">
							<br /><br v-if="filter.type === 'buttonToggle'" />
						</span>
					</div>
				</v-card>
			</v-col>
			<v-col sm="12" :md="showFilters ? 8 : 12" :lg="showFilters ? 9 : 12">
				<v-data-table
					:headers="filteredHeaders"
					:items="items"
					:loading="loading"
					:expanded="expanded"
					:items-per-page="perPage"
					:sort-by="sortBy"
					:sort-desc="sortDesc"
					:server-items-length="total"
					item-key="_id"
					:show-expand="!!expandedColumns.length"
					:show-select="canCopy(true)"
					must-sort
					no-data-text="Não há resultados"
					no-results-text="Não há resultados"
					:footer-props="{
						itemsPerPageOptions: [10, 20, 50],
						itemsPerPageText: 'Linhas',
						showCurrentPage: true,
						showFirstLastPage: true,
					}"
					fixed-header
					:height="maxHeight"
					@update:options="setOptions"
					@item-selected="setSelected"
					@toggle-select-all="setSelected"
				>
					<template v-slot:top>
						<v-toolbar rounded>
							<v-toolbar-title>{{ title }}</v-toolbar-title>
							<v-spacer />
							<v-tooltip v-if="canCopy()" top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" icon @click="copyLine()">
										<v-icon>mdi-content-copy</v-icon>
									</v-btn>
								</template>
								<span>Copiar Seleção</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" icon @click="copyAll">
										<v-icon>mdi-content-duplicate</v-icon>
									</v-btn>
								</template>
								<span>Copiar Tudo</span>
							</v-tooltip>
							<v-tooltip v-if="type === 'users' && featuresMap.edit" top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" icon @click="showTeams = true">
										<v-icon>mdi-account-multiple</v-icon>
									</v-btn>
								</template>
								<span>Equipas</span>
							</v-tooltip>
							<v-tooltip v-if="type === 'users' && features.generateConsultantImages" top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" icon to="/users/image-generator">
										<v-icon>mdi-cards</v-icon>
									</v-btn>
								</template>
								<span>Gerar Imagens dos Consultores</span>
							</v-tooltip>
							<v-tooltip v-if="type === 'users' && user.role === 'sysAdmin'" top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" icon @click="handleExportUsers">
										<v-icon>mdi-file-excel</v-icon>
									</v-btn>
								</template>
								<span>Exportar Utilizadores</span>
							</v-tooltip>
							<v-tooltip v-if="type === 'users' && user.role === 'sysAdmin'" top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" icon @click="chooseFiles">
										<input
											id="fileUpload"
											type="file"
											accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
											hidden
											multiple
											@change="handleImportUsers"
										/>
										<v-icon>mdi-file-import</v-icon>
									</v-btn>
								</template>
								<span>Importar Utilizadores</span>
							</v-tooltip>
							<v-btn
								v-if="type === 'invoices' && view === 'percentages'"
								@click="handleBatchEdit"
								:loading="editLoading"
								color="primary"
								:disabled="!editLength"
								style="margin-right: 5px"
							>
								<v-icon left>mdi-content-save</v-icon>
								Guardar
							</v-btn>
							<div v-else-if="type === 'invoices'">
								<v-tooltip
									v-if="
										(features.generateConsultantDrafts ||
											features.generateCommercialDirectorDrafts ||
											features.generateSalaryReceiptDrafts) &&
										!user.gmailRefreshToken
									"
									top
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-bind="attrs"
											v-on="on"
											icon
											:href="`https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirect}/gmail&prompt=consent&access_type=offline&response_type=code&client_id=557887385987-ls6a5j9ep453c0qi02r8ddiumlhkb4m0.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events`"
										>
											<v-icon>mdi-gmail</v-icon>
										</v-btn>
									</template>
									<span>Ligar com o Gmail</span>
								</v-tooltip>
								<v-tooltip
									v-if="
										selectedOffices.length === 1 && features.generateConsultantDrafts && user.gmailRefreshToken
									"
									top
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn v-bind="attrs" v-on="on" icon @click="handleGenerateGmailDrafts">
											<v-icon>mdi-email</v-icon>
										</v-btn>
									</template>
									<span>Gerar emails dos consultores</span>
								</v-tooltip>
								<v-tooltip v-if="features.generateCommercialDirectorDrafts && user.gmailRefreshToken" top>
									<template v-slot:activator="{ on, attrs }">
										<v-btn v-bind="attrs" v-on="on" icon @click="handleGenerateDCGmailDrafts">
											<v-icon>mdi-account</v-icon>
										</v-btn>
									</template>
									<span>Gerar emails dos DCs</span>
								</v-tooltip>
								<v-tooltip
									v-if="
										selectedOffices.length === 1 && features.generateSalaryReceiptDrafts && user.gmailRefreshToken
									"
									top
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn v-bind="attrs" v-on="on" icon @click="chooseFiles">
											<input id="fileUpload" type="file" hidden multiple @change="handleGenerateSalaryReceipts" />
											<v-icon>mdi-book</v-icon>
										</v-btn>
									</template>
									<span>Gerar emails dos salários</span>
								</v-tooltip>
							</div>
							<v-tooltip v-if="type === 'invoices' && user.role === 'cfo'" top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-bind="attrs"
										v-on="on"
										icon
										@click="() => (view = view === 'percentages' ? 'items' : 'percentages')"
									>
										<v-icon>{{ view === "percentages" ? "mdi-file-document" : "mdi-percent" }}</v-icon>
									</v-btn>
								</template>
								<span>{{ view === "percentages" ? "Voltar às faturas" : "Editar comissões" }}</span>
							</v-tooltip>
							<v-tooltip v-if="!profileOverwrite" top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" icon @click="() => handleSetFilters('active', !filters.active)">
										<v-icon>{{ filters.active ? "mdi-archive" : "mdi-file-document" }}</v-icon>
									</v-btn>
								</template>
								<span>Arquivo</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-badge :value="Object.keys(filters).length > 1" dot overlap>
										<v-btn v-bind="attrs" v-on="on" icon @click="showFilters = !showFilters">
											<v-icon>{{ showFilters ? "mdi-filter-outline" : "mdi-filter" }}</v-icon>
										</v-btn>
									</v-badge>
								</template>
								<span>Filtros</span>
							</v-tooltip>
							<v-text-field
								outlined
								dense
								v-model="search"
								append-icon="mdi-magnify"
								label="Procurar"
								style="margin: 0px 10px; max-width: 300px"
								hide-details
								@keyup="e => setSearch(e.target.value, 'search')"
							/>
							<v-btn
								v-if="featuresMap.add"
								fab
								small
								color="primary"
								style="margin-left: 10px"
								@click="openDetail(null)"
							>
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</v-toolbar>
					</template>
					<template v-if="view === 'percentages'" slot="body.prepend">
						<td :colspan="getColspan()" />
						<td>
							<v-combobox
								outlined
								dense
								:value="batchFinderPercentage"
								@change="value => changeAllCommissions('finderPercentage', value)"
								label="Comissão do Angariador"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
						<td />
						<td>
							<v-combobox
								outlined
								dense
								:value="batchFinderCommercialDirectorCommission"
								@change="value => changeAllCommissions('finderCommercialDirectorCommission', value)"
								label="Comissão do DC do Angariador"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
						<td />
						<td>
							<v-text-field
								outlined
								dense
								:value="batchFinderBrokerCommission"
								@change="value => changeAllCommissions('finderBrokerCommission', value)"
								label="Comissão do Broker do Angariador"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
						<td>
							<v-text-field
								outlined
								dense
								:value="batchFinderCooCommission"
								@change="value => changeAllCommissions('finderCooCommission', value)"
								label="Comissão do COO do Angariador"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
						<td>
							<v-text-field
								outlined
								dense
								:value="batchFinderRecruiterPercentage"
								@change="value => changeAllCommissions('finderRecruiterPercentage', value)"
								label="Comissão do Recrutador do Angariador"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
						<td>
							<v-text-field
								outlined
								dense
								:value="batchFinderRecruiterDirectorPercentage"
								@change="value => changeAllCommissions('finderRecruiterDirectorPercentage', value)"
								label="Comissão do Diretor de Recrutamento do Angariador"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
						<td />
						<td>
							<v-text-field
								outlined
								dense
								:value="batchSellerPercentage"
								@change="value => changeAllCommissions('sellerPercentage', value)"
								label="Comissão do Vendedor"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
						<td />
						<td>
							<v-text-field
								outlined
								dense
								:value="batchSellerCommercialDirectorCommission"
								@change="value => changeAllCommissions('sellerCommercialDirectorCommission', value)"
								label="Comissão do DC do Vendedor"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
						<td />
						<td>
							<v-text-field
								outlined
								dense
								:value="batchSellerBrokerCommission"
								@change="value => changeAllCommissions('sellerBrokerCommission', value)"
								label="Comissão do Broker do Vendedor"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
						<td>
							<v-text-field
								outlined
								dense
								:value="batchSellerCooCommission"
								@change="value => changeAllCommissions('sellerCooCommission', value)"
								label="Comissão do COO do Vendedor"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
						<td>
							<v-text-field
								outlined
								dense
								:value="batchSellerRecruiterPercentage"
								@change="value => changeAllCommissions('sellerRecruiterPercentage', value)"
								label="Comissão do Recrutador do Angariador"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
						<td>
							<v-text-field
								outlined
								dense
								:value="batchSellerRecruiterDirectorPercentage"
								@change="value => changeAllCommissions('sellerRecruiterDirectorPercentage', value)"
								label="Comissão do Diretor de Recrutamento do Angariador"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
						<td />
						<td>
							<v-text-field
								outlined
								dense
								:value="batchCreditManagerCommission"
								@change="value => changeAllCommissions('creditManagerCommission', value)"
								label="Comissão da Gestora de Crédito"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px 15px 0px 15px; width: 100px"
							/>
						</td>
					</template>
					<template v-slot:item.office="{ item }">
						{{ allOffices.find(o => o.key === item.office)?.name }}
					</template>
					<template v-slot:item.offices="{ item }">
						{{ item.offices.map(o => allOffices.find(o2 => o2.key === o)?.name).join(", ") }}
					</template>
					<template v-slot:item.ref="{ item }">
						<v-chip v-if="item.isBroken" color="red">
							{{ formatRef(item.ref) }}
							<v-icon right>mdi-bug</v-icon>
						</v-chip>
						<v-chip v-else-if="isDone(item)" color="green">
							{{ formatRef(item.ref) }}
							<v-icon right>mdi-checkbox-marked-circle</v-icon>
						</v-chip>
						<v-chip v-else color="dark-grey" class="chip" style="width: 100%">{{ formatRef(item.ref) }}</v-chip>
					</template>
					<template v-slot:item.issueDate="{ item }">
						{{ formatDate(item.issueDate, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.date="{ item }">
						{{ formatDate(item.date, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.owner="{ item }">
						<v-chip v-if="item.clientPaymentDate" :outlined="item.isExternalFinder" color="green" class="chip">
							{{ formatOwner(item.owner) }}
							<v-icon right>mdi-checkbox-marked-circle</v-icon>
						</v-chip>
						<v-chip
							v-else-if="item.owner"
							outlined
							:color="item.isExternalFinder ? 'blue' : 'dark-grey'"
							class="chip"
						>
							{{ formatOwner(item.owner) }}
						</v-chip>
						<span v-else></span>
					</template>
					<template v-slot:item.owners="{ item }">
						{{ item.owners ? item.owners.map(o => o.name).join(", ") : "" }}
					</template>
					<template v-slot:item.buyers="{ item }">
						{{ item.buyers ? item.buyers.map(b => b.name).join(", ") : "" }}
					</template>
					<template v-slot:item.finder="{ item }">
						<v-edit-dialog @open="setItem(item._id)" @save="handlePatchItem(item)">
							<div v-if="item.finder">
								<v-tooltip :disabled="item.isExternalFinder || !item.finderTeam" top>
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on" style="display: flex; gap: 10px">
											<v-chip
												v-if="item.isExternalFinder"
												outlined
												:color="item.clientPaymentDate ? 'green' : 'blue'"
												class="chip"
											>
												{{ item.finder.name || item.finder }}
												<v-icon v-if="isFinderDone(item)" right>mdi-checkbox-marked-circle</v-icon>
											</v-chip>
											<v-chip
												v-else-if="item.sentBillsToFinder || item.finderPaymentDate"
												:outlined="item.isExternalFinder"
												:color="item.finderPaymentDate ? 'green' : 'warning'"
												class="chip"
											>
												{{ item.finder.name || item.finder }}
												<v-icon v-if="isFinderDone(item)" right>mdi-checkbox-marked-circle</v-icon>
											</v-chip>
											<v-chip v-else-if="item.finder.name" outlined class="chip">
												{{ item.finder.name }}
											</v-chip>
											<span style="width: 50px">
												<v-chip
													v-if="!item.isExternalFinder && item.finderCommercialDirector"
													outlined
													style="width: 48px"
												>
													{{ formatAvatarName(item.finderCommercialDirector.name) }}
												</v-chip>
											</span>
										</div>
									</template>
									<span>{{ config.teams.find(t => t.key === item.finderTeam)?.name }}</span>
								</v-tooltip>
							</div>
							<v-chip v-else outlined color="error" class="chip"> ... </v-chip>
							<template v-if="type === 'invoices' && featuresMap.edit" v-slot:input>
								<v-autocomplete
									v-if="item.ref === 'Bank'"
									outlined
									v-model="finder"
									item-text="name"
									label="Bancos"
									:items="config.banks"
									return-object
								/>
								<v-combobox
									v-else
									outlined
									v-model="finder"
									item-text="name"
									label="Angariador"
									:items="consultants"
									return-object
								/>
								<v-menu
									v-if="!isExternalFinder"
									v-model="showFinderBillsDatePicker"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="sentBillsToFinder"
											label="Fatura enviada ao angariador"
											v-bind="attrs"
											v-on="on"
										/>
									</template>
									<v-date-picker
										v-model="sentBillsToFinder"
										color="primary"
										@input="showFinderBillsDatePicker = false"
									/>
								</v-menu>
								<v-btn @click="handlePatchItem(item)" style="margin: 10px 0px" color="primary">Guardar</v-btn>
							</template>
						</v-edit-dialog>
					</template>
					<template v-slot:item.finderValue="{ item }">
						{{ formatNumber(item.finderValue) }}
					</template>
					<template v-slot:item.finderCommission="{ item }">
						{{ formatNumber(item.finderCommission) }}
						<v-btn v-if="item.finderCommission" small icon @click="copyLine(item._id)">
							<v-icon small>mdi-content-copy</v-icon>
						</v-btn>
					</template>
					<template v-slot:item.finderPercentage="{ item }">
						<v-text-field
							v-if="view === 'percentages' && !item.isExternalFinder"
							outlined
							dense
							:value="item.finderPercentage"
							@change="value => changeCommission(item, 'finderPercentage', value)"
							label="Comissão do Angariador"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{ item.finderPercentage ? `${item.finderPercentage}%` : "" }}
						</span>
					</template>
					<template v-slot:item.finderCommercialDirector="{ item }">
						{{ !item.isExternalFinder ? formatCommercialDirector(item.finderCommercialDirector) : "" }}
					</template>
					<template v-slot:item.finderCommercialDirectorValue="{ item }">
						{{ formatNumber(item.finderCommercialDirectorValue) }}
					</template>
					<template v-slot:item.finderCommercialDirectorCommission="{ item }">
						<v-text-field
							v-if="view === 'percentages' && !item.isExternalFinder"
							outlined
							dense
							:value="item.finderCommercialDirectorCommission"
							@change="value => changeCommission(item, 'finderCommercialDirectorCommission', value)"
							label="Comissão do DC do Angariador"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{
								!item.isExternalFinder && item.finderCommercialDirectorCommission
									? `${item.finderCommercialDirectorCommission}%`
									: ""
							}}
						</span>
					</template>
					<template v-slot:item.finderBroker="{ item }">
						{{ !item.isExternalFinder ? formatCommercialDirector(item.finderBroker) : "" }}
					</template>
					<template v-slot:item.finderBrokerCommission="{ item }">
						<v-text-field
							v-if="view === 'percentages' && !item.isExternalFinder"
							outlined
							dense
							:value="item.finderBrokerCommission"
							@change="value => changeCommission(item, 'finderBrokerCommission', value)"
							label="Comissão do Broker do Angariador"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{ !item.isExternalFinder && item.finderBrokerCommission ? `${item.finderBrokerCommission}%` : "" }}
						</span>
					</template>
					<template v-slot:item.finderCoo="{ item }">
						{{ !item.isExternalFinder ? formatCommercialDirector(item.finderCoo) : "" }}
					</template>
					<template v-slot:item.finderCooCommission="{ item }">
						<v-text-field
							v-if="view === 'percentages' && !item.isExternalFinder"
							outlined
							dense
							:value="item.finderCooCommission"
							@change="value => changeCommission(item, 'finderCooCommission', value)"
							label="Comissão do COO do Angariador"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{ !item.isExternalFinder && item.finderCooCommission ? `${item.finderCooCommission}%` : "" }}
						</span>
					</template>
					<template v-slot:item.finderRecruiter="{ item }">
						{{ !item.isExternalFinder ? item.finderRecruiter?.name : "" }}
					</template>
					<template v-slot:item.finderRecruiterPercentage="{ item }">
						<v-text-field
							v-if="view === 'percentages' && !item.isExternalFinder"
							outlined
							dense
							:value="item.finderRecruiterPercentage"
							@change="value => changeCommission(item, 'finderRecruiterPercentage', value)"
							label="Comissão do Recrutador do Angariador"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{
								!item.isExternalFinder && item.finderRecruiterPercentage
									? `${item.finderRecruiterPercentage}%`
									: ""
							}}
						</span>
					</template>
					<template v-slot:item.finderRecruiterDirector="{ item }">
						{{ !item.isExternalFinder ? item.finderRecruiterDirector?.name : "" }}
					</template>
					<template v-slot:item.finderRecruiterDirectorPercentage="{ item }">
						<v-text-field
							v-if="view === 'percentages' && !item.isExternalFinder"
							outlined
							dense
							:value="item.finderRecruiterDirectorPercentage"
							@change="value => changeCommission(item, 'finderRecruiterDirectorPercentage', value)"
							label="Comissão do Diretor de Recrutamento do Angariador"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{
								!item.isExternalFinder && item.finderRecruiterDirectorPercentage
									? `${item.finderRecruiterDirectorPercentage}%`
									: ""
							}}
						</span>
					</template>
					<template v-slot:item.seller="{ item }">
						<v-edit-dialog @open="setItem(item._id)" @save="handlePatchItem(item)">
							<div v-if="item.seller">
								<v-tooltip :disabled="item.isExternalSeller || !item.sellerTeam" top>
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on" style="display: flex; gap: 10px">
											<v-chip
												v-if="item.isExternalSeller || item.sentBillsToSeller || item.sellerPaymentDate"
												:outlined="item.isExternalSeller"
												:color="item.sellerPaymentDate ? 'green' : item.isExternalSeller ? 'blue' : 'warning'"
												class="chip"
											>
												{{ item.seller.name || item.seller }}
												<v-icon v-if="isSellerDone(item)" right>mdi-checkbox-marked-circle</v-icon>
											</v-chip>
											<v-chip v-else-if="item.seller.name" outlined class="chip">
												{{ item.seller.name }}
											</v-chip>
											<span style="width: 50px">
												<v-badge
													v-if="type === 'invoices' && item.isExternalSeller"
													dot
													:color="item.files?.find(f => f.active && f.isInvoice) ? 'success' : 'error'"
												/>
												<v-chip v-else-if="item.sellerCommercialDirector" outlined style="width: 48px">
													{{ formatAvatarName(item.sellerCommercialDirector.name) }}
												</v-chip>
											</span>
										</div>
									</template>
									<span>{{ config.teams.find(t => t.key === item.sellerTeam)?.name }}</span>
								</v-tooltip>
							</div>
							<v-chip v-else outlined color="error" class="chip"> ... </v-chip>
							<template v-if="type === 'invoices' && featuresMap.edit" v-slot:input>
								<v-combobox outlined v-model="seller" item-text="name" label="Vendedor" :items="consultants" />
								<v-menu
									v-if="!isExternalSeller"
									v-model="showSellerBillsDatePicker"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="sentBillsToSeller"
											label="Fatura enviada ao vendedor"
											v-bind="attrs"
											v-on="on"
										/>
									</template>
									<v-date-picker
										v-model="sentBillsToSeller"
										color="primary"
										@input="showSellerBillsDatePicker = false"
									/>
								</v-menu>
								<v-btn @click="handlePatchItem(item)" style="margin: 10px 0px" color="primary">Guardar</v-btn>
							</template>
						</v-edit-dialog>
					</template>
					<template v-slot:item.sellerValue="{ item }">
						{{ formatNumber(item.sellerValue) }}
					</template>
					<template v-slot:item.sellerCommission="{ item }">
						{{ formatNumber(item.sellerCommission) }}
						<v-btn v-if="item.sellerCommission" small icon @click="copyLine(item._id)">
							<v-icon small>mdi-content-copy</v-icon>
						</v-btn>
					</template>
					<template v-slot:item.sellerPercentage="{ item }">
						<v-text-field
							v-if="view === 'percentages' && !item.isExternalSeller"
							outlined
							dense
							:value="item.sellerPercentage"
							@change="value => changeCommission(item, 'sellerPercentage', value)"
							label="Comissão do Vendedor"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{ item.sellerPercentage ? `${item.sellerPercentage}%` : "" }}
						</span>
					</template>
					<template v-slot:item.sellerCommercialDirector="{ item }">
						{{ !item.isExternalSeller ? formatCommercialDirector(item.sellerCommercialDirector) : "" }}
					</template>
					<template v-slot:item.sellerCommercialDirectorValue="{ item }">
						{{ formatNumber(item.sellerCommercialDirectorValue) }}
					</template>
					<template v-slot:item.sellerCommercialDirectorCommission="{ item }">
						<v-text-field
							v-if="view === 'percentages' && !item.isExternalSeller"
							outlined
							dense
							:value="item.sellerCommercialDirectorCommission"
							@change="value => changeCommission(item, 'sellerCommercialDirectorCommission', value)"
							label="Comissão do DC do Vendedor"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{
								!item.isExternalSeller && item.sellerCommercialDirectorCommission
									? `${item.sellerCommercialDirectorCommission}%`
									: ""
							}}
						</span>
					</template>
					<template v-slot:item.sellerBroker="{ item }">
						{{ !item.isExternalSeller ? formatCommercialDirector(item.sellerBroker) : "" }}
					</template>
					<template v-slot:item.sellerBrokerCommission="{ item }">
						<v-text-field
							v-if="view === 'percentages' && !item.isExternalSeller"
							outlined
							dense
							:value="item.sellerBrokerCommission"
							@change="value => changeCommission(item, 'sellerBrokerCommission', value)"
							label="Comissão do Broker do Vendedor"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{ !item.isExternalSeller && item.sellerBrokerCommission ? `${item.sellerBrokerCommission}%` : "" }}
						</span>
					</template>
					<template v-slot:item.sellerCoo="{ item }">
						{{ !item.isExternalSeller ? formatCommercialDirector(item.sellerCoo) : "" }}
					</template>
					<template v-slot:item.sellerCooCommission="{ item }">
						<v-text-field
							v-if="view === 'percentages' && !item.isExternalSeller"
							outlined
							dense
							:value="item.sellerCooCommission"
							@change="value => changeCommission(item, 'sellerCooCommission', value)"
							label="Comissão do COO do Vendedor"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{ !item.isExternalSeller && item.sellerCooCommission ? `${item.sellerCooCommission}%` : "" }}
						</span>
					</template>
					<template v-slot:item.sellerRecruiter="{ item }">
						{{ !item.isExternalSeller ? item.sellerRecruiter?.name : "" }}
					</template>
					<template v-slot:item.sellerRecruiterPercentage="{ item }">
						<v-text-field
							v-if="view === 'percentages' && !item.isExternalSeller"
							outlined
							dense
							:value="item.sellerRecruiterPercentage"
							@change="value => changeCommission(item, 'sellerRecruiterPercentage', value)"
							label="Comissão do Recrutador do Vendedor"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{
								!item.isExternalSeller && item.sellerRecruiterPercentage
									? `${item.sellerRecruiterPercentage}%`
									: ""
							}}
						</span>
					</template>
					<template v-slot:item.sellerRecruiterDirector="{ item }">
						{{ !item.isExternalSeller ? item.sellerRecruiterDirector?.name : "" }}
					</template>
					<template v-slot:item.sellerRecruiterDirectorPercentage="{ item }">
						<v-text-field
							v-if="view === 'percentages' && !item.isExternalSeller"
							outlined
							dense
							:value="item.sellerRecruiterDirectorPercentage"
							@change="value => changeCommission(item, 'sellerRecruiterDirectorPercentage', value)"
							label="Comissão do Diretor de Recrutamento do Vendedor"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{
								!item.isExternalSeller && item.sellerRecruiterDirectorPercentage
									? `${item.sellerRecruiterDirectorPercentage}%`
									: ""
							}}
						</span>
					</template>
					<template v-slot:item.commission="{ item }">
						{{
							`${formatNumber(item.commission, false, false, type === "users" ? 0 : 2)}${
								type === "users" ? "%" : ""
							}`
						}}
					</template>
					<template v-slot:item.commercialDirectorCommission="{ item }">
						{{
							item.commercialDirectorCommission
								? `${formatNumber(item.commercialDirectorCommission, false, false, type === "users" ? 0 : 2)}${
										type === "users" ? "%" : ""
								  }`
								: ""
						}}
					</template>
					<template v-slot:item.tipyValue="{ item }">
						{{ formatNumber(item.tipyValue) }}
					</template>
					<template v-slot:item.value="{ item }">
						{{ formatNumber(item.value) }}
					</template>
					<template v-slot:item.toReceive="{ item }">
						<v-chip v-if="item.clientPaymentDate" color="green">
							{{ formatNumber(item.toReceive) }}
							<v-icon right>mdi-checkbox-marked-circle</v-icon>
						</v-chip>
						<span v-else style="color: orange">{{ formatNumber(item.toReceive) }}</span>
					</template>
					<template v-slot:item.toPay="{ item }">
						<v-edit-dialog @open="setItem(item._id)" @save="handlePatchItem(item)">
							<v-chip v-if="item.isPaid" color="green">
								{{ formatNumber(item.toPay) }}
								<v-icon right>mdi-checkbox-marked-circle</v-icon>
							</v-chip>
							<span v-else>{{ formatNumber(item.toPay) }}</span>
							<template v-if="item.clientPaymentDate && featuresMap.edit" v-slot:input>
								<v-menu
									v-if="!isExternalFinder && sentBillsToFinder"
									v-model="showFinderPaymentDatePicker"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="finderPaymentDate"
											label="Data de Pagamento ao Angariador"
											v-bind="attrs"
											v-on="on"
										/>
									</template>
									<v-date-picker
										v-model="finderPaymentDate"
										color="primary"
										@input="showFinderPaymentDatePicker = false"
									/>
								</v-menu>
								<v-divider />
								<br v-if="isExternalSeller" />
								<v-menu
									v-if="isExternalSeller || sentBillsToSeller"
									v-model="showSellerPaymentDatePicker"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="sellerPaymentDate"
											label="Data de Pagamento ao Vendedor"
											v-bind="attrs"
											v-on="on"
										/>
									</template>
									<v-date-picker
										v-model="sellerPaymentDate"
										color="primary"
										@input="showSellerPaymentDatePicker = false"
									/>
								</v-menu>
								<v-btn @click="handlePatchItem(item)" style="margin: 10px 0px" color="primary">Guardar</v-btn>
							</template>
						</v-edit-dialog>
					</template>
					<template v-slot:item.clientProofDate="{ item }">
						<v-edit-dialog @open="setItem(item._id)" @save="handlePatchItem(item)">
							{{ item.clientProofDate ? formatDate(item.clientProofDate, "DD-MMM-YY") : "" }}
							<template v-if="type === 'invoices' && featuresMap.edit" v-slot:input>
								<v-date-picker v-model="clientProofDate" color="primary" />
								<br />
								<v-btn @click="handlePatchItem(item)" style="margin: 10px 0px" color="primary">Guardar</v-btn>
							</template>
						</v-edit-dialog>
					</template>
					<template v-slot:item.clientPaymentDate="{ item }">
						<v-edit-dialog @open="setItem(item._id)" @save="handlePatchItem(item)">
							{{ item.clientPaymentDate ? formatDate(item.clientPaymentDate, "DD-MMM-YY") : "" }}
							<template v-if="type === 'invoices' && featuresMap.edit" v-slot:input>
								<v-date-picker v-model="clientPaymentDate" color="primary" />
								<br />
								<v-btn @click="handlePatchItem(item)" style="margin: 10px 0px" color="primary">Guardar</v-btn>
							</template>
						</v-edit-dialog>
					</template>
					<template v-slot:item.sentBillsToFinder="{ item }">
						{{ item.sentBillsToFinder ? formatDate(item.sentBillsToFinder, "DD-MMM-YY") : "" }}
					</template>
					<template v-slot:item.finderPaymentDate="{ item }">
						{{ item.finderPaymentDate ? formatDate(item.finderPaymentDate, "DD-MMM-YY") : "" }}
					</template>
					<template v-slot:item.sentBillsToSeller="{ item }">
						{{ item.sentBillsToSeller ? formatDate(item.sentBillsToSeller, "DD-MMM-YY") : "" }}
					</template>
					<template v-slot:item.sellerPaymentDate="{ item }">
						{{ item.sellerPaymentDate ? formatDate(item.sellerPaymentDate, "DD-MMM-YY") : "" }}
					</template>
					<template v-slot:item.creditManager="{ item }">
						{{ item.creditManager ? item.creditManager.name : "" }}
					</template>
					<template v-slot:item.creditManagerCommission="{ item }">
						<v-text-field
							v-if="view === 'percentages' && item.ref === 'Bank'"
							outlined
							dense
							:value="item.creditManagerCommission"
							@change="value => changeCommission(item, 'creditManagerCommission', value)"
							label="Comissão da Gestora de Crédito"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 100px"
						/>
						<span v-else>
							{{ item.ref === "Bank" && item.creditManagerCommission ? `${item.creditManagerCommission}%` : "" }}
						</span>
					</template>
					<template v-slot:item.creditManagerValue="{ item }">
						{{ item.creditManagerValue ? formatNumber(item.creditManagerValue) : "" }}
					</template>
					<template v-slot:item.observations="{ item }">
						{{ item.observations }}
					</template>
					<template v-slot:item.link="{ item }">
						<v-btn v-if="item.invoiceId" icon :href="item.link" target="_blank">
							<v-icon>mdi-file-export</v-icon>
						</v-btn>
					</template>
					<template v-slot:item.reservationDate="{ item }">
						{{ formatDate(item.reservationDate, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.predictedExecutionDate="{ item }">
						<v-tooltip v-for="date in item.billingDates" :key="date._id" top color="#222">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
									{{ date.predictedExecutionDate && formatDate(date.predictedExecutionDate, "DD-MMM-YY") }}
								</div>
							</template>
							<span>{{ config.billingTypes.find(t => t.key === date.type)?.name }} - {{ date.percentage }}%</span>
						</v-tooltip>
					</template>
					<template v-slot:item.executionDate="{ item }">
						<v-tooltip v-for="date in item.billingDates" :key="date._id" top color="#222">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
									{{ date.executionDate && formatDate(date.executionDate, "DD-MMM-YY") }}
								</div>
							</template>
							<span>{{ config.billingTypes.find(t => t.key === date.type)?.name }} - {{ date.percentage }}%</span>
						</v-tooltip>
					</template>
					<template v-slot:item.predictedValue="{ item }">
						<v-tooltip v-for="date in item.billingDates" :key="date._id" top color="#222">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
									{{ date.predictedExecutionDate && formatNumber(item.tipyValue * (date.percentage / 100)) }}
								</div>
							</template>
							<span>{{ config.billingTypes.find(t => t.key === date.type)?.name }} - {{ date.percentage }}%</span>
						</v-tooltip>
					</template>
					<template v-slot:item.executedValue="{ item }">
						<v-tooltip v-for="date in item.billingDates" :key="date._id" top color="#222">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
									{{ date.executionDate && formatNumber(item.tipyValue * (date.percentage / 100)) }}
								</div>
							</template>
							<span>{{ config.billingTypes.find(t => t.key === date.type)?.name }} - {{ date.percentage }}%</span>
						</v-tooltip>
					</template>
					<template v-slot:item.pipeline="{ item }">
						<v-tooltip v-for="date in item.billingDates" :key="date._id" top color="#222">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
									{{ date.executionDate ? "" : formatNumber(item.tipyValue * (date.percentage / 100)) }}
								</div>
							</template>
							<span>{{ config.billingTypes.find(t => t.key === date.type)?.name }} - {{ date.percentage }}%</span>
						</v-tooltip>
					</template>
					<template v-slot:item.giveUpDate="{ item }">
						<v-edit-dialog
							v-if="item.billingDates && !item.billingDates.find(d => d.executionDate)"
							@open="setItem(item._id)"
							@save="handlePatchItem(item)"
						>
							{{ item.giveUpDate && formatDate(item.giveUpDate, "DD-MMM-YY") }}
							<template v-if="type === 'reservations' && featuresMap.edit" v-slot:input>
								<v-date-picker v-model="giveUpDate" color="primary" />
								<br />
								<v-btn @click="handlePatchItem(item)" style="margin: 10px 0px" color="primary"> Guardar </v-btn>
							</template>
						</v-edit-dialog>
						<div v-else>
							{{ item.giveUpDate && formatDate(item.giveUpDate, "DD-MMM-YY") }}
						</div>
					</template>
					<template v-slot:item.giveUpReason="{ item }">
						{{
							item.giveUpReason &&
							(config.giveUpReasons.find(r => r.key === item.giveUpReason)?.name || item.giveUpReason)
						}}
					</template>
					<template v-slot:item.impicDate="{ item }">
						<v-edit-dialog
							v-if="item.impic && item.billingDates.find(d => d.executionDate)"
							@open="setItem(item._id)"
							@save="handlePatchItem(item)"
						>
							<v-chip v-if="item.impicDate" color="success">
								{{ formatDate(item.impicDate, "DD-MMM-YY") }}
								<v-icon right>mdi-checkbox-marked-circle</v-icon>
							</v-chip>
							<v-chip v-else color="error" style="cursor: pointer">
								Data
								<v-icon right>mdi-close-circle</v-icon>
							</v-chip>
							<template v-if="type === 'reservations' && featuresMap.edit" v-slot:input>
								<v-date-picker v-model="impicDate" color="primary" />
								<br />
								<v-btn @click="handlePatchItem(item)" style="margin: 10px 0px" color="primary"> Guardar </v-btn>
							</template>
						</v-edit-dialog>
						<v-chip v-else-if="item.impic">
							<v-icon>mdi-checkbox-marked-circle</v-icon>
						</v-chip>
					</template>
					<template v-slot:item.clientPaymentDates="{ item }">
						<v-tooltip v-if="item.clientPaymentDates && Array.isArray(item.clientPaymentDates)" bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-chip v-bind="attrs" v-on="on">
									{{ item.clientPaymentDates.length }}
									<v-icon right>mdi-plus</v-icon>
								</v-chip>
							</template>
							<div
								v-for="invoice in item.clientPaymentDates"
								:key="invoice.number"
								style="
									display: flex;
									gap: 10px;
									justify-content: space-between;
									align-items: center;
									padding: 5px 0px;
								"
							>
								<b>{{ invoice.number }}</b>
								<v-chip v-if="invoice.clientPaymentDate" color="success">
									{{ formatDate(invoice.clientPaymentDate, "DD-MMM-YY") }}
									<v-icon right>mdi-checkbox-marked-circle</v-icon>
								</v-chip>
								<v-chip v-else color="error">
									<v-icon>mdi-close-circle</v-icon>
								</v-chip>
							</div>
						</v-tooltip>
						<v-chip v-else-if="item.clientPaymentDates" color="success">
							{{ formatDate(item.clientPaymentDates, "DD-MMM-YY") }}
							<v-icon right>mdi-checkbox-marked-circle</v-icon>
						</v-chip>
						<v-chip v-else-if="'clientPaymentDates' in item" color="error">
							<v-icon>mdi-close-circle</v-icon>
						</v-chip>
					</template>
					<template v-slot:item.moneyLaundering="{ item }">
						{{ item.moneyLaundering && config.documentationTypes.find(t => t.key === item.moneyLaundering).name }}
					</template>
					<template v-slot:item.riskAnalysis="{ item }">
						{{ item.riskAnalysis && config.documentationTypes.find(t => t.key === item.riskAnalysis).name }}
					</template>
					<template v-slot:item.internalCredit="{ item }">
						{{ item.internalCredit && config.internalCreditTypes.find(t => t.key === item.internalCredit).name }}
					</template>
					<template v-slot:item.cpcvDate="{ item }">
						{{ item.cpcvDate && formatDate(item.cpcvDate, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.deedDate="{ item }">
						{{ item.deedDate && formatDate(item.deedDate, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.scheduledDate="{ item }">
						{{ item.scheduledDate && formatDate(item.scheduledDate, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.creditManager="{ item }">
						{{ item.creditManager?.name }}
					</template>
					<template v-slot:item.creditValue="{ item }">
						{{ formatNumber(item.creditValue) }}
					</template>
					<template v-slot:item.houseValue="{ item }">
						{{ formatNumber(item.houseValue) }}
					</template>
					<template v-slot:item.bankCommission="{ item }">
						{{
							item.bankCommission
								? `${formatNumber(item.bankCommission, false, false, type === "users" ? 0 : 2)}${
										type === "users" ? "%" : ""
								  }`
								: ""
						}}
					</template>
					<template v-slot:item.bankCommissionDate="{ item }">
						{{ item.bankCommissionDate && formatDate(item.bankCommissionDate, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.appraisal="{ item }">
						{{ formatNumber(item.appraisal) }}
					</template>
					<template v-slot:item.appraisalDate="{ item }">
						{{ item.appraisalDate && formatDate(item.appraisalDate, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.houseType="{ item }">
						{{ item.houseType && config.houseTypes.find(t => t.key === item.houseType)?.name }}
					</template>
					<template v-slot:item.area="{ item }"> {{ item.area && `${item.area}m2` }} </template>
					<template v-slot:item.rooms="{ item }"> {{ item.rooms !== null ? `T${item.rooms}` : "" }} </template>
					<template v-slot:item.user="{ item }">
						{{ item.user?.name }}
					</template>
					<template v-slot:item.findingDate="{ item }">
						{{ formatDate(item.findingDate, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.contractType="{ item }">
						{{
							item.contractType &&
							config[type === "findings" ? "findingContractTypes" : "contractTypes"].find(
								t => t.key === item.contractType,
							)?.name
						}}
					</template>
					<template v-slot:item.contractDuration="{ item }">
						{{ item.contractDuration && `${item.contractDuration} meses` }}
					</template>
					<template v-slot:item.regime="{ item }">
						{{ item.regime && config.regimes.find(r => r.key === item.regime).name }}
					</template>
					<template v-slot:item.state="{ item }">
						{{
							item.state &&
							config[
								`${{ viabilities: "viabilityS", reservations: "reservationS", findings: "s" }[type]}tates`
							].find(s => s.key === item.state).name
						}}
					</template>
					<template v-slot:item.percentageSplit="{ item }">
						{{ item.percentageSplit ? `${item.percentageSplit * 100}%` : "" }}
					</template>
					<template v-slot:item.url="{ item }">
						<v-btn v-if="item.url" icon :href="item.url" target="_blank">
							<v-icon>mdi-link</v-icon>
						</v-btn>
					</template>
					<template v-slot:item.consultant="{ item }">
						<v-tooltip v-if="item.consultant" :disabled="item.isExternalConsultant || !item.consultantTeam" top>
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on" style="display: flex; gap: 10px">
									<v-chip outlined :color="item.isExternalConsultant ? 'blue' : ''" class="chip">
										{{ item.consultant.name || item.consultant }}
									</v-chip>
									<span style="width: 50px">
										<v-chip
											v-if="!item.isExternalConsultant && item.consultantCommercialDirector"
											outlined
											style="width: 48px"
										>
											{{ formatAvatarName(item.consultantCommercialDirector.name) }}
										</v-chip>
									</span>
								</div>
							</template>
							<span>{{ config.teams.find(t => t.key === item.consultantTeam)?.name }}</span>
						</v-tooltip>
					</template>
					<template v-slot:item.paySlips="{ item }">
						{{ item.paySlips ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.bankStatements="{ item }">
						{{ item.bankStatements ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.irs="{ item }">
						{{ item.irs ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.settlementNote="{ item }">
						{{ item.settlementNote ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.declarationOfEffectiveness="{ item }">
						{{ item.declarationOfEffectiveness ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.rgpd="{ item }">
						{{ item.rgpd ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.responsabilitiesMap="{ item }">
						{{ item.responsabilitiesMap ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.requested="{ item }">
						<div v-for="request in item.requests.filter(r => r.requested && !r.approved)" :key="request._id">
							<v-chip style="margin: 3px">
								{{ config.banks.find(b => b.key === request.bank)?.name }} -
								{{ formatNumber(request.requestedCredit, true) }}
							</v-chip>
						</div>
					</template>
					<template v-slot:item.approved="{ item }">
						<div v-for="request in item.requests.filter(r => r.approved && !r.chosen)" :key="request._id">
							<v-chip color="info" style="margin: 3px">
								{{ config.banks.find(b => b.key === request.bank)?.name }} -
								{{ formatNumber(request.approvedCredit, true) }}
							</v-chip>
						</div>
					</template>
					<template v-slot:item.chosen="{ item }">
						<div v-for="request in item.requests.filter(r => r.chosen)" :key="request._id">
							<v-chip color="success" style="margin: 3px">
								{{ config.banks.find(b => b.key === request.bank)?.name }} -
								{{ formatNumber(request.chosenCredit, true) }}
								<v-icon right>mdi-checkbox-marked-circle</v-icon>
							</v-chip>
						</div>
					</template>
					<template v-slot:item.category="{ item }">
						{{ config.expenseCategories.find(c => c.key === item.category)?.name }}
					</template>
					<template v-slot:item.subCategory="{ item }">
						{{ config.expenseSubCategories.find(c => c.key === item.subCategory)?.name }}
					</template>
					<template v-slot:item.repeatsMonthly="{ item }">
						{{ item.repeatsMonthly ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.paymentDate="{ item }">
						{{ item.paymentDate && formatDate(item.paymentDate, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.gender="{ item }">
						{{ config.genders.find(g => g.key === item.gender)?.name }}
					</template>
					<template v-slot:item.birthday="{ item }">
						{{ item.birthday ? formatDate(item.birthday, "DD-MMM-YY") : "" }}
					</template>
					<template v-slot:item.country="{ item }">
						{{ item.country ? countries[item.country]?.name : "" }}
					</template>
					<template v-slot:item._created="{ item }">
						{{ formatDate(item._created, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.recruiter="{ item }">
						{{ item.recruiter?.name }}
					</template>
					<template v-slot:item.source="{ item }">
						{{ config.sources.find(s => s.key === item.source)?.name }}
					</template>
					<template v-slot:item.reference="{ item }">
						{{ item.reference?.name }}
					</template>
					<template v-slot:item.experient="{ item }">
						{{ item.experient ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.duplicate="{ item }">
						{{ item.duplicate ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.experienceTypes="{ item }">
						{{
							item.experienceTypes
								? item.experienceTypes.map(t => config.experienceTypes.find(t2 => t2.key === t)?.name).join(", ")
								: ""
						}}
					</template>
					<template v-slot:item.stage="{ item }">
						<v-chip style="margin-top: 3px" small :color="config.stages.find(s => s.key === item.stage).color">
							{{ config.stages.find(s => s.key === item.stage).name }}
						</v-chip>
					</template>
					<template v-slot:item.startDate="{ item }">
						{{ formatDate(item.startDate, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.endDate="{ item }">
						{{ formatDate(item.endDate, "DD-MMM-YY") }}
					</template>
					<template v-slot:item.fullTime="{ item }">
						{{ item.fullTime ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.referencedBy="{ item }">
						{{ item.referencedBy?.name }}
					</template>
					<template v-slot:item.role="{ item }">
						{{ item.role ? config.roles.find(t => t.key === item.role)?.name : "" }}
					</template>
					<template v-slot:item.team="{ item }">
						{{ item.team ? config.teams.find(t => t.key === item.team)?.name : "" }}
					</template>
					<template v-slot:item.manager="{ item }">
						{{ item.manager?.name }}
					</template>
					<template v-slot:item.integration="{ item }">
						{{ item.integration?.name }}
					</template>
					<template v-slot:item.invoice="{ item }">
						{{ item.invoice ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.commercialDirectorBankCommission="{ item }">
						{{ item.commercialDirectorBankCommission ? `${item.commercialDirectorBankCommission}%` : "" }}
					</template>
					<template v-slot:item.brokerCommission="{ item }">
						{{ item.brokerCommission ? `${item.brokerCommission}%` : "" }}
					</template>
					<template v-slot:item.vitamina21Date="{ item }">
						{{ item.vitamina21Date ? formatDate(item.vitamina21Date, "DD-MMM-YY") : "" }}
					</template>
					<template v-slot:item.vitamina21EndDate="{ item }">
						{{ item.vitamina21EndDate ? formatDate(item.vitamina21EndDate, "DD-MMM-YY") : "" }}
					</template>
					<template v-slot:item.integrationMeetingDate="{ item }">
						{{ item.integrationMeetingDate ? formatDate(item.integrationMeetingDate, "DD-MMM-YY") : "" }}
					</template>
					<template v-slot:item.create21Date="{ item }">
						{{ item.create21Date ? formatDate(item.create21Date, "DD-MMM-YY") : "" }}
					</template>
					<template v-slot:item.offers="{ item }">
						{{ item.offers ? item.offers.map(o => config.offers.find(o2 => o2.key === o)?.name).join(", ") : "" }}
					</template>
					<template v-slot:item.hasComputer="{ item }">
						{{ item.hasComputer ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.hasPhone="{ item }">
						{{ item.hasPhone ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.hasVehicle="{ item }">
						{{ item.hasVehicle ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.qualifications="{ item }">
						{{ config.qualifications.find(q => q.key === item.qualifications)?.name }}
					</template>
					<template v-slot:item.residenceTitle="{ item }">
						{{ item.residenceTitle ? "Sim" : "Não" }}
					</template>
					<template v-slot:item.residenceExpirationDate="{ item }">
						{{ item.residenceExpirationDate ? formatDate(item.residenceExpirationDate, "DD-MMM-YY") : "" }}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-badge v-if="actions.includes('comments')" overlap :content="item.comments" :value="!!item.comments">
							<v-btn icon @click="openAction(item, 'Comments')">
								<v-icon>mdi-comment-text</v-icon>
							</v-btn>
						</v-badge>
						<v-badge v-if="actions.includes('tasks')" overlap :content="item.tasks" :value="!!item.tasks">
							<v-btn icon @click="openAction(item, 'Tasks')">
								<v-icon>mdi-checkbox-marked</v-icon>
							</v-btn>
						</v-badge>
						<v-badge
							v-if="featuresMap.addFiles && actions.includes('files') && item.files"
							overlap
							:content="item.files.filter(f => f.active)?.length"
							:value="!!item.files.filter(f => f.active)?.length"
						>
							<v-btn icon @click="openAction(item, 'Files')">
								<v-icon>mdi-folder</v-icon>
							</v-btn>
						</v-badge>
						<v-tooltip
							v-if="
								featuresMap.addInvoice &&
								actions.includes('addInvoice') &&
								item.billingDates.filter(d => !d.executionDate).length
							"
							top
							color="#222"
						>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn v-bind="attrs" v-on="on" icon>
												<v-icon>mdi-file-document</v-icon>
											</v-btn>
										</template>
										<v-list>
											<v-list-item
												:disabled="index !== 0"
												v-for="(date, index) in item.billingDates.filter(d => !d.executionDate)"
												:key="index"
											>
												<v-list-item-title @click="handleAddInvoice(item)" style="cursor: pointer">
													{{ config.billingTypes.find(t => t.key === date.type)?.name }} - {{ date.percentage }}%
												</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</span>
							</template>
							<span>Gerar fatura</span>
						</v-tooltip>
						<v-btn
							v-if="features.listChanges && actions.includes('changes')"
							icon
							@click="openAction(item, 'ChangeLog')"
						>
							<v-icon>mdi-chart-timeline</v-icon>
						</v-btn>
						<v-btn
							v-if="features.addEventsToLead && actions.includes('timeline')"
							:disabled="isClosed(item)"
							icon
							@click="openAction(item, 'Timeline')"
						>
							<v-icon>mdi-network</v-icon>
						</v-btn>
						<v-btn
							v-if="featuresMap.edit && actions.includes('view')"
							icon
							@click="$router.push(`/${type}/${item._id}`)"
						>
							<v-icon>mdi-eye</v-icon>
						</v-btn>
						<v-btn
							v-if="featuresMap.edit && actions.includes('download')"
							icon
							@click="() => handleGeneratePDF(item)"
						>
							<v-icon>mdi-download</v-icon>
						</v-btn>
						<v-btn v-if="featuresMap.edit && actions.includes('edit')" icon @click="openDetail(item)">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
						<span v-if="featuresMap.delete && actions.includes('delete')">
							<DeletionDialog
								v-if="filters.active"
								deletionText="este item"
								:onConfirm="() => handleDeleteItem(item)"
							>
								<template v-slot:activator="{ onClick }">
									<v-btn icon @click="onClick">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
							</DeletionDialog>
							<v-btn v-else icon @click="() => handleDeleteItem(item)">
								<v-icon>mdi-restore</v-icon>
							</v-btn>
						</span>
					</template>
					<template v-slot:expanded-item="{ item }">
						<td
							v-for="column in expandedColumns"
							:key="column"
							:colspan="Math.ceil(columns.length / expandedColumns.length)"
						>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										{{ formatExpanded(item[column], column) }}
									</span>
								</template>
								<span>{{ headers.find(h => h.value === column).text }}</span>
							</v-tooltip>
						</td>
					</template>
					<template v-if="featuresMap.listTotals" slot="body.append">
						<td v-if="canCopy(true)" />
						<td v-for="header in filteredHeaders" :key="header.value" style="height: 48px">
							{{
								totals && (header.type === "number" || header.value === "clientPaymentDate")
									? formatNumber(totals[header.value])
									: ""
							}}
						</td>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-dialog v-model="showDetail">
			<slot name="detail" :selectedItem="selectedItem" :updateDetail="updateDetail" :closeDetail="closeDetail" />
		</v-dialog>
		<v-dialog v-if="selectedItem" v-model="showComments" width="550px">
			<v-card style="padding: 10px; text-align: left; border-bottom: 1px solid grey">
				<v-chip>{{ selectedItem.ref || selectedItem.name }}</v-chip>
				<span style="float: right; padding-top: 5px">
					{{ selectedItem.buyer || (selectedItem.buyers && selectedItem.buyers[0]?.name) }}
				</span>
			</v-card>
			<Comments
				:group="type"
				:id="selectedItem._id"
				:onSubmit="onCommentSubmit"
				:types="
					['viabilities', 'findings', 'reservations', 'invoices'].includes(type)
						? [
								{ name: 'Processual', value: 'process' },
								{ name: 'Crédito', value: 'credit' },
								{ name: 'Broker', value: 'broker' },
						  ]
						: type === 'users'
						? [
								{ name: 'Diretor Comercial', value: 'commercialDirector' },
								{ name: 'Broker', value: 'broker' },
						  ]
						: null
				"
			/>
		</v-dialog>
		<v-dialog v-if="selectedItem" v-model="showTasks" width="550px">
			<v-card>
				<div style="padding: 10px; text-align: left; border-bottom: 1px solid grey">
					<v-chip>{{ selectedItem.ref || selectedItem.name }}</v-chip>
					<span style="float: right; padding-top: 5px; max-width: 400px; white-space: nowrap; overflow: hidden">
						{{ selectedItem.buyer || (selectedItem.buyers && selectedItem.buyers[0]?.name) }}
					</span>
				</div>
				<Tasks :idOverwrite="selectedItem._id" />
			</v-card>
		</v-dialog>
		<v-dialog v-if="selectedItem" v-model="showFiles" width="550px">
			<v-card style="padding: 10px; text-align: left; border-bottom: 1px solid grey">
				<v-chip>{{ selectedItem.ref || selectedItem.name }}</v-chip>
				<span style="float: right; padding-top: 5px">
					{{ selectedItem.buyer || (selectedItem.buyers && selectedItem.buyers[0]?.name) }}
				</span>
			</v-card>
			<Files
				:files="selectedItem.files"
				:group="type"
				:id="selectedItem._id"
				:patchFunction="patchItem"
				:onSubmit="onDialogSubmit"
			/>
		</v-dialog>
		<v-dialog v-if="selectedItem" v-model="showChangeLog" width="750px">
			<ChangeLog :model="type === 'viabilities' ? 'viability' : type.slice(0, -1)" :id="selectedItem._id" />
		</v-dialog>
		<v-dialog v-model="showTimeline" max-width="1000px">
			<LeadTimelineForm :lead="selectedItem" :onClose="closeDetail" />
		</v-dialog>
		<v-dialog v-model="showTeams">
			<ListForm
				:translations="[]"
				:consultants="consultants"
				:list="{ name: 'teams', items: config.teams }"
				:onClose="reload"
			/>
		</v-dialog>
	</v-container>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import { countries } from "countries-list";
import { all as languagesList } from "locale-codes";
import { PDFDocument, rgb } from "pdf-lib";
import FileSaver from "file-saver";

import DeletionDialog from "../components/DeletionDialog.vue";
import Profiles from "../components/Profiles.vue";
import Comments from "../components/Comments.vue";
import Files from "../components/Files.vue";
import Tasks from "../components/Tasks.vue";
import ChangeLog from "../components/ChangeLog.vue";
import LeadTimelineForm from "../components/LeadTimelineForm.vue";
import ListForm from "../components/ListForm.vue";

import { patchInvoices, generateInvoice } from "../api/invoices";
import { getUsers, importUsers, exportUsers } from "../api/users";
import { generateGmailDrafts, generateDCGmailDrafts, generateSalaryReceipts } from "../api/gmail";

import { formatDate, formatNumber, formatOwner, formatAvatarName } from "../utils/utils";

export default Vue.extend({
	name: "DataTable",
	props: [
		"type",
		"featuresMap",
		"title",
		"quickFilters",
		"filtersConfig",
		"getItems",
		"getFilters",
		"patchItem",
		"deleteItem",
		"actions",
		"profileOverwrite",
		"sortByOverwrite",
	],
	components: { DeletionDialog, Profiles, Comments, Files, Tasks, ChangeLog, LeadTimelineForm, ListForm },
	async created() {
		if (this.sortByOverwrite) this.sortBy = this.sortByOverwrite;

		this.setProfile();

		await this.handleGetUsers();
		if (this.getFilters) await this.handleGetFilters();

		window.addEventListener("keydown", e => {
			if (
				/textarea|select|input/i.test(event.target.nodeName) ||
				["text", "password"].includes(event.target.type)
			) {
				return;
			}

			if (this.type === "invoices") {
				if (e.key === "n") {
					this.filterOptions.clientPaymentDate = this.filterOptions.clientPaymentDate === "null" ? false : "null";
					this.handleSetFilters("clientPaymentDate", this.filterOptions.clientPaymentDate);
				} else if (e.key === "m") {
					this.filterOptions.isBank = this.filterOptions.isBank === "null" ? false : "null";
					this.handleSetFilters("isBank", this.filterOptions.isBank);
				}
			}

			if (e.key === "f") this.showFilters = !this.showFilters;
		});

		this.languagesList = languagesList.map(language => ({
			name: `${language.local || language.name} ${language.location ? `(${language.location})` : ""}`,
			value: language.tag,
		}));
		this.countries = countries;
	},
	watch: {
		selectedOffices: function () {
			this.handleGetItems();
		},
		dateRange: function () {
			this.handleGetItems();
		},
		profiles: function () {
			this.setProfile();
		},
		view: function () {
			this.formatColumns();
		},
		quickFilter(filter) {
			if (filter) {
				const quickFilter = this.quickFilters.find(f => f.value === filter);

				for (const [key, value] of Object.entries(quickFilter.filters)) {
					this.handleSetFilters(key, value);

					if (key === "stage") this.filterOptions.stage = value;
				}
			} else {
				delete this.filters.stage;
				this.filterOptions.stage = [];

				this.page = 0;

				this.handleGetItems();
			}
		},
	},
	computed: {
		...mapState([
			"company",
			"user",
			"features",
			"profiles",
			"allOffices",
			"selectedOffices",
			"dateRange",
			"redirect",
			"config",
		]),
	},
	data() {
		return {
			headers: [
				// Invoices
				{
					text: "Agência",
					value: "office",
					width: 120,
					types: ["invoices", "reservations", "findings", "viabilities", "expenses", "leads"],
					format: office => this.allOffices.find(o => o.key === office)?.name,
				},
				{
					text: "Ref",
					value: "ref",
					align: "center",
					width: 120,
					format: this.formatRef,
					types: ["invoices", "reservations", "findings"],
				},
				{
					text: "Nº",
					value: "number",
					align: "center",
					width: 75,
					types: ["invoices", "reservations", "findings", "expenses"],
				},
				{
					text: "Data de Emissão",
					value: "issueDate",
					type: "date",
					width: 100,
					types: ["invoices"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Data",
					value: "date",
					type: "date",
					width: 100,
					types: ["invoices", "viabilities", "expenses"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Proprietário",
					value: "owner",
					align: "center",
					width: 165,
					format: formatOwner,
					types: ["invoices", "findings"],
				},
				{ text: "Comprador", value: "buyer", align: "center", width: 165, types: ["invoices"] },
				{
					text: "Angariador",
					value: "finder",
					type: "name",
					align: "center",
					sortable: false,
					width: 140,
					types: ["invoices", "reservations", "findings"],
					getStyles: item => (item.isExternalFinder ? "color: blue" : ""),
					format: finder => finder.name,
				},
				{
					text: "Faturação Angariador",
					value: "finderValue",
					type: "number",
					align: "right",
					width: 120,
					types: ["invoices", "reservations"],
					format: number => formatNumber(number, false, true),
				},
				{
					text: "Comissão Angariador",
					value: "finderCommission",
					type: "number",
					align: "right",
					width: 120,
					types: ["invoices", "reservations"],
					format: number => formatNumber(number, false, true),
				},
				{
					text: "% Angariador",
					value: "finderPercentage",
					align: "right",
					width: 75,
					types: ["invoices", "reservations"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "DC Angariador",
					value: "finderCommercialDirector",
					type: "name",
					align: "center",
					width: 75,
					types: ["invoices", "reservations", "findings"],
					format: commercialDirector => commercialDirector.name,
				},
				{
					text: "Comissão DC Angariador",
					value: "finderCommercialDirectorValue",
					type: "number",
					align: "right",
					width: 120,
					types: ["invoices", "reservations"],
					format: number => formatNumber(number, false, true),
				},
				{
					text: "% DC Angariador",
					value: "finderCommercialDirectorCommission",
					align: "right",
					width: 75,
					types: ["invoices", "reservations"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "Broker Angariador",
					value: "finderBroker",
					type: "name",
					align: "center",
					width: 75,
					types: ["invoices", "reservations"],
					format: broker => broker.name,
				},
				{
					text: "% Broker Angariador",
					value: "finderBrokerCommission",
					align: "right",
					width: 75,
					types: ["invoices", "reservations"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "COO Angariador",
					value: "finderCoo",
					type: "name",
					align: "center",
					width: 140,
					types: ["invoices"],
					format: broker => broker.name,
				},
				{
					text: "% COO Angariador",
					value: "finderCooCommission",
					align: "right",
					width: 75,
					types: ["invoices"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "Recrutador Angariador",
					value: "finderRecruiter",
					align: "right",
					width: 140,
					types: ["invoices", "reservations"],
					format: recruiter => recruiter.name,
				},
				{
					text: "% Recrutador Angariador",
					value: "finderRecruiterPercentage",
					align: "right",
					width: 75,
					types: ["invoices", "reservations"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "Director de Recrutamento Angariador",
					value: "finderRecruiterDirector",
					align: "right",
					width: 140,
					types: ["invoices", "reservations"],
					format: recruiterDirector => recruiterDirector.name,
				},
				{
					text: "% Director de Recrutamento Angariador",
					value: "finderRecruiterDirectorPercentage",
					align: "right",
					width: 75,
					types: ["invoices", "reservations"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "Vendedor",
					value: "seller",
					align: "center",
					sortable: false,
					width: 140,
					types: ["invoices", "reservations"],
					getStyles: item => (item.isExternalSeller ? "color: blue" : ""),
					format: seller => seller.name,
				},
				{
					text: "Faturação Vendedor",
					value: "sellerValue",
					type: "number",
					align: "right",
					width: 120,
					types: ["invoices", "reservations"],
					format: number => formatNumber(number, false, true),
				},
				{
					text: "Comissão Vendedor",
					value: "sellerCommission",
					type: "number",
					align: "right",
					width: 120,
					types: ["invoices", "reservations"],
					format: number => formatNumber(number, false, true),
				},
				{
					text: "% Vendedor",
					value: "sellerPercentage",
					align: "right",
					width: 75,
					types: ["invoices", "reservations"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "DC Vendedor",
					value: "sellerCommercialDirector",
					type: "name",
					align: "center",
					width: 75,
					types: ["invoices", "reservations"],
					format: commercialDirector => commercialDirector.name,
				},
				{
					text: "Comissão DC Vendedor",
					value: "sellerCommercialDirectorValue",
					type: "number",
					align: "right",
					width: 120,
					types: ["invoices", "reservations"],
					format: number => formatNumber(number, false, true),
				},
				{
					text: "% DC Vendedor",
					value: "sellerCommercialDirectorCommission",
					align: "right",
					width: 75,
					types: ["invoices", "reservations"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "Broker Vendedor",
					value: "sellerBroker",
					type: "name",
					align: "center",
					width: 140,
					types: ["invoices", "reservations"],
					format: broker => broker.name,
				},
				{
					text: "% Broker Vendedor",
					value: "sellerBrokerCommission",
					align: "right",
					width: 75,
					types: ["invoices", "reservations"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "COO Vendedor",
					value: "sellerCoo",
					type: "name",
					align: "center",
					width: 140,
					types: ["invoices"],
					format: broker => broker.name,
				},
				{
					text: "% COO Vendedor",
					value: "sellerCooCommission",
					align: "right",
					width: 75,
					types: ["invoices"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "Recrutador Vendedor",
					value: "sellerRecruiter",
					align: "right",
					width: 140,
					types: ["invoices", "reservations"],
					format: recruiter => recruiter.name,
				},
				{
					text: "% Recrutador Vendedor",
					value: "sellerRecruiterPercentage",
					align: "right",
					width: 75,
					types: ["invoices", "reservations"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "Director de Recrutamento Vendedor",
					value: "sellerRecruiterDirector",
					align: "right",
					width: 140,
					types: ["invoices", "reservations"],
					format: recruiterDirector => recruiterDirector.name,
				},
				{
					text: "% Director de Recrutamento Vendedor",
					value: "sellerRecruiterDirectorPercentage",
					align: "right",
					width: 75,
					types: ["invoices", "reservations"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "Comissão Consultor",
					value: "commission",
					type: "number",
					align: "right",
					width: 120,
					types: ["invoices", "reservations", "users"],
					format: number =>
						`${formatNumber(number, false, true, this.type === "users" ? 0 : 2)}${
							this.type === "users" ? "%" : ""
						}`,
				},
				{
					text: "Comissão DC",
					value: "commercialDirectorCommission",
					type: "number",
					align: "right",
					width: 120,
					types: ["invoices", "reservations", "users"],
					format: number =>
						`${formatNumber(number, false, true, this.type === "users" ? 0 : 2)}${
							this.type === "users" ? "%" : ""
						}`,
				},
				{
					text: "Comissão da Agência",
					value: "tipyValue",
					type: "number",
					align: "right",
					width: 100,
					types: ["invoices", "reservations"],
					getStyles: item => (item.isPaid ? "background-color: green" : ""),
					format: number => formatNumber(number, false, true),
				},
				{
					text: this.type === "expenses" ? "Valor" : "Comissão Total",
					value: "value",
					type: "number",
					align: "right",
					width: 140,
					types: ["invoices", "reservations", "findings", "expenses"],
					format: number => formatNumber(number, false, true),
				},
				{
					text: "Percentagem Interna",
					value: "percentageSplit",
					type: "number",
					align: "right",
					width: 50,
					types: ["invoices", "reservations", "findings"],
					format: percentageSplit => `${percentageSplit * 100}%`,
				},
				{
					text: "Por receber",
					value: "toReceive",
					type: "number",
					align: "right",
					width: 95,
					types: ["invoices"],
					format: number => formatNumber(number, false, true),
				},
				{
					text: "Por pagar",
					value: "toPay",
					type: "number",
					align: "right",
					width: 110,
					types: ["invoices"],
					format: number => formatNumber(number, false, true),
				},
				{
					text: "Comprovado pelo cliente",
					value: "clientProofDate",
					type: "date",
					width: 100,
					types: ["invoices"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Pago pelo cliente",
					value: "clientPaymentDate",
					type: "date",
					width: 100,
					types: ["invoices"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Fatura enviada ao angariador",
					value: "sentBillsToFinder",
					type: "date",
					width: 100,
					types: ["invoices"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Pago ao angariador",
					value: "finderPaymentDate",
					type: "date",
					width: 100,
					types: ["invoices"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Fatura enviada ao vendedor",
					value: "sentBillsToSeller",
					type: "date",
					width: 100,
					types: ["invoices"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Pago ao vendedor",
					value: "sellerPaymentDate",
					type: "date",
					width: 100,
					types: ["invoices"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Gestora de Crédito",
					value: "creditManager",
					align: "center",
					sortable: false,
					width: 140,
					types: ["invoices", "reservations", "viabilities"],
					format: creditManager => creditManager.name,
				},
				{
					text: "% Gestora de Crédito",
					value: "creditManagerCommission",
					align: "right",
					width: 75,
					types: ["invoices"],
					format: number => `${formatNumber(number, false, true)}%`,
				},
				{
					text: "Comissão da Gestora de Crédito",
					value: "creditManagerValue",
					type: "number",
					align: "right",
					width: 100,
					types: ["invoices"],
					format: number => formatNumber(number, false, true),
				},
				{ text: "Observações", value: "observations", width: 120 },
				{ text: "Link", value: "link", width: 120, types: ["invoices"] },
				{ text: "Ações", value: "actions", align: "right", width: 360 },

				// Reservations
				{
					text: "Reservado",
					value: "reservationDate",
					align: "center",
					width: 120,
					types: ["reservations"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Proprietários",
					value: "owners",
					align: "center",
					width: 165,
					types: ["reservations"],
					format: owners => owners.map(o => o.name).join(", "),
				},
				{
					text: "Compradores",
					value: "buyers",
					align: "center",
					width: 165,
					types: ["reservations", "viabilities"],
					format: buyers => buyers.map(b => b.name).join(", "),
				},
				{
					text: "Previsão",
					value: "predictedExecutionDate",
					align: "center",
					width: 120,
					types: ["reservations"],
					skipNullCheck: true,
					format: (date, item) =>
						item.billingDates
							.map(d => d.predictedExecutionDate && formatDate(d.predictedExecutionDate, "DD-MMM-YY"))
							.filter(d => d)
							.join(","),
				},
				{
					text: "Faturado",
					value: "executionDate",
					align: "center",
					width: 120,
					types: ["reservations"],
					skipNullCheck: true,
					format: (date, item) =>
						item.billingDates
							.map(d => d.executionDate && formatDate(d.executionDate, "DD-MMM-YY"))
							.filter(d => d)
							.join(","),
				},
				{
					text: "Valor Previsão",
					value: "predictedValue",
					total: "value",
					type: "number",
					align: "right",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: (date, item) =>
						item.billingDates
							.map(d => d.predictedExecutionDate && formatNumber(item.tipyValue * (d.percentage / 100)))
							.filter(d => d)
							.join(","),
				},
				{
					text: "Valor Faturado",
					value: "executedValue",
					total: "executions",
					type: "number",
					align: "right",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: (date, item) =>
						item.billingDates
							.map(d => d.executionDate && formatNumber(item.tipyValue * (d.percentage / 100)))
							.filter(d => d)
							.join(","),
				},
				{
					text: "Valor por Faturar",
					value: "pipeline",
					type: "number",
					align: "right",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: (date, item) =>
						item.billingDates
							.map(d => (d.executionDate ? "" : formatNumber(item.tipyValue * (d.percentage / 100))))
							.filter(d => d)
							.join(","),
				},
				{
					text: "Desistência",
					value: "giveUpDate",
					align: "center",
					width: 120,
					types: ["reservations", "viabilities"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Razão da Desistência",
					value: "giveUpReason",
					align: "center",
					width: 140,
					types: ["reservations", "viabilities"],
					format: giveUpReason =>
						this.config.giveUpReasons.find(r => r.key === giveUpReason)?.name || giveUpReason,
				},
				{
					text: "Pago em",
					value: "clientPaymentDates",
					align: "center",
					sortable: false,
					width: 85,
					types: ["reservations"],
					skipNullCheck: true,
					format: (clientPaymentDates, item) => {
						let lines = "";

						if (clientPaymentDates && Array.isArray(clientPaymentDates)) {
							for (const invoice of clientPaymentDates) {
								lines += `${invoice.number} - ${
									invoice.clientPaymentDate ? formatDate(invoice.clientPaymentDate, "DD-MMM-YY") : "Não pago"
								}<br/>`;
							}
						} else if (clientPaymentDates) {
							lines = formatDate(clientPaymentDates, "DD-MMM-YY");
						} else if ("clientPaymentDates" in item) {
							lines = "Não pago";
						}

						return lines;
					},
				},
				{
					text: "IMPIC",
					value: "impicDate",
					align: "center",
					width: 120,
					types: ["reservations"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Intermediação",
					value: "internalCredit",
					align: "center",
					width: 140,
					types: ["reservations"],
					format: internalCredit => this.config.internalCreditTypes.find(t => t.key === internalCredit)?.name,
				},
				{
					text: "Branqueamento de Capitais",
					value: "moneyLaundering",
					align: "center",
					width: 140,
					types: ["reservations"],
					format: moneyLaundering => this.config.documentationTypes.find(t => t.key === moneyLaundering)?.name,
				},
				{
					text: "Análise de Risco",
					value: "riskAnalysis",
					align: "center",
					width: 140,
					types: ["reservations"],
					format: riskAnalysis => this.config.documentationTypes.find(t => t.key === riskAnalysis)?.name,
				},
				{
					text: "Data de CPCV",
					value: "cpcvDate",
					align: "center",
					width: 120,
					types: ["reservations"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Data de Escritura",
					value: "deedDate",
					align: "center",
					width: 120,
					types: ["reservations"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Agendamento",
					value: "scheduledDate",
					align: "center",
					width: 140,
					types: ["reservations", "viabilities"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Financiamento",
					value: "creditValue",
					type: "number",
					align: "right",
					width: 140,
					types: ["reservations"],
					format: number => formatNumber(number, false, true),
				},
				{
					text: "Valor de Venda",
					value: "houseValue",
					type: "number",
					align: "right",
					width: 140,
					types: ["reservations", "findings"],
					format: number => formatNumber(number, false, true),
				},
				{
					text: "Valor do Sinal",
					value: "signalValue",
					type: "number",
					align: "right",
					width: 140,
					types: ["reservations"],
					format: number => formatNumber(number, false, true),
				},
				{ text: "Banco", value: "creditBank", align: "center", width: 140, types: ["reservations"] },
				{
					text: "Comissão Bancária",
					value: "bankCommission",
					type: "number",
					align: "center",
					width: 140,
					types: ["reservations", "users"],
					format: number =>
						`${formatNumber(number, false, true, this.type === "users" ? 0 : 2)}${
							this.type === "users" ? "%" : ""
						}`,
				},
				{
					text: "Data de Extrato",
					value: "bankCommissionDate",
					align: "center",
					width: 140,
					types: ["reservations"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Avaliação",
					value: "appraisal",
					type: "number",
					align: "center",
					width: 140,
					types: ["reservations"],
					format: number => formatNumber(number, false, true),
				},
				{
					text: "Data de Avaliação",
					value: "appraisalDate",
					align: "center",
					width: 120,
					types: ["reservations"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Morada",
					value: "address",
					align: "center",
					width: 140,
					types: ["reservations", "findings", "users"],
				},
				{
					text: "Código Postal",
					value: "postalCode",
					align: "center",
					width: 140,
					types: ["reservations", "findings", "users"],
				},
				{
					text: "Cidade",
					value: "city",
					align: "center",
					width: 140,
					types: ["reservations", "findings", "users"],
				},
				{
					text: "Tipo de Imóvel",
					value: "houseType",
					align: "center",
					width: 140,
					types: ["reservations", "findings"],
					format: houseType => this.config.houseTypes.find(t => t.key === houseType)?.name,
				},
				{
					text: "Área Útil",
					value: "area",
					align: "center",
					width: 140,
					types: ["reservations", "findings"],
					format: area => `${area}m2`,
				},
				{
					text: "Tipologia",
					value: "rooms",
					align: "center",
					width: 140,
					types: ["reservations", "findings"],
					format: rooms => `T${rooms}`,
				},
				{
					text: "Certidão Registo Predial",
					value: "predialRegistrationCertificate",
					align: "center",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: value => (value ? "Sim" : "Não"),
				},
				{
					text: "Caderneta Predial",
					value: "predialBooklet",
					align: "center",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: value => (value ? "Sim" : "Não"),
				},
				{
					text: "Licença de Utilização",
					value: "usageLicense",
					align: "center",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: value => (value ? "Sim" : "Não"),
				},
				{
					text: "Certidão de Isenção LU (1951)",
					value: "luExemptionCertificate",
					align: "center",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: value => (value ? "Sim" : "Não"),
				},
				{
					text: "Certidão de Infraestruturas (1ª Transmissão)",
					value: "infrastructureCertificate",
					align: "center",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: value => (value ? "Sim" : "Não"),
				},
				{
					text: "Ficha Técnica de Habitação (FTH)",
					value: "housingTechnicalSheet",
					align: "center",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: value => (value ? "Sim" : "Não"),
				},
				{
					text: "Certificado Energético",
					value: "energeticCertificate",
					align: "center",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: value => (value ? "Sim" : "Não"),
				},
				{
					text: "Planta do Imóvel",
					value: "propertyPlan",
					align: "center",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: value => (value ? "Sim" : "Não"),
				},
				{
					text: "KYC",
					value: "kyc",
					align: "center",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: value => (value ? "Sim" : "Não"),
				},
				{
					text: "Direitos de Preferência",
					value: "preferenceRights",
					align: "center",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: value => (value ? "Sim" : "Não"),
				},
				{
					text: "Tipo de Negócio",
					value: "dealType",
					align: "center",
					width: 140,
					types: ["reservations"],
					format: dealType => this.config.dealTypes.find(t => t.key === dealType)?.name,
				},
				{
					text: "Seguro",
					value: "insurance",
					align: "center",
					width: 100,
					types: ["reservations"],
					skipNullCheck: true,
					format: value => (value ? "Sim" : "Não"),
				},
				{
					text: "Condomínio",
					value: "condominium",
					align: "center",
					width: 140,
					types: ["reservations"],
					format: condominiumTypes => this.config.condominiumTypes.find(t => t.key === condominiumTypes)?.name,
				},
				{
					text: "Criado por",
					value: "user",
					align: "center",
					width: 165,
					types: ["reservations", "findings", "viabilities", "leads"],
					format: user => user.name,
				},

				// Findings
				{
					text: "Angariado",
					value: "findingDate",
					align: "center",
					width: 120,
					types: ["findings"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},
				{
					text: "Tipo de Contrato",
					value: "contractType",
					align: "center",
					width: 120,
					types: ["findings", "users"],
					format: contractType =>
						this.config[this.type === "findings" ? "findingContractTypes" : "contractTypes"].find(
							t => t.key === contractType,
						)?.name,
				},
				{
					text: "Duração do Contrato",
					value: "contractDuration",
					align: "center",
					width: 120,
					types: ["findings"],
					format: contractDuration => `${contractDuration} meses`,
				},
				{
					text: "Regime",
					value: "regime",
					align: "center",
					width: 120,
					types: ["findings"],
					format: regime => this.config.regimes.find(r => r.key === regime)?.name,
				},
				{
					text: "Estado",
					value: "state",
					align: "center",
					width: 120,
					types: ["reservations", "findings", "viabilities"],
					format: state => this.config.states.find(s => s.key === state)?.name,
				},
				{ text: "Link", value: "url", align: "center", width: 120, types: ["findings"] },

				// Viabilities
				{
					text: "Consultor",
					value: "consultant",
					type: "name",
					align: "center",
					sortable: false,
					width: 140,
					types: ["viabilities"],
					getStyles: item => (item.isExternalConsultant ? "color: blue" : ""),
					format: consultant => consultant.name,
				},
				{
					text: "Recibos de Vencimento",
					value: "paySlips",
					align: "center",
					width: 100,
					types: ["viabilities"],
					skipNullCheck: true,
					format: paySlips => (paySlips ? "Sim" : "Não"),
				},
				{
					text: "Extratos Bancários",
					value: "bankStatements",
					align: "center",
					width: 100,
					types: ["viabilities"],
					skipNullCheck: true,
					format: bankStatements => (bankStatements ? "Sim" : "Não"),
				},
				{
					text: "IRS",
					value: "irs",
					align: "center",
					width: 100,
					types: ["viabilities"],
					skipNullCheck: true,
					format: irs => (irs ? "Sim" : "Não"),
				},
				{
					text: "Nota de Liquidação",
					value: "settlementNote",
					align: "center",
					width: 100,
					types: ["viabilities"],
					skipNullCheck: true,
					format: settlementNote => (settlementNote ? "Sim" : "Não"),
				},
				{
					text: "Declaração de Efetividade",
					value: "declarationOfEffectiveness",
					align: "center",
					width: 100,
					types: ["viabilities"],
					skipNullCheck: true,
					format: declarationOfEffectiveness => (declarationOfEffectiveness ? "Sim" : "Não"),
				},
				{
					text: "RGPD",
					value: "rgpd",
					align: "center",
					width: 100,
					types: ["viabilities"],
					skipNullCheck: true,
					format: rgpd => (rgpd ? "Sim" : "Não"),
				},
				{
					text: "Mapa de Responsabilidades",
					value: "responsabilitiesMap",
					align: "center",
					width: 100,
					types: ["viabilities"],
					skipNullCheck: true,
					format: responsabilitiesMap => (responsabilitiesMap ? "Sim" : "Não"),
				},
				{
					text: "Pedido",
					value: "requested",
					width: 140,
					types: ["viabilities"],
					skipNullCheck: true,
					format: (requested, item) =>
						item.requests
							.filter(r => r.requested)
							.map(
								r =>
									`${this.config.banks.find(b => b.key === r.bank).name} - ${formatNumber(
										r.requestedCredit,
										true,
									)}`,
							)
							.join("<br/>"),
				},
				{
					text: "Aprovado",
					value: "approved",
					width: 140,
					types: ["viabilities"],
					skipNullCheck: true,
					format: (approved, item) =>
						item.requests
							.filter(r => r.approved)
							.map(
								r =>
									`${this.config.banks.find(b => b.key === r.bank).name} - ${formatNumber(
										r.approvedCredit,
										true,
									)}`,
							)
							.join("<br/>"),
				},
				{
					text: "Escolhido",
					value: "chosen",
					width: 140,
					types: ["viabilities"],
					skipNullCheck: true,
					format: (chosen, item) =>
						item.requests
							.filter(r => r.chosen)
							.map(
								r =>
									`${this.config.banks.find(b => b.key === r.bank).name} - ${formatNumber(r.chosenCredit, true)}`,
							)
							.join("<br/>"),
				},

				// Expenses
				{
					text: "Categoria",
					value: "category",
					width: 140,
					types: ["expenses"],
					format: category => this.config.expenseCategories.find(s => s.key === category)?.name,
				},
				{
					text: "Sub-Categoria",
					value: "subCategory",
					width: 140,
					types: ["expenses"],
					format: subCategory => this.config.expenseSubCategories.find(s => s.key === subCategory)?.name,
				},
				{
					text: "Repete Mensalmente",
					value: "repeatsMonthly",
					width: 100,
					types: ["expenses"],
					skipNullCheck: true,
					format: repeatsMonthly => (repeatsMonthly ? "Sim" : "Não"),
				},
				{
					text: "Data de Pagamento",
					value: "paymentDate",
					type: "date",
					width: 100,
					types: ["expenses"],
					format: date => formatDate(date, "DD-MMM-YY"),
				},

				// Leads
				{
					text: "Nome",
					value: "name",
					align: "center",
					width: 140,
					types: ["leads", "users", "expenses"],
				},
				{
					text: "Género",
					value: "gender",
					align: "center",
					width: 100,
					types: ["leads", "users"],
					format: gender => this.config.genders.find(g => g.key === gender)?.name,
				},
				{
					text: "Aniversário",
					value: "birthday",
					align: "center",
					width: 100,
					types: ["leads", "users"],
					format: birthday => formatDate(birthday, "DD-MMM-YY"),
				},
				{
					text: "Email",
					value: "personalEmail",
					align: "center",
					width: 140,
					types: ["leads", "users"],
				},
				{
					text: "Telefone",
					value: "phone",
					align: "center",
					width: 100,
					types: ["leads", "users"],
				},
				{
					text: "Telefone 2",
					value: "phone2",
					align: "center",
					width: 100,
					types: ["leads", "users"],
				},
				{
					text: "País",
					value: "country",
					align: "center",
					width: 100,
					types: ["leads", "users"],
					format: country => countries[country]?.name,
				},
				{
					text: "Data de Entrada",
					value: "_created",
					align: "center",
					width: 100,
					types: ["leads"],
					format: _created => formatDate(_created, "DD-MMM-YY"),
				},
				{
					text: "Fonte",
					value: "source",
					align: "center",
					width: 100,
					types: ["leads", "users"],
					format: source => this.config.sources.find(s => s.key === source)?.name,
				},
				{
					text: "Referência",
					value: "reference",
					align: "center",
					width: 140,
					types: ["leads"],
					format: reference => reference?.name,
				},
				{
					text: "Recrutador",
					value: "recruiter",
					align: "center",
					width: 140,
					types: ["leads", "users"],
					format: recruiter => recruiter?.name,
				},
				{
					text: "Experiente",
					value: "experient",
					align: "center",
					width: 100,
					types: ["leads", "users"],
					skipNullCheck: true,
					format: experient => (experient ? "Sim" : "Não"),
				},
				{
					text: "Tipo de Experiência",
					value: "experienceTypes",
					align: "center",
					width: 100,
					types: ["leads"],
					format: experienceTypes =>
						experienceTypes.map(t => this.config.experienceTypes.find(t2 => t2.key === t)?.name).join(", "),
				},
				{
					text: "Experiência",
					value: "experience",
					align: "center",
					width: 100,
					types: ["leads"],
				},
				{
					text: "Fase",
					value: "stage",
					align: "center",
					width: 140,
					types: ["leads"],
					format: stage => this.config.stages.find(s => s.key === stage)?.name,
				},
				{
					text: "Duplicada",
					value: "duplicate",
					align: "center",
					width: 100,
					types: ["leads"],
					format: duplicate => (duplicate ? "Sim" : "Não"),
				},

				// Users
				{
					text: "Agências",
					value: "offices",
					width: 120,
					types: ["users"],
					format: offices => offices.map(o => this.allOffices.find(o2 => o2.key === o)?.name).join(", "),
				},
				{
					text: "Email",
					value: "email",
					align: "center",
					width: 140,
					types: ["users"],
				},
				{
					text: "Cargo",
					value: "role",
					align: "center",
					width: 140,
					types: ["users"],
					format: role => this.config.roles.find(r => r.key === role)?.name,
				},
				{
					text: "Responsável",
					value: "manager",
					align: "center",
					width: 140,
					types: ["users"],
					format: manager => manager?.name,
				},
				{
					text: "Data de Entrada",
					value: "startDate",
					align: "center",
					width: 100,
					types: ["users"],
					format: startDate => formatDate(startDate, "DD-MMM-YY"),
				},
				{
					text: "Data de Saída",
					value: "endDate",
					align: "center",
					width: 100,
					types: ["users"],
					format: endDate => formatDate(endDate, "DD-MMM-YY"),
				},
				{
					text: "Motivo de Saída",
					value: "contractEndReason",
					align: "center",
					width: 140,
					types: ["users"],
				},
				{
					text: "Full Time",
					value: "fullTime",
					align: "center",
					width: 100,
					types: ["users"],
					skipNullCheck: true,
					format: fullTime => (fullTime ? "Sim" : "Não"),
				},
				{
					text: "Integrador",
					value: "integration",
					align: "center",
					width: 140,
					types: ["users"],
					format: integration => integration?.name,
				},
				{
					text: "Referência",
					value: "referencedBy",
					align: "center",
					width: 140,
					types: ["users"],
					format: referencedBy => referencedBy?.name,
				},
				{
					text: "Equipa",
					value: "team",
					align: "center",
					width: 140,
					types: ["users"],
					format: team => this.config.teams.find(t => t.key === team)?.name,
				},
				{
					text: "Fatura",
					value: "invoice",
					align: "center",
					width: 100,
					types: ["users"],
					skipNullCheck: true,
					format: invoice => (invoice ? "Sim" : "Não"),
				},
				{
					text: "Comissão Bancária do Responsável",
					value: "commercialDirectorBankCommission",
					type: "number",
					align: "center",
					width: 140,
					types: ["users"],
					format: number => `${formatNumber(number, false, true, 0)}%`,
				},
				{
					text: "Comissão Bancária do Broker",
					value: "brokerCommission",
					type: "number",
					align: "center",
					width: 140,
					types: ["users"],
					format: number => `${formatNumber(number, false, true, 0)}%`,
				},
				{
					text: "Data do BEGIN",
					value: "vitamina21Date",
					align: "center",
					width: 100,
					types: ["users"],
					format: vitamina21Date => formatDate(vitamina21Date, "DD-MMM-YY"),
				},
				{
					text: "Data Final do BEGIN",
					value: "vitamina21EndDate",
					align: "center",
					width: 100,
					types: ["users"],
					format: vitamina21EndDate => formatDate(vitamina21EndDate, "DD-MMM-YY"),
				},
				{
					text: "Data da Integração",
					value: "integrationMeetingDate",
					align: "center",
					width: 100,
					types: ["users"],
					format: integrationMeetingDate => formatDate(integrationMeetingDate, "DD-MMM-YY"),
				},
				{
					text: "Data do Create21",
					value: "create21Date",
					align: "center",
					width: 100,
					types: ["users"],
					format: create21Date => formatDate(create21Date, "DD-MMM-YY"),
				},
				{
					text: "Ofertas",
					value: "offers",
					align: "center",
					width: 140,
					types: ["users"],
					format: offers => offers.map(o => this.config.offers.find(o2 => o2.key === o)?.name),
				},
				{
					text: "Tem Computador",
					value: "hasComputer",
					align: "center",
					width: 100,
					types: ["users"],
					skipNullCheck: true,
					format: hasComputer => (hasComputer ? "Sim" : "Não"),
				},
				{
					text: "Tem Telemóvel",
					value: "hasPhone",
					align: "center",
					width: 100,
					types: ["users"],
					skipNullCheck: true,
					format: hasPhone => (hasPhone ? "Sim" : "Não"),
				},
				{
					text: "Tem Veículo",
					value: "hasVehicle",
					align: "center",
					width: 100,
					types: ["users"],
					skipNullCheck: true,
					format: hasVehicle => (hasVehicle ? "Sim" : "Não"),
				},
				{
					text: "Nome Completo",
					value: "completeName",
					align: "center",
					width: 140,
					types: ["users"],
				},
				{
					text: "Qualificações",
					value: "qualifications",
					align: "center",
					width: 140,
					types: ["users"],
					format: qualifications => this.config.qualifications.find(q => q.key === qualifications)?.name,
				},
				{
					text: "Título de Residência",
					value: "residenceTitle",
					align: "center",
					width: 100,
					types: ["users"],
					skipNullCheck: true,
					format: residenceTitle => (residenceTitle ? "Sim" : "Não"),
				},
				{
					text: "Data de Validade do Título de Residência",
					value: "residenceExpirationDate",
					align: "center",
					width: 100,
					types: ["users"],
					format: residenceExpirationDate => formatDate(residenceExpirationDate, "DD-MMM-YY"),
				},
				{
					text: "Nº do Título de Residência",
					value: "compresidenceTitleNumberleteName",
					align: "center",
					width: 140,
					types: ["users"],
				},
				{
					text: "CC",
					value: "cc",
					align: "center",
					width: 140,
					types: ["users"],
				},
				{
					text: "Data de Validade do CC",
					value: "expirationDate",
					align: "center",
					width: 100,
					types: ["users"],
					format: expirationDate => formatDate(expirationDate, "DD-MMM-YY"),
				},
				{
					text: "NIF",
					value: "nif",
					align: "center",
					width: 140,
					types: ["users"],
				},
				{
					text: "NISS",
					value: "niss",
					align: "center",
					width: 140,
					types: ["users"],
				},
				{
					text: "IBAN",
					value: "iban",
					align: "center",
					width: 140,
					types: ["users"],
				},
			],
			filteredHeaders: [],
			timer: null,
			search: "",
			page: 0,
			perPage: 20,
			sortBy: "date",
			sortDesc: true,
			loading: false,
			expanded: [],
			selected: [],
			quickFilter: null,
			filterOptions: {
				// Invoices
				active: true,
				consultants: [],
				teams: [],
				commercialDirectors: [],
				creditManagers: [],
				recruiters: [],
				clientProofDate: "null",
				clientPaymentDate: "null",
				consultantPaymentDate: "null",
				sentBills: "null",
				isBank: "null",
				isExternal: "null",
				isShared: "null",

				// Reservations
				isValidated: "null",
				hasPredictedDate: "null",
				isExecuted: "null",
				impic: "null",
				moneyLaundering: "null",
				riskAnalysis: "null",
				predictedExecutionDateRange: [],
				executionDateRange: [],
				giveUpDateRange: [],
				impicDateRange: [],
				cpcvDateRange: [],
				deedDateRange: [],
				scheduledDateRange: [],
				internalCredit: "null",
				banks: [],
				hasBankCommissionDate: "null",
				address: "",
				postalCode: "",
				city: [],
				houseType: [],
				rooms: [],
				minArea: "",
				maxArea: "",

				// Findings
				contractType: [],
				contractDuration: "",
				regime: [],

				// Viabilities
				isGiveUp: "null",
				state: [],

				// Leads
				recruiter: [],
				stage: [],
				source: [],
				experient: "null",
				experienceTypes: [],

				// Users
				role: [],
				manager: [],
				startDateRange: [],
				gender: "null",
				languages: [],

				// Expenses
				category: [],
				subCategory: [],
				repeatsMonthly: "null",
			},
			filters: { active: true },

			showFilters: false,
			view: "items",
			consultants: [],
			commercialDirectors: [],
			creditManagers: [],
			recruiters: [],
			managers: [],
			countries: [],

			items: [],
			total: 0,
			totals: null,
			monthly: null,
			pipeline: null,

			showFinderPaymentDatePicker: false,
			showSellerPaymentDatePicker: false,
			showFinderBillsDatePicker: false,
			showSellerBillsDatePicker: false,

			finder: "",
			isExternalFinder: false,
			sentBillsToFinder: null,
			finderPaymentDate: null,
			seller: "",
			isExternalSeller: false,
			sentBillsToSeller: null,
			sellerPaymentDate: null,
			clientProofDate: null,
			clientPaymentDate: null,

			giveUpDate: "",
			impicDate: "",

			salaryReceipts: null,

			showDetail: false,
			showComments: false,
			showTasks: false,
			showFiles: false,
			showChangeLog: false,
			showTimeline: false,

			showTeams: false,

			selectedItem: null,

			batchFinderPercentage: null,
			batchFinderCommercialDirectorCommission: null,
			batchFinderBrokerCommission: null,
			batchFinderCooCommission: null,
			batchFinderRecruiterPercentage: null,
			batchFinderRecruiterDirectorPercentage: null,
			batchSellerPercentage: null,
			batchSellerCommercialDirectorCommission: null,
			batchSellerBrokerCommission: null,
			batchSellerCooCommission: null,
			batchSellerRecruiterPercentage: null,
			batchSellerRecruiterDirectorPercentage: null,
			batchCreditManagerCommission: null,
			commissionsEdit: {},
			editLength: 0,
			editLoading: false,

			profile: null,
			columns: [],
			expandedColumns: [],

			maxWidth: 0,
			maxHeight: 0,
		};
	},
	mounted() {
		window.onresize = this.setMaxHeight;
	},
	methods: {
		...mapMutations(["openToast", "login", "setProfiles"]),
		formatDate,
		formatNumber,
		formatOwner,
		formatAvatarName,
		reload() {
			window.location.reload();
		},
		getAutocompleteItems(value) {
			if (value.includes(".")) {
				const splitValue = value.split(".");

				return this[splitValue[0]][splitValue[1]];
			}

			return this[value];
		},
		evaluateCondition(condition) {
			if (Array.isArray(condition)) return this[condition[0]] === condition[1];

			return condition;
		},
		getColspan() {
			let colspan = 1;
			if (this.canCopy(true)) colspan++;

			return colspan;
		},
		formatRef(ref) {
			if (ref === "Bank") return ref;

			const officeRefs = this.allOffices.map(o => o.ref);

			return ref ? (officeRefs.includes(ref.split("-")[0]) ? ref.split("-")[1].replace(/^0+/, "") : "Ext") : "";
		},
		canCopy(canSelect) {
			return canSelect
				? this.filterOptions.consultants.length === 1
				: this.selected.length && this.filterOptions.consultants.length === 1;
		},
		isClosed(item) {
			return [
				"toAssign",
				"recruiterRejected",
				"contactsContactedByRecruiter",
				"contactsContactedByLead",
				"interviewDoneByRecruiter",
				"interviewDoneByLead",
				"presentationDoneByRecruiter",
				"presentationDoneByLead",
				"approvalDoneByRecruiter",
				"approvalDoneByLead",
				"recruited",
			].includes(item.stage);
		},
		formatCommercialDirector(commercialDirector) {
			if (!commercialDirector) return;

			const splitName = commercialDirector.name.split(" ");

			return splitName.length > 1
				? `${splitName[0][0].toUpperCase()}${splitName[1][0].toUpperCase()}`
				: splitName[0][0].toUpperCase();
		},
		formatDateRange(value) {
			const dateRange = JSON.parse(JSON.stringify(value));
			dateRange.sort((a, b) => new Date(a) - new Date(b));

			return dateRange.map(d => formatDate(d, "DD-MMM")).join(" ~ ");
		},
		clearDateRange(field) {
			this.filterOptions[field] = [];

			this.handleSetFilters(field, this.filterOptions[field]);
		},
		handleDateFilter(field, date) {
			if (date.length === 2) {
				const dateRange = JSON.parse(JSON.stringify(date));
				dateRange.sort((a, b) => new Date(a) - new Date(b));

				this.handleSetFilters(field, dateRange);
			}
		},
		formatColumns() {
			let columns = this.columns;

			if (this.view === "percentages") {
				columns = [
					"finder",
					"finderPercentage",
					"finderCommercialDirector",
					"finderCommercialDirectorCommission",
					"finderBroker",
					"finderBrokerCommission",
					"finderCooCommission",
					"finderRecruiterPercentage",
					"finderRecruiterDirectorPercentage",
					"seller",
					"sellerPercentage",
					"sellerCommercialDirector",
					"sellerCommercialDirectorCommission",
					"sellerBroker",
					"sellerBrokerCommission",
					"sellerCooCommission",
					"sellerRecruiterPercentage",
					"sellerRecruiterDirectorPercentage",
					"creditManager",
					"creditManagerCommission",
					"actions",
				];
			}

			this.filteredHeaders = this.headers
				.filter(header => columns.includes(header.value))
				.sort((a, b) => columns.indexOf(a.value) - columns.indexOf(b.value));

			if (this.expandedColumns.length) {
				this.filteredHeaders.push({ text: "", value: "data-table-expand", width: 50 });
			} else {
				this.filteredHeaders = this.filteredHeaders.filter(h => h.value !== "data-table-expand");
			}

			this.maxWidth = this.filteredHeaders.reduce(
				(acc, h) => acc + (this.view === "percentages" ? h.width + 25 : h.width),
				500,
			);
		},
		formatExpanded(info, column) {
			const header = this.headers.find(h => h.value === column);

			if (header.type === "number") {
				return this.formatNumber(info);
			} else if (header.type === "date") {
				return this.formatDate(info, "DD-MM-YY");
			} else if (header.type === "name") {
				return info ? info.name : info;
			} else {
				return info;
			}
		},
		setMaxHeight() {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				const table = document.getElementsByClassName("v-data-table__wrapper")[0];
				const tableHead = document.getElementsByClassName("v-data-table-header")[0];
				const tableHeadHeight = tableHead.clientHeight;
				const isScrollable = table.scrollWidth > table.clientWidth;
				const totals = document.getElementById("totals");

				const rowsHeight = ((this.items.length || 1) + 1) * 48 + tableHeadHeight + (isScrollable ? 20 : 0);
				const maxHeight =
					window.innerHeight - (totals ? totals.clientHeight : 0) - 50 - (this.profileOverwrite ? 310 : 160);

				this.maxHeight = maxHeight > rowsHeight ? rowsHeight : maxHeight;

				if (this.maxHeight < 300) this.maxHeight = window.innerHeight - 195;
				if (this.maxHeight < 300) this.maxHeight = 500;
			}, 500);
		},
		async setProfile() {
			this.profile = this.profiles.find(p => p.active && p.type === this.type);

			if (this.profile) {
				this.columns = (this.profileOverwrite && this.profileOverwrite.columns) || this.profile.columns;
				this.expandedColumns = (this.profileOverwrite && this.profileOverwrite.expanded) || this.profile.expanded;

				this.filters =
					(this.profileOverwrite && { ...this.profileOverwrite.filters }) ||
					(this.profile.filters && { ...this.profile.filters });

				for (const filterOption in this.filterOptions) {
					this.filterOptions[filterOption] =
						filterOption in this.filters
							? this.filters[filterOption]
							: Array.isArray(this.filterOptions[filterOption])
							? []
							: ["address", "postalCode", "minArea", "maxArea"].includes(filterOption)
							? ""
							: "null";
				}

				// For when page changes but is not refreshed
				if (this.filters.search) this.search = this.filters.search;

				this.page = 0;
				this.handleGetItems();

				this.formatColumns();
			}
		},
		updateParent(key, value) {
			this[key] = value;
		},
		handleSetFilters(filter, filterValue, isNullable) {
			if (
				filterValue !== "null" &&
				(isNullable || filterValue !== null) &&
				(Array.isArray(filterValue) ? filterValue.length : true)
			) {
				this.filters[filter] = filterValue;
			} else {
				delete this.filters[filter];
			}

			this.page = 0;

			this.handleGetItems();
		},
		isDone(item) {
			return item.clientPaymentDate && this.isFinderDone(item) && this.isSellerDone(item);
		},
		isFinderDone(item) {
			return item.isExternalFinder ? item.clientPaymentDate : item.sentBillsToFinder && item.finderPaymentDate;
		},
		isSellerDone(item) {
			return item.isExternalSeller ? item.sellerPaymentDate : item.sentBillsToSeller && item.sellerPaymentDate;
		},
		setOptions(options) {
			if (
				this.perPage !== options.itemsPerPage ||
				this.sortBy !== options.sortBy ||
				this.sortDesc !== options.sortDesc
			) {
				this.page = 0;
			}

			this.page = options.page - 1;
			this.perPage = options.itemsPerPage;
			this.sortBy = options.sortBy[0];
			this.sortDesc = options.sortDesc[0];

			this.handleGetItems();
		},
		setSearch(value, key) {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this[key] = value;

				this.handleSetFilters(key, value ? value : null);
			}, 800);
		},
		setSelected(item) {
			if (item.items) {
				this.selected = item.value ? item.items.map(i => i._id) : [];
			} else {
				const itemExists = this.selected.find(s => s === item.item._id);
				if (!itemExists) {
					this.selected.push(item.item._id);
				} else {
					this.selected = this.selected.filter(s => s !== item.item._id);
				}
			}
		},
		async handleGetFilters() {
			this.loading = true;

			const response = await this.getFilters();

			if (response.status === 200) {
				this.cities = response.data.city;
				this.rooms = response.data.rooms;
			}

			this.loading = false;
		},
		async handleGetItems() {
			this.loading = true;

			const response = await this.getItems(this.page, this.perPage, this.sortBy, this.sortDesc, this.filters);

			if (response.status === 200) {
				this.items = response.data[this.type];
				this.total = response.data.total;
				this.totals = response.data.totals;
				this.monthly = response.data.monthly;
				this.pipeline = response.data.pipeline;

				this.setMaxHeight();
			}

			this.commissionsEdit = {};

			this.loading = false;
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { all: true, infoLevel: 2 });

			if (response.status === 200) {
				this.consultants = response.data.users;
				this.managers = response.data.users.filter(u =>
					["ceo", "cfo", "coo", "broker", "commercialDirector", "teamLeader"].includes(u.role),
				);
				this.commercialDirectors = response.data.users.filter(u =>
					["coo", "broker", "commercialDirector", "teamLeader"].includes(u.role),
				);
				this.creditManagers = response.data.users.filter(
					u => u.active && (u.role === "creditManager" || u.otherRoles?.includes("creditManager")),
				);
				this.recruiters = response.data.users.filter(
					u =>
						u.active && ["recruiter", "expansionDirector", "commercialDirector", "broker", "ceo"].includes(u.role),
				);
			}
		},
		openDetail(item) {
			if (item) {
				this.selectedItem = this.items.find(i => i._id === item._id);
			} else {
				this.selectedItem = null;
			}

			this.showDetail = true;
		},
		async updateDetail() {
			await this.handleGetItems();

			this.selectedItem = this.items.find(i => i._id === this.selectedItem._id);
		},
		closeDetail() {
			this.showDetail = false;
			this.showTimeline = false;

			this.handleGetItems();
		},
		openAction(item, action) {
			this.selectedItem = this.items.find(i => i._id === item._id);

			this[`show${action}`] = true;
		},
		copyTable(data, hideTotals) {
			let lines = "";

			const headersLine = this.filteredHeaders.map(h => `<th>${h.text}</th>`).join("");

			for (const item of data) {
				lines += "<tr>";

				for (const header of this.filteredHeaders) {
					lines += `<td align="${header.align}" ${header.getStyles ? `style="${header.getStyles(item)}"` : ""}>
						${
							header.skipNullCheck
								? header.format(item[header.value], item)
								: item[header.value]
								? header.format
									? header.format(item[header.value], item)
									: item[header.value]
								: ""
						}
					</td>`;
				}

				lines += "</tr>";
			}

			const totals = this.totals
				? this.filteredHeaders
						.map(
							h =>
								`<td align="${h.align}">
										<b>${
											this.totals && (h.type === "number" || h.value === "clientPaymentDate")
												? formatNumber(this.totals[h.value])
												: ""
										}</b>
								</td>`,
						)
						.join("")
				: "";

			copy(
				`<style>
					table, th, td { border: 1px solid black; border-collapse: collapse; font-size: 0.9em }
				</style>
				<table>
					<tr>${headersLine}</tr>
					${lines}
					${hideTotals || !totals ? "" : `<tr>${totals}</tr>`}
				</table>`,
				{
					asHtml: true,
					format: "text/html",
				},
			);
		},
		copyLine(id) {
			const ids = id ? [id] : this.selected;

			const data = this.items.filter(i => ids.includes(i._id));

			this.copyTable(data, true);
		},
		async copyAll() {
			const response = await this.getItems(0, 1000, this.sortBy, this.sortDesc, this.filters);

			if (response.status !== 200) this.openToast({ message: "Erro no pedido", color: "red" });

			this.copyTable(response.data[this.type]);
		},
		setItem(id) {
			const item = this.items.find(i => i._id === id);

			if (this.type === "invoices") {
				let foundBank = null;
				if (item.ref === "Bank") {
					foundBank = this.config.banks.find(c => c._id === item.finder.name);
				}

				this.finder = foundBank ? foundBank : item.finder ? item.finder : "";
				this.isExternalFinder = item.isExternalFinder;
				this.sentBillsToFinder = item.sentBillsToFinder && formatDate(item.sentBillsToFinder, "YYYY-MM-DD");
				this.finderPaymentDate = item.finderPaymentDate && formatDate(item.finderPaymentDate, "YYYY-MM-DD");
				this.seller = item.seller ? item.seller : "";
				this.isExternalSeller = item.isExternalSeller;
				this.sentBillsToSeller = item.sentBillsToSeller && formatDate(item.sentBillsToSeller, "YYYY-MM-DD");
				this.sellerPaymentDate = item.sellerPaymentDate && formatDate(item.sellerPaymentDate, "YYYY-MM-DD");
				this.clientProofDate = item.clientProofDate && formatDate(item.clientProofDate, "YYYY-MM-DD");
				this.clientPaymentDate = item.clientPaymentDate && formatDate(item.clientPaymentDate, "YYYY-MM-DD");
			} else if (this.type === "reservations") {
				this.giveUpDate = item.giveUpDate && formatDate(item.giveUpDate, "YYYY-MM-DD");
				this.impicDate = item.impicDate && formatDate(item.impicDate, "YYYY-MM-DD");
			}
		},
		async handlePatchItem(item) {
			const {
				finder,
				sentBillsToFinder,
				finderPaymentDate,
				seller,
				sentBillsToSeller,
				sellerPaymentDate,
				clientProofDate,
				clientPaymentDate,
				giveUpDate,
				impicDate,
			} = this;

			const update = { _id: item._id };

			if (this.type === "invoices") {
				if (finder && (finder !== item.finder || finder._id !== item.finder._id)) {
					update.finder = finder._id || finder.name || finder;
					update.isExternalFinder = !finder._id || item.ref === "Bank";
					update.finderPercentage = finder.commission;
					update.finderTeam = finder.team || null;
					update.finderCommercialDirector = finder.manager || null;
					update.finderCommercialDirectorCommission = finder.commercialDirectorCommission;
					update.finderBrokerCommission = finder.brokerCommission;
					update.finderCooCommission = finder.cooCommission;
					update.finderRecruiterPercentage = finder.recruiterCommission;
					update.finderRecruiterDirectorPercentage = finder.recruiterDirectorCommission;
				}

				if (sentBillsToFinder !== item.sentBillsToFinder) update.sentBillsToFinder = sentBillsToFinder;
				if (!item.finderPaymentDate || dayjs(finderPaymentDate).diff(dayjs(item.finderPaymentDate))) {
					update.finderPaymentDate = sentBillsToFinder ? finderPaymentDate : null;
				}

				if (seller && (seller !== item.seller || seller._id !== item.seller._id)) {
					update.seller = seller._id || seller.name || seller;
					update.isExternalSeller = !seller._id;
					update.sellerPercentage = item.ref === "Bank" ? seller.bankCommission : seller.commission;
					update.sellerTeam = seller.team || null;
					update.sellerCommercialDirector = seller.manager || null;
					update.sellerCommercialDirectorCommission =
						item.ref === "Bank" && seller.commercialDirectorBankCommission
							? seller.commercialDirectorBankCommission
							: seller.commercialDirectorCommission;
					update.sellerBrokerCommission = seller.brokerCommission;
					update.sellerCooCommission = seller.cooCommission;
					update.sellerRecruiterPercentage = seller.recruiterCommission;
					update.sellerRecruiterDirectorPercentage = seller.recruiterDirectorCommission;
					update.creditManagerCommission = seller.creditManagerCommission;
				}

				if (sentBillsToSeller !== item.sentBillsToSeller) update.sentBillsToSeller = sentBillsToSeller;
				if (!item.sellerPaymentDate || dayjs(sellerPaymentDate).diff(dayjs(item.sellerPaymentDate))) {
					update.sellerPaymentDate = (seller._id && sentBillsToSeller) || !seller._id ? sellerPaymentDate : null;
				}

				if (!item.clientProofDate || dayjs(clientProofDate).diff(dayjs(item.clientProofDate))) {
					update.clientProofDate = clientProofDate;
				}
				if (!item.clientPaymentDate || dayjs(clientPaymentDate).diff(dayjs(item.clientPaymentDate))) {
					update.clientPaymentDate = clientPaymentDate;
				}
			} else if (this.type === "reservations") {
				if (giveUpDate) update.giveUpDate = giveUpDate;
				if (impicDate) update.impicDate = impicDate;
			}

			if (Object.keys(update).length > 1) {
				const response = await this.patchItem(update);

				if (response.status === 200) {
					this.handleGetItems();
				}
			}
		},
		async handleDeleteItem(item) {
			this.loading = true;

			const response = await this.deleteItem(item._id);

			if (response.status === 200) {
				this.handleGetItems();

				this.loading = false;
			}
		},
		async onDialogSubmit() {
			await this.handleGetItems();

			this.selectedItem = this.items.find(r => r._id === this.selectedItem._id);
		},
		async onCommentSubmit(increment) {
			this.selectedItem.comments += increment;
		},
		async handleGenerateGmailDrafts() {
			this.loading = true;

			const response = await generateGmailDrafts();

			if (response.status === 401) {
				this.login({ user: { ...this.user, gmailRefreshToken: false } });
			} else if (response.status === 400) {
				this.openToast({ message: "Escolha uma agência", color: "red" });
			}

			this.loading = false;
		},
		async handleGenerateDCGmailDrafts() {
			this.loading = true;

			const response = await generateDCGmailDrafts();

			if (response.status === 401) {
				this.login({ user: { ...this.user, gmailRefreshToken: false } });
			}

			this.loading = false;
		},
		chooseFiles() {
			document.getElementById("fileUpload").click();
		},
		async handleGenerateSalaryReceipts(e) {
			const formData = new FormData();
			for (const file of e.target.files) {
				formData.append("files", file);
			}

			this.loading = true;

			const response = await generateSalaryReceipts(formData);

			if (response.status === 401) {
				this.login({ user: { ...this.user, gmailRefreshToken: false } });
			}

			this.loading = false;
		},
		async handleAddInvoice(item) {
			this.loading = true;

			const response = await generateInvoice(item._id);

			if (response.status === 200) {
				await this.handleGetItems();

				this.openToast({ message: "Fatura criada com sucesso", color: "success" });
			} else {
				this.openToast({
					message:
						response.message === "REQUIRED_FIELDS_RESERVATION"
							? "Falta referência ou angariador ou vendedor"
							: response.message === "REQUIRED_FIELDS_OWNER"
							? "Falta o nome ou o nif do proprietário ou comprador"
							: response.message === "REQUIRED_FIELDS_EXTERNAL"
							? "Falta campos da agência de partilha"
							: "O NIF não é válido",
					color: "red",
				});
			}

			this.loading = false;
		},
		changeAllCommissions(type, commission) {
			for (const item of this.items) {
				const _id = item._id;

				const old = item[type];
				item[type] = commission;

				this.commissionsEdit[_id] = this.commissionsEdit[_id]
					? { ...this.commissionsEdit[_id], [type]: commission, [`${type}__old`]: old }
					: { [type]: commission, [`${type}__old`]: old };
			}

			this.editLength = Object.keys(this.commissionsEdit).length;
		},
		changeCommission(item, type, commission) {
			this.commissionsEdit[item._id] = this.commissionsEdit[item._id]
				? { ...this.commissionsEdit[item._id], [type]: commission, [`${type}__old`]: item[type] }
				: { [type]: commission, [`${type}__old`]: item[type] };
			this.editLength = Object.keys(this.commissionsEdit).length;
		},
		async handleBatchEdit() {
			this.editLoading = true;

			let edit = this.commissionsEdit;

			const response = await patchInvoices(edit);

			if (response.status === 200) {
				await this.handleGetItems();

				this.openToast({ message: "Faturas editadas", color: "success" });
				this.commissionsEdit = {};
				this.editLength = 0;
			}

			this.editLoading = false;
		},
		async handleExportUsers() {
			this.loading = true;

			const response = await exportUsers();

			if (response.status === 200) {
				window.open(`${process.env.VUE_APP_API_URL}/api/files/download/users.csv`, "_blank");
			}

			this.loading = false;
		},
		async handleImportUsers(e) {
			const formData = new FormData();
			for (const file of e.target.files) {
				formData.append("files", file);
			}

			this.loading = true;

			await importUsers(formData);

			this.loading = false;
		},
		async handleGeneratePDF(item) {
			const pdfDoc = await PDFDocument.create();

			const pages = [
				[
					[
						{ name: "Tipo de Negócio", value: "dealType" },
						{ name: "Estado", value: "state" },
						{ name: "Nº de Processo", value: "number" },
						{ name: "Angariador", value: "finder" },
						{ name: "Vendedor", value: "seller" },
						{
							name: item.dealType === "renting" ? "Senhorios" : "Proprietários",
							value: "owners",
							fields: [
								{ name: "Nome", value: "name" },
								{ name: "ID de Prospeção", value: "prospectingNumber" },
								{ name: "Morada", value: "address" },
								{ name: "Nº de Telefone", value: "phone" },
								{ name: "Email", value: "email" },
								{
									name: "Data de Nascimento",
									value: "birthday",
									format: birthday => formatDate(birthday, "DD-MM-YY"),
								},
								{ name: "NIF", value: "nif" },
								{ name: "Documento de Identificação", value: "cc" },
								{ name: "Data de Validade do Documento", value: "ccExpirationDate" },
								{ name: "IBAN", value: "iban" },
								{ name: "Naturalidade", value: "naturality" },
								{
									name: "Estado Civil",
									value: "maritalStatus",
									format: maritalStatus => this.config.maritalStatusTypes.find(t => t.key === maritalStatus)?.name,
								},
								{
									name: "Regime de Casamento",
									value: "marriageRegime",
									format: marriageRegime =>
										this.config.marriageRegimeTypes.find(t => t.key === marriageRegime)?.name,
								},
								{ name: "Profissão", value: "job" },
								{ name: "Entidade Empregadora", value: "company" },
								{ name: "Percentagem", value: "percentage" },
							],
						},
						{
							name: "Fiadores",
							value: "guarantors",
							fields: [
								{ name: "Nome", value: "name" },
								{ name: "ID de Prospeção", value: "prospectingNumber" },
								{ name: "Morada", value: "address" },
								{ name: "Nº de Telefone", value: "phone" },
								{ name: "Email", value: "email" },
								{
									name: "Data de Nascimento",
									value: "birthday",
									format: birthday => formatDate(birthday, "DD-MM-YY"),
								},
								{ name: "NIF", value: "nif" },
								{ name: "Documento de Identificação", value: "cc" },
								{ name: "Data de Validade do Documento", value: "ccExpirationDate" },
								{ name: "IBAN", value: "iban" },
								{ name: "Naturalidade", value: "naturality" },
								{
									name: "Estado Civil",
									value: "maritalStatus",
									format: maritalStatus => this.config.maritalStatusTypes.find(t => t.key === maritalStatus)?.name,
								},
								{
									name: "Regime de Casamento",
									value: "marriageRegime",
									format: marriageRegime =>
										this.config.marriageRegimeTypes.find(t => t.key === marriageRegime)?.name,
								},
								{ name: "Profissão", value: "job" },
								{ name: "Entidade Empregadora", value: "company" },
							],
							showIfRenting: true,
						},
					],
					[
						{ name: "Agência de Partilha", value: "externalOffice" },
						{ name: "Contacto da Agência de Partilha", value: "externalContact" },
						{ name: "Contacto DP da Agência de Partilha", value: "externalStaffContact" },
						{ name: "NIF da Agência de Partilha", value: "externalNif" },
						{ name: "Morada da Agência de Partilha", value: "externalAddress" },
						{
							name: item.dealType === "renting" ? "Arrendatários" : "Compradores",
							value: "buyers",
							fields: [
								{ name: "Nome", value: "name" },
								{ name: "ID de Prospeção", value: "prospectingNumber" },
								{ name: "Morada", value: "address" },
								{ name: "Nº de Telefone", value: "phone" },
								{ name: "Email", value: "email" },
								{
									name: "Data de Nascimento",
									value: "birthday",
									format: birthday => formatDate(birthday, "DD-MM-YY"),
								},
								{ name: "NIF", value: "nif" },
								{ name: "Documento de Identificação", value: "cc" },
								{ name: "Data de Validade do Documento", value: "ccExpirationDate" },
								{ name: "IBAN", value: "iban" },
								{ name: "Naturalidade", value: "naturality" },
								{
									name: "Estado Civil",
									value: "maritalStatus",
									format: maritalStatus => this.config.maritalStatusTypes.find(t => t.key === maritalStatus)?.name,
								},
								{
									name: "Regime de Casamento",
									value: "marriageRegime",
									format: marriageRegime =>
										this.config.marriageRegimeTypes.find(t => t.key === marriageRegime)?.name,
								},
								{ name: "Profissão", value: "job" },
								{ name: "Entidade Empregadora", value: "company" },
							],
						},
					],
				],
				[
					[
						{ name: "Referência", value: "ref" },
						{ name: "Morada", value: "address" },
						{ name: "Código Postal", value: "postalCode" },
						{ name: "Cidade", value: "city" },
						{ name: "Tipo de Imóvel", value: "houseType" },
						{ name: "Área Útil", value: "area" },
						{ name: "Tipologia", value: "rooms" },
						{ name: "Condomínio", value: "condominium" },
						{ name: "Certidão Registo Predial", value: "predialRegistrationCertificate" },
						{ name: "Caderneta Predial", value: "predialBooklet" },
						{ name: "Licença de Utilização", value: "usageLicense" },
						{ name: "Certidão de Isenção LU (1951)", value: "luExemptionCertificate" },
						{ name: "Certidão de Infraestruturas (1ª Transmissão)", value: "infrastructureCertificate" },
						{ name: "Ficha Técnica de Habitação (FTH)", value: "housingTechnicalSheet" },
						{ name: "Certificado Energético", value: "energeticCertificate" },
						{ name: "Planta do Imóvel", value: "propertyPlan" },
						{ name: "KYC", value: "kyc" },
						{ name: "Direitos de Preferência", value: "preferenceRights" },
						{ name: "Destino do Imóvel", value: "purpose", showIfNotRenting: true },
						{ name: "Data de Entrada", value: "reservationDate" },
						{ name: "Previsão", value: "predictedExecutionDate" },
						{ name: "Faturado", value: "executionDate" },
						{ name: "Valor Previsão", value: "predictedValue" },
						{ name: "Valor Faturado", value: "executedValue" },
						{ name: "Valor por Faturar", value: "pipeline" },
					],
					[
						{
							name: item.dealType === "renting" ? "Valor da Renda Mensal" : "Valor de Venda",
							value: "houseValue",
						},
						{ name: "Duração do Arrendamento", value: "rentingDuration", showIfRenting: true },
						{ name: "Número de Rendas", value: "numberOfRents", showIfRenting: true },
						{ name: "Número de Cauções", value: "numberOfDeposits", showIfRenting: true },
						{ name: "Valor do Sinal", value: "signalValue", showIfNotRenting: true },
						{ name: "Percentagem do Negócio", value: "officePercentage", showIfNotRenting: true },
						{ name: "Percentagem Interna", value: "percentageSplit" },
						{ name: "Comissão Total", value: "value" },
						{ name: "Data de Agendamento", value: "scheduledDate", showIfNotRenting: true },
						{ name: "Gestora de Crédito", value: "creditManager", showIfNotRenting: true },
						{ name: "Intermediação", value: "internalCredit", showIfNotRenting: true },
						{ name: "Valor do Financiamento", value: "creditValue", showIfNotRenting: true },
						{ name: "Banco", value: "creditBank", showIfNotRenting: true },
						{ name: "Valor de Avaliação", value: "appraisal", showIfNotRenting: true },
						{ name: "Data de Avaliação", value: "appraisalDate", showIfNotRenting: true },
						{ name: "Comissão Bancária", value: "bankCommission", showIfNotRenting: true },
						{ name: "Data de Extrato", value: "bankCommissionDate", showIfNotRenting: true },
						{ name: "Seguro", value: "insurance" },
						{ name: "Observações", value: "observations" },
					],
				],
			];

			const fontSize = 10;
			let isFirstPage = true;
			for (const page of pages) {
				const pdfPage = pdfDoc.addPage();

				let { width, height } = pdfPage.getSize();

				if (isFirstPage) {
					pdfPage.drawRectangle({
						x: 10,
						y: height - 75,
						width: width - 20,
						height: 65,
						borderWidth: 2,
						borderColor: rgb(0.75, 0.68, 0.53),
						color: rgb(0.75, 0.68, 0.53),
						opacity: 0.5,
					});

					pdfPage.drawRectangle({
						x: 10,
						y: height - 165,
						width: width / 2 - 15,
						height: 80,
						borderWidth: 1,
						borderColor: rgb(0, 0, 0),
					});

					pdfPage.drawRectangle({
						x: width / 2 + 5,
						y: height - 165,
						width: width / 2 - 15,
						height: 80,
						borderWidth: 1,
						borderColor: rgb(0, 0, 0),
					});

					pdfPage.drawRectangle({
						x: 10,
						y: height - 835,
						width: width / 2 - 15,
						height: height - 200,
						borderWidth: 1,
						borderColor: rgb(0, 0, 0),
					});

					pdfPage.drawRectangle({
						x: width / 2 + 5,
						y: height - 835,
						width: width / 2 - 15,
						height: height - 200,
						borderWidth: 1,
						borderColor: rgb(0, 0, 0),
					});

					pdfPage.drawText("Ficha de Processo", { x: width / 2 - 100, y: height - 52, size: 25 });
				} else {
					pdfPage.drawRectangle({
						x: 10,
						y: height - 835,
						width: width / 2 - 15,
						height: height - 37,
						borderWidth: 1,
						borderColor: rgb(0, 0, 0),
					});

					pdfPage.drawRectangle({
						x: width / 2 + 5,
						y: height - 835,
						width: width / 2 - 15,
						height: height - 37,
						borderWidth: 1,
						borderColor: rgb(0, 0, 0),
					});

					pdfPage.drawRectangle({
						x: 10,
						y: height - 30,
						width: width / 2 - 15,
						height: 20,
						borderWidth: 2,
						borderColor: rgb(0.75, 0.68, 0.53),
						color: rgb(0.75, 0.68, 0.53),
						opacity: 0.5,
					});

					pdfPage.drawText("Imóvel", { x: width / 4 - 20, y: height - 25, size: 14 });

					pdfPage.drawRectangle({
						x: width / 2 + 5,
						y: height - 30,
						width: width / 2 - 15,
						height: 20,
						borderWidth: 2,
						borderColor: rgb(0.75, 0.68, 0.53),
						color: rgb(0.75, 0.68, 0.53),
						opacity: 0.5,
					});

					pdfPage.drawText("Negócio", { x: width / 2 + 120, y: height - 25, size: 14 });
				}

				for (let i = 0; i < page.length; i++) {
					const column = page[i];
					let h = isFirstPage ? 85 : 30;

					const x = i > 0 ? 15 + width / 2 : 20;

					for (const field of column) {
						if (
							(!field.showIfRenting && !field.showIfNotRenting) ||
							(item.dealType === "renting" && field.showIfRenting) ||
							(item.dealType !== "renting" && field.showIfNotRenting)
						) {
							const header = this.headers.find(h => h.value === field.value);

							if (field.fields) {
								h += 30;

								pdfPage.drawRectangle({
									x: x - 10,
									y: height - h - 4,
									width: width / 2 - 15,
									height: 20,
									borderWidth: 2,
									borderColor: rgb(0.75, 0.68, 0.53),
									color: rgb(0.75, 0.68, 0.53),
									opacity: 0.5,
								});

								pdfPage.drawText(field.name, { x: x + width / 4 - 55, y: height - h + 2, size: 14 });

								h += 5;

								for (let j = 0; j < item[field.value].length; j++) {
									const element = item[field.value][j];

									for (const inField of field.fields) {
										h += 15;

										pdfPage.drawText(
											`${inField.name}: ${
												inField.format ? inField.format(element[inField.value]) : element[inField.value] || ""
											}`,
											{
												x,
												y: height - h,
												size: fontSize,
											},
										);
									}

									if (item[field.value].length > 1 && j !== item[field.value].length - 1) {
										h += 15;
										pdfPage.drawLine({
											start: { x, y: height - h },
											end: { x: x + 265, y: height - h },
											thickness: 1,
										});
									}
								}
							} else {
								h += 15;

								pdfPage.drawText(
									`${field.name}: ${
										item[field.value] || item[field.value] === false || header?.skipNullCheck
											? header?.format
												? header.format(item[field.value], item)
												: item[field.value]
											: ""
									}`,
									{ x, y: height - h, size: fontSize },
								);
							}
						}
					}
				}

				isFirstPage = false;
			}

			const pdfBytes = await pdfDoc.save();

			FileSaver.saveAs(new Blob([pdfBytes], { type: "application/pdf" }), "reservation.pdf");
		},
	},
});
</script>
