import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/pt";

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

function formatDate(date, format, relative = false, originalFormat) {
	return relative ? dayjs(date).fromNow() : dayjs(date, originalFormat).locale("pt").format(format);
}

function diff(date1, date2, unit) {
	return dayjs(date1).diff(dayjs(date2), unit);
}

function formatNumber(number, currency, isForExcel, toFixed = 2, showZeroes = false) {
	if ((!number || number == 0 || isNaN(number)) && !showZeroes) return "";
	if (!number && showZeroes) number = 0;

	const splitNumber = number.toFixed(toFixed).toString().split(".");
	const formattedNumber = splitNumber[0].replace(/(.)(?=(\d{3})+$)/g, "$1 ");

	let finalNumber = splitNumber[1] ? `${formattedNumber}.${splitNumber[1]}` : formattedNumber;

	if (isForExcel) finalNumber = finalNumber.replace(".", ",");

	return currency ? `${finalNumber} €` : finalNumber;
}

function formatAvatarName(name) {
	const splitName = name.split(" ").filter(n => n);

	return splitName.length > 1
		? `${splitName[0][0].toUpperCase()}${splitName[1][0].toUpperCase()}`
		: splitName[0][0].toUpperCase();
}

function formatOwner(owner) {
	if (!owner) return "";

	const splitOwner = owner.trim().split(" ");

	if (splitOwner.includes("e") || splitOwner.includes("&")) {
		if (["e", "&"].includes(splitOwner[1])) {
			return `${splitOwner[0]} e ${splitOwner[2]}`;
		} else {
			return `${splitOwner[0]} e ${splitOwner[3]}`;
		}
	} else {
		return splitOwner.length === 1 ? splitOwner[0] : `${splitOwner[0]} ${splitOwner[splitOwner.length - 1]}`;
	}
}

function validateCC(cc) {
	function isNumber(char) {
		var asciiVal = char.charCodeAt(0);
		return asciiVal >= 48 && asciiVal <= 57;
	}

	function getNumberFromChar(letter) {
		if (isNumber(letter)) {
			return parseInt(letter);
		} else {
			return letter.charCodeAt(0) - 55;
		}
	}

	var sum = 0;
	var secondDigit = false;

	for (let i = cc.length - 1; i >= 0; i--) {
		var valor = getNumberFromChar(cc.charAt(i));
		if (secondDigit) {
			valor *= 2;
			if (valor > 9) {
				valor -= 9;
			}
		}

		sum += valor;
		secondDigit = !secondDigit;
	}

	return sum % 10 == 0 ? 1 : 0;
}

function getFileUrl(file) {
	// ?${Date.now()} to refresh image

	try {
		return file.includes(`https://${this.company}.s3`)
			? `${this.apiUrl}/api/files/${this.company}/${file}?app=tipy&token=${this.token}`
			: file;
	} catch (err) {
		return null;
	}
}

function getFileName(file) {
	try {
		return file.includes(`https://${this.company}.s3`)
			? decodeURI(file.split("/")[file.split("/").length - 1])
			: file;
	} catch (err) {
		return null;
	}
}

export { formatDate, diff, formatNumber, formatAvatarName, formatOwner, validateCC, getFileName, getFileUrl };
