<template>
	<DataTable
		type="reservations"
		:featuresMap="{
			add: features.addReservation,
			edit: features.editReservation,
			delete: features.deleteReservation,
			addFiles: true,
			addInvoice: features.addInvoice,
			listTotals: features.listReservationTotals,
		}"
		title="Processos"
		:filtersConfig="filtersConfig"
		:getItems="getReservations"
		:getFilters="getReservationsFilters"
		:patchItem="patchReservation"
		:deleteItem="deleteReservation"
		:actions="['comments', 'tasks', 'files', 'addInvoice', 'changes', 'view', 'download', 'edit', 'delete']"
		:profileOverwrite="profileOverwrite"
		sortByOverwrite="reservationDate"
	>
		<template v-slot:totals="{ pipeline, totals }">
			<ReservationTotals id="totals" :pipeline="pipeline" :totals="totals" />
		</template>
		<template v-slot:detail="{ selectedItem, updateDetail, closeDetail }">
			<ReservationForm :reservation="selectedItem" :onUpdate="updateDetail" :onClose="closeDetail" />
		</template>
	</DataTable>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

import DataTable from "../components/DataTable.vue";
import ReservationTotals from "../components/ReservationTotals.vue";
import ReservationForm from "../components/ReservationForm";

import { getReservations, getReservationsFilters, patchReservation, deleteReservation } from "../api/reservations";

export default Vue.extend({
	name: "Reservations",
	props: ["profileOverwrite"],
	components: { DataTable, ReservationTotals, ReservationForm },
	computed: mapState(["features"]),
	data() {
		return {
			filtersConfig: [
				{ type: "autocomplete", label: "Consultores", value: "consultants", condition: !this.profileOverwrite },
				{
					type: "autocomplete",
					label: "Equipas",
					value: "teams",
					items: "config.teams",
					itemValue: "key",
					condition: !this.profileOverwrite,
				},
				{
					type: "autocomplete",
					label: "Diretores Comerciais",
					value: "commercialDirectors",
					condition: !this.profileOverwrite,
				},
				{ type: "divider" },
				{
					type: "autocomplete",
					label: "Estado",
					value: "state",
					items: "config.reservationStates",
					itemValue: "key",
				},
				{
					type: "buttonToggle",
					value: "hasPredictedDate",
					options: [
						{ value: false, label: "Sem Previsão" },
						{ value: "null", label: "Tudo" },
						{ value: true, label: "Com Previsão" },
					],
				},
				{
					type: "buttonToggle",
					value: "isExecuted",
					options: [
						{ value: "toExecute", label: "Por Faturar" },
						{ value: "executed", label: "Faturado" },
						{ value: "null", label: "Tudo" },
						{ value: "giveUp", label: "Desistências" },
					],
				},
				{
					type: "buttonToggle",
					value: "impic",
					options: [
						{ value: true, label: "Com IMPIC" },
						{ value: "null", label: "Tudo" },
						{ value: false, label: "Sem IMPIC" },
					],
				},
				{
					type: "buttonToggle",
					label: "Branqueamento de Capitais",
					value: "moneyLaundering",
					options: [
						{ value: "ok", label: "OK" },
						{ value: "null", label: "Tudo" },
						{ value: "missing", label: "Em Falta" },
						{ value: "notApplicable", label: "Não Aplicável" },
					],
					condition: ["company", "realtyart"],
				},
				{
					type: "buttonToggle",
					label: "Análise de Risco",
					value: "riskAnalysis",
					options: [
						{ value: "ok", label: "OK" },
						{ value: "null", label: "Tudo" },
						{ value: "missing", label: "Em Falta" },
						{ value: "notApplicable", label: "Não Aplicável" },
					],
					condition: ["company", "realtyart"],
				},
				{ type: "date", label: "Data de Previsão", value: "predictedExecutionDateRange" },
				{ type: "date", label: "Data de Faturação", value: "executionDateRange", twoCol: true },
				{ type: "date", label: "Data de Desistência", value: "giveUpDateRange", twoCol: true },
				{ type: "date", label: "Data de IMPIC", value: "impicDateRange" },
				{ type: "date", label: "Data de CPCV", value: "cpcvDateRange", twoCol: true },
				{ type: "date", label: "Data de Escritura", value: "deedDateRange", twoCol: true },
				{ type: "divider" },
				{ type: "autocomplete", label: "Gestoras de Crédito", value: "creditManager", items: "creditManagers" },
				{ type: "date", label: "Data de Agendamento", value: "scheduledDateRange" },
				{
					type: "buttonToggle",
					value: "internalCredit",
					options: [
						{ value: "yes", label: "Com Intermediação" },
						{ value: "no", label: "Sem Intermediação" },
						{ value: "byClient", label: "Tratado pelo Cliente" },
						{ value: "paidInFull", label: "Pronto Pagamento" },
						{ value: "null", label: "Tudo" },
					],
					vertical: true,
				},
				{ type: "autocomplete", label: "Bancos", value: "creditBank", items: "config.banks", itemValue: "key" },
				{
					type: "buttonToggle",
					value: "hasBankCommissionDate",
					options: [
						{ value: true, label: "Extrato Recebido" },
						{ value: "null", label: "Tudo" },
						{ value: false, label: "Extrato Por Receber" },
					],
				},
				{ type: "divider" },
				{ type: "textField", label: "Morada", value: "address" },
				{ type: "textField", label: "Código Postal", value: "postalCode", twoCol: true },
				{
					type: "autocomplete",
					label: "Cidade",
					value: "city",
					items: "cities",
					itemText: "_id",
					itemValue: "_id",
					twoCol: true,
				},
				{
					type: "autocomplete",
					label: "Tipo de Imóvel",
					value: "houseType",
					items: "config.houseTypes",
					itemValue: "key",
				},
				{ type: "autocomplete", label: "Tipologia", value: "rooms", itemText: "_id", itemValue: "_id" },
				{ type: "textField", label: "Área mínima", value: "minArea", textType: "number", twoCol: true },
				{ type: "textField", label: "Área máxima", value: "maxArea", textType: "number", twoCol: true },

				// TODO: two column row support
			],
		};
	},
	methods: { getReservations, getReservationsFilters, patchReservation, deleteReservation },
});
</script>
