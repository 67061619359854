<style>
.statContainer {
	padding: 5px;
	display: flex;
	gap: 10px;
	justify-content: space-between;
	align-items: center;
}
</style>

<template>
	<div width="100%" style="display: flex; flex-wrap: wrap; gap: 10px; justify-content: center">
		<v-card v-for="card in cards" :key="`${card.stat}${today?.toString()}`" min-width="450px">
			<v-card-text
				style="padding: 10px; text-align: left; display: flex; flex-direction: column; gap: 10px; height: 100%"
			>
				<div v-for="period in periods" :key="period.key">
					<div
						v-if="stats && stats[period.key]"
						:style="`background-color: ${
							$vuetify.theme.dark ? '#333' : '#f2f2f2'
						}; border-radius: 5px; padding: 5px;`"
					>
						<div class="statContainer">
							<span style="display: flex; gap: 5px; align-items: center">
								<v-chip style="border-radius: 3px">{{ period.name }}</v-chip>
								<v-tooltip top color="#222">
									<template v-slot:activator="{ on, attrs }">
										<span v-bind="attrs" v-on="on" style="font-size: 1.75em">
											{{
												formatNumber(
													card.stat === "expenses"
														? stats[period.key].salesCost + stats[period.key].fixedCost
														: stats[period.key][card.stat || leadStat] || 0,
													card.isCurrency,
													false,
													card.isCurrency ? 2 : 0,
													true,
												)
											}}
										</span>
									</template>
									<span>Realizado</span>
								</v-tooltip>
								<span v-if="card.stat === 'findings' && stats[period.key].findingsValue">
									{{ `(${formatNumber(stats[period.key].findingsValue, true)})` }}
								</span>
								<v-tooltip v-if="stats[period.key][`${card.stat || leadStat}Goal`]" top color="#222">
									<template v-slot:activator="{ on, attrs }">
										<b v-bind="attrs" v-on="on" style="margin-right: 5px">
											/
											{{
												formatNumber(
													stats[period.key][`${card.stat || leadStat}Goal`],
													card.isCurrency,
													false,
													card.isCurrency ? 2 : 0,
												)
											}}
										</b>
									</template>
									<span>Objetivo</span>
								</v-tooltip>
							</span>
							<span v-if="period.key !== 'period'" style="display: flex; gap: 5px">
								<PercentageChip
									v-if="period.key.includes('-')"
									:stats="stats[period.key]"
									:stat="`${card.stat || leadStat}YearlyGrowth`"
									tooltip="Comparado ao mesmo mês do ano anterior"
									:type="type"
									:chip="true"
									:onClick="changeStatsType"
								/>
								<PercentageChip
									:stats="stats[period.key]"
									:stat="`${card.stat || leadStat}PeriodicGrowth`"
									:tooltip="`Comparado ao ${period.key.includes('-') ? 'mês' : 'ano'} anterior`"
									:type="type"
									:chip="true"
									:onClick="changeStatsType"
								/>
							</span>
						</div>
						<div v-if="card.stat === 'expenses' && period.key !== 'period'" class="statContainer">
							<span style="display: flex; gap: 5px; align-items: center">
								<v-chip small style="border-radius: 3px">Margem</v-chip>
								<v-tooltip top color="#222">
									<template v-slot:activator="{ on, attrs }">
										<span v-bind="attrs" v-on="on">
											{{ formatNumber(stats[period.key].margin, true, false, 2) }}
										</span>
									</template>
									<span>Margem</span>
								</v-tooltip>
							</span>
							<span style="display: flex; gap: 5px">
								<PercentageChip
									:stats="stats[period.key]"
									stat="margin"
									tooltip="Margem"
									:type="0"
									:chip="true"
									:onClick="() => {}"
								/>
							</span>
						</div>
						<div v-if="card.stat === 'expenses' && period.key !== 'period'" class="statContainer">
							<span style="display: flex; gap: 5px; align-items: center">
								<v-chip small style="border-radius: 3px">Custo de Venda</v-chip>
								<v-tooltip top color="#222">
									<template v-slot:activator="{ on, attrs }">
										<span v-bind="attrs" v-on="on">
											{{ formatNumber(stats[period.key].salesCost, true, false, 2) }}
										</span>
									</template>
									<span>Custo de Venda</span>
								</v-tooltip>
							</span>
							<span style="display: flex; gap: 5px">
								<PercentageChip
									:stats="stats[period.key]"
									stat="salesCost"
									tooltip="CUsto de Venda"
									:type="0"
									:chip="true"
									:onClick="() => {}"
								/>
							</span>
						</div>
						<div v-if="card.stat === 'expenses' && period.key !== 'period'" class="statContainer">
							<span style="display: flex; gap: 5px; align-items: center">
								<v-chip small style="border-radius: 3px">Custo Fixo</v-chip>
								<v-tooltip top color="#222">
									<template v-slot:activator="{ on, attrs }">
										<span v-bind="attrs" v-on="on">
											{{ formatNumber(stats[period.key].fixedCost, true, false, 2) }}
										</span>
									</template>
									<span>Custo Fixo</span>
								</v-tooltip>
							</span>
							<span style="display: flex; gap: 5px">
								<PercentageChip
									:stats="stats[period.key]"
									stat="fixedCost"
									tooltip="Custo Fixo"
									:type="0"
									:chip="true"
									:onClick="() => {}"
								/>
							</span>
						</div>
						<div v-if="card.stat === 'billing' && period.key !== 'period'" class="statContainer">
							<span style="display: flex; gap: 5px; align-items: center">
								<v-chip small style="border-radius: 3px">Previsto</v-chip>
								<v-tooltip top color="#222">
									<template v-slot:activator="{ on, attrs }">
										<span v-bind="attrs" v-on="on">
											{{
												formatNumber(stats[period.key].predicted, card.isCurrency, false, card.isCurrency ? 2 : 0)
											}}
										</span>
									</template>
									<span>Previsto</span>
								</v-tooltip>
							</span>
							<span style="display: flex; gap: 5px">
								<PercentageChip
									v-if="period.key.includes('-')"
									:stats="stats[period.key]"
									:stat="`${card.stat || leadStat}YearlyGrowthPrediction`"
									tooltip="Comparado ao mesmo mês do ano anterior"
									:type="type"
									:chip="true"
									:onClick="changeStatsType"
								/>
								<PercentageChip
									:stats="stats[period.key]"
									:stat="`${card.stat || leadStat}PeriodicGrowthPrediction`"
									:tooltip="`Comparado ao ${period.key.includes('-') ? 'mês' : 'ano'} anterior`"
									:type="type"
									:chip="true"
									:onClick="changeStatsType"
								/>
							</span>
						</div>
						<div v-if="stats[period.key][`${card.stat || leadStat}Goal`]" style="padding: 5px">
							<v-progress-linear
								rounded
								:value="stats[period.key][`${card.stat || leadStat}GoalPercent`]"
								height="12"
							>
								<template v-slot:default="{ value }">
									<span style="font-size: 0.85em">{{ Math.round(value) }}%</span>
								</template>
							</v-progress-linear>
						</div>
						<div
							v-if="stats[period.key][`${card.stat || leadStat}Expected`] && period.key !== 'period'"
							class="statContainer"
						>
							<span style="display: flex; gap: 5px; align-items: center">
								<v-chip small style="border-radius: 3px">Esperado</v-chip>
								<v-tooltip top color="#222">
									<template v-slot:activator="{ on, attrs }">
										<span v-bind="attrs" v-on="on">
											{{
												formatNumber(
													stats[period.key][`${card.stat || leadStat}Expected`],
													card.isCurrency,
													false,
													card.isCurrency ? 2 : 0,
												)
											}}
										</span>
									</template>
									<span>Esperado</span>
								</v-tooltip>
							</span>
							<PercentageChip
								:stats="stats[period.key]"
								:stat="`${card.stat || leadStat}ComparedToExpected`"
								tooltip="Comparado ao esperado"
								:type="type"
								:chip="true"
								:onClick="e => changeStatsType(e, true)"
							/>
						</div>
					</div>
				</div>
				<div v-if="stats" style="height: 100%">
					<div
						v-if="card.stat === 'reservations'"
						:style="`background-color: ${
							$vuetify.theme.dark ? '#333' : '#f2f2f2'
						}; border-radius: 5px; padding: 10px; font-size: 1.75em`"
					>
						<v-chip style="border-radius: 3px">Por Executar</v-chip>
						{{ formatNumber(stats.pipeline, true) }}
					</div>
				</div>
				<v-select
					v-if="!card.stat"
					outlined
					dense
					v-model="leadStat"
					item-text="name"
					item-value="key"
					label="Estatística"
					:items="config.statTypes"
					hide-details
					style="width: 150px"
				/>
				<div
					style="
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-top: 10px;
						flex-grow: 1;
						font-size: 1.75em;
					"
				>
					{{ card.name }}
					<v-icon v-if="card.icon" color="grey" style="font-size: 1.5em">
						{{ card.icon }}
					</v-icon>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import dayjs from "dayjs";

import PercentageChip from "./PercentageChip";

import { getGroupedStats } from "../../api/stats";

import { formatDate, formatNumber } from "../../utils/utils";

export default Vue.extend({
	name: "StatsOverview",
	props: ["fields"],
	components: { PercentageChip },
	created() {
		this.handleGetStats();

		this.setupCards();
	},
	watch: {
		selectedOffices: function () {
			this.handleGetStats();
		},
		dateRange: function () {
			this.handleGetStats();
		},
	},
	computed: mapState(["dateRange", "selectedOffices", "config"]),
	data() {
		return {
			loading: true,

			today: null,
			year: null,

			type: 0,

			stats: null,

			leadStat: "recruited",

			allCards: [
				{ name: "Recrutamento", stat: "", icon: "mdi-account-switch" },
				{ name: "Angariações", stat: "findings", icon: "mdi-home-automation" },
				{ name: "Pipeline", stat: "reservations", icon: "mdi-bookmark", isCurrency: true },
				{ name: "Faturação", stat: "billing", icon: "mdi-file-check", isCurrency: true },
				{ name: "Despesas", stat: "expenses", icon: "mdi-currency-usd-off", isCurrency: true },
			],
			cards: [],
			periods: [],
		};
	},
	methods: {
		formatNumber,
		setupCards() {
			for (const field of this.fields && this.fields.length ? this.fields : this.allCards.map(o => o.stat)) {
				const option = this.allCards.find(o => o.stat === field);
				if (option) this.cards.push(option);
			}
		},
		changeStatsType(e, skipLast) {
			this.type = this.type === 2 || (this.type === 1 && skipLast) ? 0 : this.type + 1;
		},
		async handleGetStats() {
			this.loading = true;

			const response = await getGroupedStats("office");

			if (response.status === 200) {
				this.stats = response.data.total;

				this.formatStats();
			}

			this.loading = false;
		},
		async formatStats() {
			this.today = dayjs(this.dateRange[1]);
			this.year = this.today.get("year");

			this.periods = [
				{ name: formatDate(this.today, "MMM YYYY"), key: `${this.today.get("month") + 1}-${this.year}` },
				{ name: this.year, key: this.year.toString() },
			];

			if (this.year === dayjs(this.dateRange[0]).get("year")) {
				this.periods.push({ name: "Intervalo selecionado", key: "period" });
			}
		},
	},
});
</script>
